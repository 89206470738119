import axios from 'axios'
import React, { useEffect, useState } from 'react'

const NumbersStatus = ({themeMode}) => {

  const [GetUser, setGetUser] = useState([])
  const [UserFounded, setUserFounded] = useState(false)
  const [Operation, setOperation] = useState([])
  const [StopOperation, setStopOperation] = useState(false)
  const [ownWitness, setOwnWitness] = useState([])
  const [ownWitnessStopReq, setOwnWitnessStopReq] = useState(false)


  useEffect(() => {
    if(!UserFounded){
      const fetchDada12 = async () => {
        try {
          const { data } = await axios.get(
            '/api/v1/get-users'
          )
          if(data.length > 0){
            setGetUser(data)
            if(GetUser.length > 0){
              setUserFounded(true)
            }
          }
        } catch (error) { }
      }
      fetchDada12()
    }

  }, [GetUser, UserFounded])
  
  useEffect(() => {

    const fetchData = async () => {

      if(!StopOperation){
        try {
          const { data } = await axios.get(
            '/api/v1/operations',
          )
          if(data.length > 0){
            setOperation(data)
            setStopOperation(true)
          }
          
        } catch (error) {}
      }
    }

    fetchData()

  }, [Operation, StopOperation])
    
  useEffect(()=>{

    const fetchWitnessDada = async () => {
      if(!ownWitnessStopReq){
          try {
            const { data } = await axios.get(
              '/api/v1/get-witnesses',
            )
            setOwnWitness(data)
          } catch (error) {}
      }
    }

    fetchWitnessDada()

  },[ownWitness, ownWitnessStopReq])


  return (
    
    <div className={`row mt-3 mb-5 pt-5 pb-5 ${themeMode === "true" ? "nkab-com-t" : "nkab-com"}`}>

      <div className='col-md-4 mt-3 mb-3'>
        <div style={{color:`${themeMode === "true" ? "#90d9ff" : "#333"}`}}>
          <div>Membres actifs</div>
          <div> {GetUser.length === 0 ? null : <strong style={{fontSize:"17px"}}>{GetUser.length}+</strong>} </div>
        </div>
      </div>

      <div className='col-md-4 mt-3 mb-3'>
        <div style={{color:`${themeMode === "true" ? "#90d9ff" : "#333"}`}}>
          <div>Opérations</div>
          <div> {Operation.length === 0 ? null : <strong style={{fontSize:"17px"}}>{Operation.length}+</strong>} </div>
        </div>
      </div>

      <div className='col-md-4 mt-3 mb-3'>
        <div style={{color:`${themeMode === "true" ? "#90d9ff" : "#333"}`}}>
          <div>Opérations</div>
          <div> {ownWitness.length === 0 ? null : <strong style={{fontSize:"17px"}}>{ownWitness.length}+</strong>} </div>
        </div>
      </div>

  </div>

  )
}

export default NumbersStatus