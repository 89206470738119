import React from 'react'

const SupportCommunity = () => {
    
  return (
    <div className='text-center'>
        <div className='pt-3 pb-2 mt-3 community-support'>
            <div> <span className='bolder' style={{color:"#000"}}>Rejoignez notre communauté</span> </div>
            <div className='pt-2 pb-3'>
                <div>
                    <div className='text-center nkab-community telegram-bg pointer mb-3'>
                        <a href="https://t.me/+SV_lGX2Bl9cxNmFk" className="icoFacebook" title="Telegram">
                        <span><strong className='telegram-color'> Telegram </strong></span>
                        <span><i className="fa fa-telegram telegram-color" aria-hidden="true"></i></span>
                        </a>
                    </div>
                    <div className='text-center nkab-community whatsapp-bg pointer'>
                        <a href="https://chat.whatsapp.com/B9DsCr6DeaPLeN4CWbk0R1" className="icoFacebook" title="Whatsapp">
                        <span><strong className='whatsapp-color'> Whatsapp </strong>
                        <span> <i className="fa fa-whatsapp whatsapp-color" aria-hidden="true"></i> </span>
                        </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SupportCommunity