import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEye} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import Marquee from 'react-fast-marquee'
import ChatCustomer from '../components/ChatCustomer'
import InfoCoin from '../components/InfoCoin'
import NoServices from '../components/NoServices'

export default function SendingMoneyScreen(props) {

  var    _ProWallet           = localStorage.getItem('_ProWallet')
  const [pub,         setPub] = useState([])

  if (_ProWallet === 'true') {
    window.location.replace("https://nkab-exchange.net/nkab-pro");
  }

  useEffect(() => {
    const fetchDada13 = async () => {
      try {
        const { data } = await axios.get(
          '/api/v1/pubs',
        )
        setPub(data[0])
      } catch (error) {}
    }
    fetchDada13()
  }, [])

  
  return (
    <div className="container" style={{backgroundColor:"#90d9ff"}}>
      <InfoCoin/>
      <div className="main-session">
        <div className="pub-section">
          <span className="pub-first-title-section">NEW</span>
          <span className="pub-second-title-section">
            {!pub ? null : pub ? <>{pub.ownPubTitle}</> : null}
          </span>
          <Marquee>
            <span className="pub-content-section">
              {!pub ? null : pub ? <>{pub.ownPubContent}</> : null}
            </span>
          </Marquee>
          <span className="pub-seemore-details">
            <a href="/info-details" className="color-w">
              <FontAwesomeIcon icon={faEye} />
            </a>
          </span>
        </div>
        <NoServices/>
      </div>
      <ChatCustomer />
    </div>
  )
}
