import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Alert, Autocomplete } from '@mui/material';
import axios from 'axios';
import { showSuccessMessage } from '../utils/notification/Notification';


const defaultTheme = createTheme();

export default function SignUp(props) {

    const countries = [
      { code: 'CI', label: "Côte d'Ivoire", phone: '225' },
      { code: 'ML', label: "Mali",          phone: '223' },
      { code: 'SN', label: "Senegal",       phone: '221' },
      { code: 'TG', label: "Togo",          phone: '228' },
      { code: 'BF', label: "Burkina Fasso", phone: '226' },
      { code: 'BJ', label: "Benin",         phone: '229' },
      { code: 'NE', label: "Niger",         phone: '227' },
      { code: 'CM', label: "Cameroun",      phone: '237' },
      { code: 'GN', label: "Guinée",        phone: '224' },
    ];

    var   sponsorshipId  = props.match.params.id

    const [checked,         setChecked        ] = useState(false);
    const [NkabSpinner,     setNkabSpinner    ] = useState(false)
    const [UserStopReq,     setUserStopReq    ] = useState(false)
    const [getUsers,        setGetUsers       ] = useState([])
    const [getId,           setGetId          ] = useState([])
    const [sponsorshipRef,  setSponsorshipRef ] = useState()
    const [isSponsored,     setIsSponsored    ] = useState()
    const [msg,             setMsg            ] = useState('')
    const [EmptyFName,      setEmptyFName     ] = useState("")
    const [EmptyLName,      setEmptyLName     ] = useState("")
    const [EmptyEmail,      setEmptyEmail     ] = useState("")
    const [EmptyCountry,    setEmptyCountry   ] = useState("")
    const [EmptyPhone,      setEmptyPhone     ] = useState("")
    const [EmptyDate,       setEmptyDate      ] = useState("")
    const [EmptyPlace,      setEmptyPlace     ] = useState("")
    const [EmptyResidence,  setEmptyResidence ] = useState("")
    const [EmptyConfirm,    setEmptyConfirm   ] = useState("")
    const [ErrorMessage,    setErrorMessage   ] = useState("")
    const [EamilExisted,    setEamilExisted   ] = useState(false)


  const handleCheck = () => {
      setChecked(!checked)
    };


  useEffect(()=>{

    const fetchDada12 = async () => {
      try {
        if(!UserStopReq){
          const { data } = await axios.get('/api/v1/get-users')
          if(data.length > 0){
            setGetUsers(data)
            setUserStopReq(true)
          }
        }
      } catch (error) {}
    }
    fetchDada12()

  },[UserStopReq])

  useEffect(()=>{
    
    if(getUsers){

      const sponsoringUser = getUsers.filter(obj => {
        return obj.ID === Number(sponsorshipId);
      });

      if(sponsoringUser.length > 0) {

        if(sponsoringUser[0]) {
          setGetId(sponsoringUser[0].ID)
        }

      }
      
      if (getId.length === 0) {
        setSponsorshipRef("No ref")
        setIsSponsored(400)
      } else if (getId.length > 0) {
        setSponsorshipRef(getId)
        setIsSponsored(1)
      }
    }

  },[getUsers, sponsorshipId, getId, sponsorshipRef])

  function FNameHandler(){
    setNkabSpinner(false)
    setEmptyFName("")
  }

  function LNameHandler(){
    setNkabSpinner(false)
    setEmptyLName("")
  }
  
  const EmailHandler = (e) => {

    setNkabSpinner(false)
    setEmptyEmail("")

    const {value} = e.target
    
    if(getUsers && value){
      const SearchUser = getUsers.filter(obj => {
        return obj.email.includes(value);
      });

      if(SearchUser.length === 1){
        setErrorMessage("Ce mail existe déjà dans notre base de données")
        setEamilExisted(true)
      }else{
        setErrorMessage("")
        setEamilExisted(false)
      }
    }

  }


  function CountryHandler(){
    setNkabSpinner(false)
    setEmptyCountry("")
  }

  function PhoneHandler(){
    setNkabSpinner(false)
    setEmptyPhone("")
  }

  function DateHandler(){
    setNkabSpinner(false)
    setEmptyDate("")
  }

  function PlaceHandler(){
    setNkabSpinner(false)
    setEmptyPlace("")
  }

  function ResidenceHandler(){
    setNkabSpinner(false)
    setEmptyResidence("")
  }

  function ConfirmHandler(){
    setNkabSpinner(false)
    setEmptyConfirm("")
  }

  const handleSubmit = async (event) => {

    setNkabSpinner(true)
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const email = (data.get('email')).toLowerCase()

    if(checked){

      if(data.get('firstName') === ""){ setEmptyFName("Non renseigné *") } 
      if(data.get('lastName') === ""){ setEmptyLName("Non renseigné *") } 
      if(email === ""){ setEmptyEmail("Non renseigné *") } 
      if(data.get('country') === ""){ setEmptyCountry("Non renseigné *") } 
      if(data.get('phone') === ""){ setEmptyPhone("Non renseigné *") } 
      if(data.get('date') === ""){ setEmptyDate("Non renseigné *") } 
      if(data.get('place') === ""){ setEmptyPlace("Non renseigné *") } 
      if(data.get('residence') === ""){ setEmptyResidence("Non renseigné *") } 
      if(data.get('confim') === ""){ setEmptyConfirm("Non conforme *") } 

      if(data.get('phone') !== ""){

        try {
          axios.post(
            '/api/register',
            {
              name : data.get('firstName'),
              lastName : data.get('lastName'),
              email : email,
              countryName : data.get('country'),
              telNumber : data.get('phone'),
              country : data.get('country') === "Côte d'Ivoire" ? "225" : data.get('country') === "Mali" ? "223" : data.get('country') === "Senegal" ? "221" : data.get('country') === "Togo" ? "228" : data.get('country') === "Burkina Fasso" ? "226" : data.get('country') === "Benin Fasso" ? "229" : data.get('country') === "Niger" ? "227" : "null",
              borningDate : data.get('date'),
              borningWay : data.get('place'),
              city : data.get('residence'),
              MOOV_MTN_ORANGE_Checked : 'MOOV MTN ORANGE Checked',
              WAVE_MASTERCARD_VISA_Checked : 'no WAVE MASTERACRD VISA Checked',
              NextImage : 'no image',
              NextImage2 : 'no image',
              NextImage3 : 'no image',
              sponsorshipRef,
              isSponsored,
              password : data.get('confim'),
              createdAt: new Date(),
              updatedAt: new Date(),
            },
          )
          .then((res) => {
            if (res.data.msg) {
              setMsg(res.data.msg)
              localStorage.setItem('email', email)
              axios.post(`/api/activate`,{email})
            }
            }
          )
        } catch (err) {
        }

      }
    }
  };

  return (
    <div className='pt-5 mb-5 pb-5'>
      <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-5 d-flex justify-content-around'>
          <div className='d-flex justify-content-center'><img className='login-currency-img' src="../../images/nkab-info.jpg" width="700" alt="register nkab" /></div>
        </div>
        <div className='col-md-3 final-seesion d-flex justify-content-around'>
          <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs" className='css-binzgt'>
              <CssBaseline />
              <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar sx={{ m: 1, bgcolor: '#c7b301' }}> <LockOutlinedIcon /> </Avatar>
                <Typography sx={{ fontSize: "20px" }}> Inscription
                </Typography>
                  <div style={{ marginBottom: `${'10px'}` }}>
                    {msg && showSuccessMessage(msg)}
                  </div>
                  {ErrorMessage !==""?<Alert severity="error">{ErrorMessage}</Alert>:null}
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      {EmptyFName !== "" ?<span className='text-danger'>{EmptyFName}</span>:null}
                      <TextField autoComplete="given-name" name="firstName" required fullWidth id="firstName" label="Nom" autoFocus onChange={FNameHandler} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {EmptyLName !== "" ?<span className='text-danger'>{EmptyLName}</span>:null}
                      <TextField required fullWidth id="lastName" label="Prénoms" name="lastName" autoComplete="family-name" onChange={LNameHandler} />
                    </Grid>
                    <Grid item xs={12}>
                      {EmptyEmail !== "" ?<span className='text-danger'>{EmptyEmail}</span>:null}
                      <TextField required fullWidth id="email" label="Adresse Email" name="email" autoComplete="email" onChange={EmailHandler} />
                    </Grid>
                      <Grid item xs={12} sm={6}>
                        {EmptyCountry !== "" ?<span className='text-danger'>{EmptyCountry}</span>:null}
                        <Autocomplete
                          id="country"
                          options={countries}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option) => (
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                              <img
                                  loading="lazy"
                                  width="20"
                                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                  alt=""
                              />
                              {option.label} ({option.code}) +{option.phone}
                              </Box>
                              )}
                              renderInput={(params) => (
                                  <TextField
                                  {...params}
                                  label="Choisir un pays"
                                  name="country"
                                  inputProps={{
                                      ...params.inputProps,
                                      autoComplete: 'new-password', // disable autocomplete and autofill
                                  }}
                                  />
                              )}
                            onChange={CountryHandler}
                          />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {EmptyDate !== "" ?<span className='text-danger'>{EmptyDate}</span>:null}
                        <TextField
                            id="date"
                            label="Date de naissance"
                            name="date"
                            type="date"
                            InputLabelProps={{shrink: true, }}
                            sx={{marginLeft: 'theme.spacing(1)', marginRight: 'theme.spacing(1)', width: "100%"}}
                            onChange={DateHandler}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                          {EmptyPlace !== "" ?<span className='text-danger'>{EmptyPlace}</span>:null}
                        <TextField id="place" required fullWidth name="place" label="Lieu de naissance" onChange={PlaceHandler} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {EmptyResidence !== "" ?<span className='text-danger'>{EmptyResidence}</span>:null}
                        <TextField name="residence" required fullWidth id="residence" label="Lieu de résidence" onChange={ResidenceHandler} />
                      </Grid>
                      <Grid item xs={12}>
                        {EmptyPhone !== "" ?<span className='text-danger'>{EmptyPhone}</span>:null}
                        <TextField name="phone" required fullWidth id="phone" label="Téléphone" type="number" onChange={PhoneHandler} />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField required fullWidth name="password" label="Mot de passe" type="password" id="password" autoComplete="new-password" />
                      </Grid>
                      <Grid item xs={12}>
                        {EmptyConfirm !== "" ?<span className='text-danger'>{EmptyConfirm}</span>:null}
                        <TextField required fullWidth name="confim" label="Confirmation de mot de passe" type="password" id="confim" autoComplete="confim-password" onChange={ConfirmHandler} />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Checkbox value="allowCGU" color="primary" onClick={handleCheck} />}
                          label="Je reconnais avoir lu la politique de confidentialité et CGU."
                        />
                      </Grid>
                    </Grid>
                  {
                      !msg && !EamilExisted ? 
                      <Button
                        type="submit"
                        disabled = {!checked ? true : false}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, bgcolor: '#c7b301' }}
                      >
                        { !NkabSpinner?"S'inscrire":<div className="nkab-spinner"></div> }
                      </Button>:
                      msg || EamilExisted ? 
                      <Button
                        type="submit"
                        disabled
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, bgcolor: '#c7b301' }}
                      >
                        {"Veuillez vérifier votre email"}
                      </Button>:null
                  }
                  <Grid container justifyContent="flex-end">
                    <Grid item> <Link href="/login" variant="body2" sx={{color:"#c7b301"}}> Vous avez déjà un compte ? Se connecter </Link> </Grid>
                  </Grid>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </div>
        <div className='col-md-2'></div>
      </div>
    </div>
  );
}