import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios'
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ChatCustomer from '../components/ChatCustomer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import LoadingBox from '../components/LoadingBox';
import { uid } from 'uid';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, OutlinedInput, Button, Grid  } from '@mui/material';
import BottomNavBar from '../components/BottomNavBar';

export default function OrderScreen(props) {
  
  const Logged = localStorage.getItem('userStatus')
  const coinName = localStorage.getItem('coinName')

  const userID = localStorage.getItem('userID')
  const userEmail = localStorage.getItem('userEmail')
  const SenderCountry = localStorage.getItem('SenderCountry')
  const Trowed = localStorage.getItem('Trowed')
  const [UserEmail, setUserEmail] = useState("")

  useEffect(()=>{
    setUserEmail(userEmail)
  },[userEmail])

  const STATUS = { STARTED: 'Traitement en cours...', }
  
    const INITIAL_COUNT1 = 300
  
    const [secondsRemaining1,            setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
    const [status1,                      setStatus1           ] = useState(STATUS.STOPPED)
  
    useEffect(()=>{
      setStatus1(STATUS.STARTED)
    },[STATUS.STARTED])

  useInterval(
    () => {
    if (secondsRemaining1 > 0) {
        setSecondsRemaining1(secondsRemaining1 - 1)
    } else {
        setStatus1(STATUS.STOPPED)
    }
    },

    status1 === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
)

function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
    savedCallback.current = callback
    }, [callback])

// Set up the interval.
useEffect(() => {
  function tick() {
    savedCallback.current()
  }
  if (delay !== null) {
    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }
}, [delay])
}

var [themeMode, setThemeMode] = useState()

let ThemeMode = localStorage.getItem("ThemeMode")

useEffect(()=>{
  
  if(secondsRemaining1 > 0){
    setThemeMode(ThemeMode)
  }

},[ThemeMode, secondsRemaining1])


  useEffect(()=>{
    if(Logged === 'Logout'){
      props.history.push(`/login`)
    }
  },[Logged, props.history])

  const {id} = useParams()

  const [loading, setLoading] = useState(false)
  
  const serviceName = localStorage.getItem('serviceName')
  const serviceSymbol = localStorage.getItem('serviceSymbol')
  const serviceImageLink = localStorage.getItem('serviceImageLink')
  const rate = Number(localStorage.getItem('rate'))
  const converted = Number(localStorage.getItem('converted'))
  const cfa = Number(localStorage.getItem('cfa'))
  const dollar = Number(localStorage.getItem('dollar'))
  const operatorListName = localStorage.getItem('operatorListName')
  const operatorAction = localStorage.getItem('operatorAction')
  const operatorUserInfoName = localStorage.getItem('operatorUserInfoName')
  const operatorUserInfoLastName = localStorage.getItem('operatorUserInfoLastName')
  const operatorUserInfoCountry = localStorage.getItem('operatorUserInfoCountry')
  const operatorUserInfoPhone = localStorage.getItem('operatorUserInfoPhone')
  const tetherNet = localStorage.getItem('tetherNet')
  const adr_Coin = localStorage.getItem('adr_Coin')
  // const lat = localStorage.getItem('lat')
  // const long = localStorage.getItem('long')
  const [execution4, setExecution4] = useState(false)

  const [hostUrl, setHostUrl] = useState()
  const [chargeCode, setChargeCode] = useState()
  const [chargeCreatedAt, setChargeCreatedAt] = useState()
  const [chargeId, setChargeId] = useState()
  const [chargeSupportEmail, setChargeSupportEmail] = useState()

  const coinbase = require('coinbase-commerce-node')

  const [cinetPayApi, setCinetPayApi] = useState([])
  const [ownSiteId, setOwnSiteId] = useState([])
  const [paymentUrl, setPaymentUrl] = useState()
  const [stopExchangeCount, setStopExchangeCount] = useState(false)
  const [stopExchangeCount2, setStopExchangeCount2] = useState(false)
  const [stopExchangeCount3, setStopExchangeCount3] = useState(false)
 
  const [finalPrice, setFinalPrice] = useState()
  const [montantTotal, setMontantTotal] = useState()
 
  const [finalPrice2, setFinalPrice2] = useState()
  const [montantTotal2, setMontantTotal2] = useState()
 
  const [finalPrice3, setFinalPrice3] = useState()
  const [montantTotal3, setMontantTotal3] = useState()

  const [cfa_nbr, setCfa_nbr] = useState()
  const [toStringed, setToStringed] = useState()
  const [toSliced, setToSliced] = useState()
  const [toNumbered, setToNumbered] = useState()

  const [cfa_nbr2, setCfa_nbr2] = useState()
  const [toStringed2, setToStringed2] = useState()
  const [toSliced2, setToSliced2] = useState()
  const [toNumbered2, setToNumbered2] = useState()

  const [cfa_nbr3, setCfa_nbr3] = useState()
  const [toStringed3, setToStringed3] = useState()
  const [toSliced3, setToSliced3] = useState()
  const [toNumbered3, setToNumbered3] = useState()

  const [Option1, setOption1] = useState(false)
  const [Option2, setOption2] = useState(false)
  const [Option3, setOption3] = useState(false)

  const [NextWaveVerification, setNextWaveVerification] =  useState([])
  const [WaveYes, setWaveYes] =  useState(false)
  const [NkabSpinner, setNkabSpinner] = useState(false)
  const [NkabSpinner2, setNkabSpinner2] = useState(false)
  const [PaymentFailed, setPaymentFailed] = useState(false)

  const [PayInRate, setPayInRate] = useState([])

  const [MerchantId, setMerchantId] = useState("")
  const [MerchantIdKey, setMerchantIdKey] = useState("")

  const [CodeSecret, setCodeSecret] = useState("")
  const nkab_rge_reference = localStorage.getItem("nkab_rge_reference")
  const LastStatus = localStorage.getItem("LastStatus")
  const [PaymentSuccess, setPaymentSuccess] = useState(false)
  const [MySuccess, setMySuccess] = useState("")
  const [PayWith, setPayWith] = useState(false)

  const LineLink = true
  // const LineLink = false



  const STATUS2 = {
    STARTED2: 'Traitement en cours...',
    }
  
    const INITIAL_COUNT2 = 35
    const [secondsRemaining2, setSecondsRemaining2] = useState(INITIAL_COUNT2)
    const [status2, setStatus2] = useState(STATUS2.STOPPED2)

    useEffect(()=>{
        setStatus2(STATUS2.STARTED2)
        if (secondsRemaining2 === 0){
            setSecondsRemaining2(INITIAL_COUNT2)
        }
    },[secondsRemaining2, STATUS2.STARTED2, INITIAL_COUNT2])
  
    useInterval(
    () => {
    if (secondsRemaining2 > 0) {
        setSecondsRemaining2(secondsRemaining2 - 1)
    } else {
        setStatus2(STATUS2.STOPPED2)
    }
    },

    status2 === STATUS2.STARTED2 ? 1000 : null,
    // passing null stops the interval
    )

      function useInterval(callback, delay) {
          const savedCallback = useRef()
  
          // Remember the latest callback.
          useEffect(() => {
          savedCallback.current = callback
          }, [callback])
  
      // Set up the interval.
      useEffect(() => {
        function tick() {
          savedCallback.current()
        }
        if (delay !== null) {
          let id = setInterval(tick, delay)
          return () => clearInterval(id)
        }
      }, [delay])
      }

    const twoDigits = (num) => String(num).padStart(2, '0')

  const handleCodeSecret = (e) => {
    const { value } = e.target
    setCodeSecret(value)
  }

  const ci     = "Côte d'Ivoire"
  const sen    = "Sénégal"
  const burk   = "Burkina Fasso"
  const benin  = "Bénin"
  const togo   = "Togo"
  const mali   = "Mali"
  const niger  = "Niger"
  const cam    = "Cameroun"
  const guinee = "Guinée"
  

  const myMoney = operatorListName === 'Orange Money'? "Orange" : operatorListName === 'Moov Money'? "Moov" : operatorListName === 'Mobile Money'? "mtn" : null


  useEffect(()=>{
    if(operatorUserInfoCountry === ci){

        setMerchantId("o3XPUfGPrmspx4AQoWLmr")
        setMerchantIdKey("sk_AOoWaEk0oXP1ldYEy5qdB5UnCVPu0lrl")
    }
    if(operatorUserInfoCountry === togo){

        setMerchantId("Ee9HM0SbkkPpmvpY8iJXa")
        setMerchantIdKey("sk_HSWq5h91I0E7sVSxXOACPBbMILX6Qq61")
    }
    if(operatorUserInfoCountry === burk){
        setMerchantId("4eEdWoS5T2UiPkEdsO7Zt")
        setMerchantIdKey("sk_yEvF54xNdflGibQpzVMLfAl7Iu6twdfO")
    }
    if(operatorUserInfoCountry === mali){
        setMerchantId("dNoKavNhtMQaNZTSCJa4s")
        setMerchantIdKey("sk_VMctIMcIRcAfPuzZxoc4r9TbStp7rNnG")
    }
    if(operatorUserInfoCountry === sen){
        setMerchantId("EF0esPiIBKxkdZcSwERyw")
        setMerchantIdKey("sk_7UiOwbEogK6QEv0Blm7T3XXQv2gzOq8M")
    }
    if(operatorUserInfoCountry === cam){
        setMerchantId("BguxmE4b9kYYCn86IUCPZ")
        setMerchantIdKey("sk_TUuDIbxLurPIKD2yPpvuMfocp2h8bTEy")
    }
    if(operatorUserInfoCountry === benin){
        setMerchantId("Bbqyg6WMe4MZALrTbVsKc")
        setMerchantIdKey("sk_VkKDUSWRpN7rLvQgcGvr6Rq4LHqvudth")
    }
    if(operatorUserInfoCountry === guinee){
        setMerchantId("sYSJyhGpeOXb95iO7WOlh")
        setMerchantIdKey("sk_JIO1fgAmaVb9i7wGUrYA9tQDDjYVoiiU")
    }
  },[ 
    operatorUserInfoCountry, 
    PayInRate, operatorListName, 
    myMoney ])



  useEffect(()=>{

    const fetchDada13 = async () => {

        if(secondsRemaining2 === 0 || secondsRemaining2 === 5 || secondsRemaining2 === 10 || secondsRemaining2 === 15 || secondsRemaining2 === 20 || secondsRemaining2 === 25 || secondsRemaining2 === 30 || secondsRemaining2 === 35){
            if(nkab_rge_reference !== ""){

                try {
                    if(localStorage.getItem("hub2id") && localStorage.getItem("hub2token")){

                        const hub2id = localStorage.getItem("hub2id")
                        const hub2token = localStorage.getItem("hub2token")

                            var requestOptions = {
                                method: 'GET',
                                redirect: 'follow'
                            };
            
                            axios.post(`/api/payment-step3`, {requestOptions, MerchantId, MerchantIdKey, hub2id, hub2token})
                            .then(result => {

                                if(result.data.payments.length > 0){
                                    localStorage.setItem("LastStatus", result.data.payments[0].status) 
                            
                                  if(LastStatus === "successful"){
                      
                                      setPaymentSuccess(true)
                                  
                                      if(nkab_rge_reference !== ""){

                                        setStatus1(STATUS.STOPPED)
                                        localStorage.removeItem("nkab_rge_reference")
                                        localStorage.removeItem("nkab_rge_token")
                                        localStorage.removeItem("nkab_rge_id")
                                        localStorage.removeItem("hub2token")
                                        localStorage.removeItem("hub2id")
                                        localStorage.removeItem("LastStatus")
                                        localStorage.removeItem("Trowed")
                                        setMySuccess("ok")

                                        if(LineLink){
                                            window.location.replace("https://nkab-exchange.net/orderhistory#1");
                                        } else {
                                            window.location.replace("http://localhost:3000/orderhistory#1");
                                        }
                                      }
                                  }else if(LastStatus === "failed"){
                      
                                      setPaymentFailed(true)
                                  
                                      if(nkab_rge_reference !== ""){

                                        setStatus1(STATUS.STOPPED)
                                        localStorage.removeItem("nkab_rge_reference")
                                        localStorage.removeItem("nkab_rge_token")
                                        localStorage.removeItem("nkab_rge_id")
                                        localStorage.removeItem("hub2token")
                                        localStorage.removeItem("hub2id")
                                        localStorage.removeItem("LastStatus")
                                        localStorage.removeItem("Trowed")
                                        setMySuccess("ok")

                                        if(LineLink){
                                            window.location.replace(`https://nkab-exchange.net/sell-or-buy-crypto#echec`);
                                        } else {
                                            window.location.replace(`http://localhost:3000/sell-or-buy-crypto#echec`);
                                        }
                                      }
                                  }
                                }
                            })

                    }
                } catch (error) {}

            }
        }
    }
    fetchDada13()

},[secondsRemaining2, nkab_rge_reference, LastStatus, MerchantId, MerchantIdKey])
  

  const handleStart = () => {
    props.history.push(`/orderhistory/#cb2%by-user=${userID}%checking-order=${id}`)
  }


  const payByCoin = (e) => {
    setNkabSpinner(true)
    e.preventDefault()
        
    const OrderId = id
    const SenderName = `${operatorUserInfoLastName} ${operatorUserInfoName}`
    const SenderPhone = operatorUserInfoPhone
    const SenderChange = finalPrice
    const PaymentMethod = operatorListName
    const SenderId = userID

    if (
      execution4 === false &&
      SenderName &&
      SenderPhone &&
      SenderChange &&
      PaymentMethod &&
      SenderId &&
      OrderId &&
      operatorAction &&
      SenderCountry &&
      serviceName &&
      serviceSymbol &&
      converted &&
      dollar && 
      rate &&
      adr_Coin &&
      userEmail
    ) {
      axios.post(
          '/api/v1/operation/create',
          {
            SenderId,
            SenderCountry,
            SenderName,
            SenderPhone,
            SenderChange : Number(SenderChange),
            PaymentMethod,
            OrderId,
            operatorAction,
            SenderEmail: userEmail,
            serviceName,
            serviceSymbol,
            tetherNet,
            converted,
            dollar,
            rate,
            adr_Coin,
            pmode : "coins",
            createdAt: new Date(),
            updatedAt: new Date(),
          },
        )
        .then(
          (response) => {
            if (response.data.msg === 'success') {
              setExecution4(true)
            }
          },
          (error) => {
            console.log(error)
          },
        )
      }

    setLoading(true)

    if (userID) {
      const Client = coinbase.Client
      const Charge = coinbase.resources.Charge

      Client.init('8f2f5dbe-60de-4bb2-b539-3788377cfca6')

      if (id) {
        const chargeData = {
          name: `Attention! Veuillez choisir la méthode de paiement ${serviceName} ci-dessous pour Suivant!`,
          description: `Transaction en cours d'opération par ${operatorUserInfoName}`,
          pricing_type: 'fixed_price',
          local_price: {
            amount: (dollar * 1).toFixed(2),
            currency: 'USD',
            // currency: convertedSymbol
          },
          requested_info: [operatorUserInfoName, userEmail],
        }

        Charge.create(chargeData, function (error, response) {
          if (response.code) {
            setChargeCode(response.code)
            setChargeCreatedAt(response.created_at)
            setChargeId(response.id)
            setChargeSupportEmail(response.support_email)
            setHostUrl(response.hosted_url)
          }
        })
      }
    }
  }

  useEffect(()=>{

    const fetchCinetPayApiDada = async () => {
      try {
        const { data } = await axios.get(
          '/api/v1/get-cinetpay-api',
        )
        setCinetPayApi(data[0])
      } catch (error) {}
    }
    fetchCinetPayApiDada()

    const fetchSitIdDada = async () => {
      try {
        const { data } = await axios.get(
          '/api/v1/get-siteids',
        )
        setOwnSiteId(data[0])
      } catch (error) {}
    }
    fetchSitIdDada()

  },[])

  function waveOption1(){
    if(SenderCountry === ci){
      setPayWith(true)
    }
    setOption1(true)
    setOption2(false)
    setOption3(false)
  }

  function waveOption2(){
    setOption3(false)
    setOption2(true)
    setOption1(false)
  }

  function waveOption3(){
    // setPayWith(true)
    setNkabSpinner(false)
    setPaymentUrl("")
    setOption3(true)
    setOption2(false)
    setOption1(false)
  }

  useEffect(()=>{
    if(id){
      if(operatorAction === 'buy'){
        if(operatorUserInfoCountry === "Côte d'Ivoire"){

          if(operatorListName === 'Mobile Money'){

            const montantTotal = Number( (cfa + (cfa * 0.035)).toFixed(0), )
            setMontantTotal(montantTotal)

            const montantTotal2 = Number( (cfa + (cfa * 0.01)).toFixed(0), )
            // const montantTotal2 = Number( (cfa*1).toFixed(0), )
            setMontantTotal2(montantTotal2)

            const montantTotal3 = Number( (cfa + (cfa * 0.01)).toFixed(0), )
            // const montantTotal3 = Number( (cfa*1).toFixed(0), )
            setMontantTotal3(montantTotal3)
          }

          if(operatorListName === 'Moov Money'){

            const montantTotal = Number( (cfa + (cfa * 0.035)).toFixed(0), )
            setMontantTotal(montantTotal)

            const montantTotal2 = Number( (cfa + (cfa * 0.01)).toFixed(0), )
            // const montantTotal2 = Number( (cfa*1).toFixed(0), )
            setMontantTotal2(montantTotal2)

            const montantTotal3 = Number( (cfa + (cfa * 0.01)).toFixed(0), )
            // const montantTotal3 = Number( (cfa*1).toFixed(0), )
            setMontantTotal3(montantTotal3)
          }

          if(operatorListName === 'Orange Money'){

            const montantTotal = Number( (cfa + (cfa * 0.035)).toFixed(0), )
            setMontantTotal(montantTotal)

            const montantTotal2 = Number( (cfa + (cfa * 0.01)).toFixed(0), )
            // const montantTotal2 = Number( (cfa*1).toFixed(0), )
            setMontantTotal2(montantTotal2)

            const montantTotal3 = Number( (cfa + (cfa * 0.01)).toFixed(0), )
            // const montantTotal3 = Number( (cfa*1).toFixed(0), )
            setMontantTotal3(montantTotal3)
          }

          if(operatorListName === 'Wave'){
            const montantTotal = Number( (cfa + (cfa * 0.01)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(operatorUserInfoCountry === "Sénégal"){
          if(operatorListName === 'Orange Money' || operatorListName === 'Free Money'){

            const montantTotal = Number( (cfa + (cfa * 0.025)).toFixed(0), )
            setMontantTotal(montantTotal)

            const montantTotal3 = Number( (cfa + (cfa * 0.015)).toFixed(0), )
            setMontantTotal3(montantTotal3)
          }

          if(operatorListName === 'Wave'){
            const montantTotal = Number( (cfa + (cfa * 0.018)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(operatorUserInfoCountry === "Togo"){
          if(operatorListName === 'Moov Money'){
            const montantTotal = Number( (cfa + (cfa * 0.025)).toFixed(0), )
            setMontantTotal(montantTotal)
          }

          if(operatorListName === 'TMoney'){
            const montantTotal = Number( (cfa + (cfa * 0.035)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(operatorUserInfoCountry === "Niger"){
          if(operatorListName === 'Orange Money' || operatorListName === 'Moov Money'){
            const montantTotal3 = Number( (cfa + (cfa * 0.02)).toFixed(0), )
            setMontantTotal3(montantTotal3)
          }
        }

        if(operatorUserInfoCountry === "Burkina Fasso"){
          if(operatorListName === 'Orange Money'){

            const montantTotal = Number( (cfa + (cfa * 0.045)).toFixed(0), )
            setMontantTotal(montantTotal)

            const montantTotal3 = Number( (cfa + (cfa * 0.015)).toFixed(0), )
            setMontantTotal3(montantTotal3)
          }
          if(operatorListName === 'Moov Money'){

            const montantTotal = Number( (cfa + (cfa * 0.035)).toFixed(0), )
            setMontantTotal(montantTotal)

            const montantTotal3 = Number( (cfa + (cfa * 0.015)).toFixed(0), )
            setMontantTotal3(montantTotal3)
          }
          if(operatorListName === 'Wave'){

            const montantTotal = Number( (cfa + (cfa * 0.018)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(operatorUserInfoCountry === "Mali"){
          if(operatorListName === 'Orange Money'){

            const montantTotal3 = Number( (cfa + (cfa * 0.015)).toFixed(0), )
            setMontantTotal3(montantTotal3)
          }
          if(operatorListName === 'Wave'){

            const montantTotal = Number( (cfa + (cfa * 0.018)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(operatorUserInfoCountry === "Benin"){
          if(operatorListName === 'Mobile Money'){
            const montantTotal = Number( (cfa + (cfa * 0.027)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
          if(operatorListName === 'Mobile Money' || operatorListName === 'Moov Money'){
            const montantTotal3 = Number( (cfa + (cfa * 0.02)).toFixed(0), )
            setMontantTotal3(montantTotal3)
          }
        }
      }

      //SELL

      if(operatorAction === 'sel'){
        if(operatorUserInfoCountry === "Côte d'Ivoire" && coinName === "BTC" && operatorListName === 'Wave'){
          const montantTotal = Number( (cfa - (cfa * 0.03)).toFixed(0), )
          setMontantTotal(montantTotal)
        } else if(operatorUserInfoCountry === "Côte d'Ivoire" && coinName !== "BTC" && (operatorListName === 'Orange Money' || operatorListName === 'Mobile Money' || operatorListName === 'Moov Money' || operatorListName !== 'Wave')){
          const montantTotal = Number( (cfa - (cfa * 0.02)).toFixed(0), )
          setMontantTotal(montantTotal)
        } else if(operatorUserInfoCountry === "Côte d'Ivoire" && coinName !== "BTC" && (operatorListName !== 'Orange Money' || operatorListName !== 'Mobile Money' || operatorListName !== 'Moov Money' || operatorListName === 'Wave')){
          const montantTotal = Number( (cfa - (cfa * 0.025)).toFixed(0), )
          setMontantTotal(montantTotal)
        }
  
        if(operatorUserInfoCountry === "Sénégal" && (operatorListName === 'Orange Money' || operatorListName === 'Free Money' || operatorListName === 'E Money' || operatorListName === 'Wave')){
          const montantTotal = Number( (cfa - (cfa * 0.02)).toFixed(0), )
          setMontantTotal(montantTotal)
        }
  
        if(operatorUserInfoCountry === "Togo" && (operatorListName === 'Moov Money' || operatorListName === 'TMoney')){
          const montantTotal = Number( (cfa - (cfa * 0.02) - 1500).toFixed(0), )
          setMontantTotal(montantTotal)
        }
  
        if(operatorUserInfoCountry === "Niger" && (operatorListName === 'Orange Money' || operatorListName === 'Moov Money')){
          const montantTotal = Number( (cfa - (cfa * 0.02)).toFixed(0), )
          setMontantTotal(montantTotal)
        }
  
        if(operatorUserInfoCountry === "Burkina Fasso" && (operatorListName === 'Moov Money' || operatorListName === 'Orange Money' || operatorListName === 'Wave')){
          const montantTotal = Number( (cfa - (cfa * 0.02)).toFixed(0), )
          setMontantTotal(montantTotal)
        }
  
        if(operatorUserInfoCountry === "Mali" && (operatorListName === 'Wave' || operatorListName === 'Orange Money')){
          const montantTotal = Number( (cfa - (cfa * 0.02)).toFixed(0), )
          setMontantTotal(montantTotal)
        }
  
        if(operatorUserInfoCountry === "Benin" && operatorListName === 'Mobile Money'){
          const montantTotal = Number((cfa - (cfa * 0.02) - 1500).toFixed(0),)
          setMontantTotal(montantTotal)
        }
      }
    }
  },[operatorAction, cfa, Option1, Option2, Option3, id, operatorListName, operatorUserInfoCountry, coinName])

  useEffect(()=>{
    if(montantTotal){
      setCfa_nbr(montantTotal)
      if(cfa_nbr){
        setToStringed(cfa_nbr.toString())
        if(toStringed){
          setToSliced(toStringed.slice(-1))
          if(toSliced){
            setToNumbered(Number(toSliced))
          }
        }
      }
    }
  },[montantTotal,cfa_nbr,toStringed, toSliced])


  useEffect(() => {
    if (stopExchangeCount === false) {
    
      if (toNumbered === 0) {
        const FinalCount = operatorUserInfoCountry === "Benin" ? (montantTotal - 1500) : montantTotal
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
      if (toNumbered === 5) {
        const FinalCount = operatorUserInfoCountry === "Benin" ? (montantTotal - 1500) : montantTotal
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
      if (toNumbered === 1) {
        const FinalCount = operatorUserInfoCountry === "Benin" ? (montantTotal - 1500) - 1 : montantTotal - 1
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
      if (toNumbered === 2) {
        const FinalCount = operatorUserInfoCountry === "Benin" ? (montantTotal - 1500) - 2 : montantTotal - 2
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
      if (toNumbered === 3) {
        const FinalCount = operatorUserInfoCountry === "Benin" ? (montantTotal - 1500) - 3 : montantTotal - 3
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
      if (toNumbered === 4) {
        const FinalCount = operatorUserInfoCountry === "Benin" ? (montantTotal - 1500) - 4 : montantTotal - 4
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
      if (toNumbered === 6) {
        const FinalCount = operatorUserInfoCountry === "Benin" ? (montantTotal - 1500) - 1 : montantTotal - 1
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
      if (toNumbered === 7) {
        const FinalCount = operatorUserInfoCountry === "Benin" ? (montantTotal - 1500) - 2 : montantTotal - 2
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
      if (toNumbered === 8) {
        const FinalCount = operatorUserInfoCountry === "Benin" ? (montantTotal - 1500) - 3 : montantTotal - 3
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
      if (toNumbered === 9) {
        const FinalCount = operatorUserInfoCountry === "Benin" ? (montantTotal - 1500) - 4 : montantTotal - 4
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
    }
  }, [
    
    toNumbered,
    montantTotal,
    finalPrice,
    stopExchangeCount,
    operatorUserInfoCountry
  ])


  useEffect(()=>{
    if(montantTotal2){
      setCfa_nbr2(montantTotal2)
      if(cfa_nbr2){
        setToStringed2(cfa_nbr2.toString())
        if(toStringed2){
          setToSliced2(toStringed2.slice(-1))
          if(toSliced2){
            setToNumbered2(Number(toSliced2))
          }
        }
      }


    }
  },[montantTotal2,cfa_nbr2,toStringed2, toSliced2])

  useEffect(() => {
    if (stopExchangeCount2 === false) {
    
      if (toNumbered2 === 0) {
        const FinalCount = montantTotal2
        setFinalPrice2(FinalCount)
        setStopExchangeCount2(true)
      }
      if (toNumbered2 === 5) {
        const FinalCount = montantTotal2
        setFinalPrice2(FinalCount)
        setStopExchangeCount2(true)
      }
      if (toNumbered2 === 1) {
        const FinalCount = montantTotal2 - 1
        setFinalPrice2(FinalCount)
        setStopExchangeCount2(true)
      }
      if (toNumbered2 === 2) {
        const FinalCount = montantTotal2 - 2
        setFinalPrice2(FinalCount)
        setStopExchangeCount2(true)
      }
      if (toNumbered2 === 3) {
        const FinalCount = montantTotal2 - 3
        setFinalPrice2(FinalCount)
        setStopExchangeCount2(true)
      }
      if (toNumbered2 === 4) {
        const FinalCount = montantTotal2 - 4
        setFinalPrice2(FinalCount)
        setStopExchangeCount2(true)
      }
      if (toNumbered2 === 6) {
        const FinalCount = montantTotal2 - 1
        setFinalPrice2(FinalCount)
        setStopExchangeCount2(true)
      }
      if (toNumbered2 === 7) {
        const FinalCount = montantTotal2 - 2
        setFinalPrice2(FinalCount)
        setStopExchangeCount2(true)
      }
      if (toNumbered2 === 8) {
        const FinalCount = montantTotal2 - 3
        setFinalPrice2(FinalCount)
        setStopExchangeCount2(true)
      }
      if (toNumbered2 === 9) {
        const FinalCount = montantTotal2 - 4
        setFinalPrice2(FinalCount)
        setStopExchangeCount2(true)
      }
    }
  }, [
    
    toNumbered2,
    montantTotal2,
    finalPrice2,
    stopExchangeCount2,
  ])

  useEffect(()=>{
    if(montantTotal3){
      setCfa_nbr3(montantTotal3)
      if(cfa_nbr3){
        setToStringed3(cfa_nbr3.toString())
        if(toStringed3){
          setToSliced3(toStringed3.slice(-1))
          if(toSliced3){
            setToNumbered3(Number(toSliced3))
          }
        }
      }


    }
  },[montantTotal3,cfa_nbr3,toStringed3, toSliced3])

  useEffect(() => {
    if (stopExchangeCount3 === false) {
    
      if (toNumbered3 === 0) {
        const FinalCount = montantTotal3
        setFinalPrice3(FinalCount)
        setStopExchangeCount3(true)
      }
      if (toNumbered3 === 5) {
        const FinalCount = montantTotal3
        setFinalPrice3(FinalCount)
        setStopExchangeCount3(true)
      }
      if (toNumbered3 === 1) {
        const FinalCount = montantTotal3 - 1
        setFinalPrice3(FinalCount)
        setStopExchangeCount3(true)
      }
      if (toNumbered3 === 2) {
        const FinalCount = montantTotal3 - 2
        setFinalPrice3(FinalCount)
        setStopExchangeCount3(true)
      }
      if (toNumbered3 === 3) {
        const FinalCount = montantTotal3 - 3
        setFinalPrice3(FinalCount)
        setStopExchangeCount3(true)
      }
      if (toNumbered3 === 4) {
        const FinalCount = montantTotal3 - 4
        setFinalPrice3(FinalCount)
        setStopExchangeCount3(true)
      }
      if (toNumbered3 === 6) {
        const FinalCount = montantTotal3 - 1
        setFinalPrice3(FinalCount)
        setStopExchangeCount3(true)
      }
      if (toNumbered3 === 7) {
        const FinalCount = montantTotal3 - 2
        setFinalPrice3(FinalCount)
        setStopExchangeCount3(true)
      }
      if (toNumbered3 === 8) {
        const FinalCount = montantTotal3 - 3
        setFinalPrice3(FinalCount)
        setStopExchangeCount3(true)
      }
      if (toNumbered3 === 9) {
        const FinalCount = montantTotal3 - 4
        setFinalPrice3(FinalCount)
        setStopExchangeCount3(true)
      }
    }
  }, [
    
    toNumbered3,
    montantTotal3,
    finalPrice3,
    stopExchangeCount3,
  ])

  const payByCinet = (e) => {
    setNkabSpinner(true)
    e.preventDefault()
        
    const OrderId = id
    const SenderName = `${operatorUserInfoLastName} ${operatorUserInfoName}`
    const SenderPhone = operatorUserInfoPhone
    const SenderChange = finalPrice
    const PaymentMethod = operatorListName
    const SenderId = userID

    if (
      execution4 === false &&
      SenderName &&
      SenderPhone &&
      SenderChange &&
      PaymentMethod &&
      SenderId &&
      OrderId &&
      operatorAction &&
      SenderCountry &&
      serviceName &&
      serviceSymbol &&
      converted &&
      dollar && 
      rate &&
      adr_Coin
    ) {
      axios.post(
          '/api/v1/operation/create',
          {
            SenderId,
            SenderCountry,
            SenderName,
            SenderPhone,
            SenderChange : Number(SenderChange),
            PaymentMethod,
            OrderId,
            operatorAction,
            SenderEmail: userEmail,
            serviceName,
            serviceSymbol,
            tetherNet,
            converted,
            dollar,
            rate,
            adr_Coin,
            pmode : "cinetpay",
            createdAt: new Date(),
            updatedAt: new Date(),
          },
        )
        .then(
          (response) => {
            if (response.data.msg === 'success') {
              setExecution4(true)
            }
          },
          (error) => {},
        )
      }
    var formdata = new FormData()
    const apikey = cinetPayApi.cinetPayAPI
    const site_id = ownSiteId.siteId
    const transaction_id = id
    const amount = finalPrice
    const currency = 'XOF'
    const description = `Achat d'actif`
    const notify_url = 'https://nkab-exchange.net/api/v1/cinetpay-status/create'
    const return_url = `https://nkab-exchange.net/orderhistory#1`
    const channels = 'ALL'
    const customer_name = operatorUserInfoName
    const customer_surname = operatorUserInfoLastName
    const customer_email = userEmail

    formdata.append('apikey', apikey)
    formdata.append('site_id', site_id)
    formdata.append('transaction_id', transaction_id)
    formdata.append('amount', amount)
    formdata.append('currency', currency)
    formdata.append('description', description)
    formdata.append('notify_url', notify_url)
    formdata.append('return_url', return_url)
    formdata.append('channels', channels)
    formdata.append('customer_name', customer_name)
    formdata.append('customer_surname', customer_surname)
    formdata.append('customer_email', customer_email)

    var postOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    }


    fetch('https://api-checkout.cinetpay.com/v2/payment', postOptions)
      .then((response) => response.json())
      .then((result) => {
        // setPaymentUrl(result.data.payment_url)
        window.location.replace(result.data.payment_url)
      })
      .catch((error) => console.log('error', error))
  }


  useEffect(()=>{
    if(NextWaveVerification && userEmail){
      const userWaveData = NextWaveVerification.filter((obj) => {
        return obj.userId === userEmail
      })

      if(userWaveData[0]){
        if(userWaveData[0].userId === userEmail){
          setWaveYes(true)
        }
      }
    }
  },[NextWaveVerification, userEmail, WaveYes])

  const payByHub2 = (e) => {
    setNkabSpinner(true)
    e.preventDefault()
        
    const OrderId = id
    const SenderName = `${operatorUserInfoLastName} ${operatorUserInfoName}`
    const SenderPhone = operatorUserInfoPhone.slice(4, 14)
    const SenderChange = finalPrice
    const PaymentMethod = operatorListName
    const SenderId = userID

    if (
      execution4 === false &&
      SenderName &&
      SenderPhone &&
      SenderChange &&
      PaymentMethod &&
      SenderId &&
      OrderId &&
      operatorAction &&
      SenderCountry &&
      serviceName &&
      serviceSymbol &&
      converted &&
      dollar && 
      rate &&
      adr_Coin
    ) {
      axios.post(
          '/api/v1/operation/create',
          {
            SenderId,
            SenderCountry,
            SenderName,
            SenderPhone,
            SenderChange : Number(SenderChange),
            PaymentMethod,
            OrderId,
            operatorAction,
            SenderEmail: userEmail,
            serviceName,
            serviceSymbol,
            tetherNet,
            converted,
            dollar,
            rate,
            adr_Coin,
            pmode : "hub2",
            createdAt: new Date(),
            updatedAt: new Date(),
          },
        )
        .then(
          (response) => {
            if (response.data.msg === 'success') {
              setExecution4(true)
              
            }
          },
          (error) => {},
        )
      }

      const OperationUId = uid(8)

      var raw = JSON.stringify({
        "customerReference": `ref_${OperationUId}`,
        "purchaseReference": `ref_${new Date()}`,
        "amount": finalPrice,
        "currency": "XOF"
      });
          
      var requestOptions = {
        method: 'POST',
        body: raw,
        redirect: 'follow'
      };


      axios.post(`/api/payment`, {requestOptions, MerchantId, MerchantIdKey})
      .then(result => {

          if(result.data.id && result.data.token){

              localStorage.setItem("hub2id", result.data.id)
              localStorage.setItem("hub2token", result.data.token)

              var raw2 = JSON.stringify({
                  "token": result.data.token,
                  "paymentMethod": "mobile_money",
                  "country": `${SenderCountry === ci ? 'CI' :SenderCountry === sen ? 'SN' :SenderCountry === mali ? 'ML' :SenderCountry === togo ? 'TG' :SenderCountry === burk ? 'BF' :SenderCountry === benin ? 'BJ' :SenderCountry === niger ? 'CM' : ""}`,
                  "provider": `${myMoney}`,
                  "mobileMoney": {
                      "msisdn": `${SenderPhone}`,
                      "otp": `${CodeSecret}`
                  }
              });
          
              var requestOptions2 = {
                  method: 'POST',
                  body: raw2,
                  redirect: 'follow'
              };

              if(localStorage.getItem("hub2id") !== ""){

                  const hub2id = localStorage.getItem("hub2id")
  
                  axios.post(`/api/payment-step2`, {requestOptions2, MerchantId, MerchantIdKey, hub2id})
                  .then(result => {
                      localStorage.setItem("nkab_rge_id", result.data.id)
                      localStorage.setItem("nkab_rge_reference", result.data.customerReference)
                      localStorage.setItem("nkab_rge_token", result.data.token)
      
                      if(result.data.token){
                        localStorage.setItem("isRActive",true)
                        localStorage.setItem("Trowed",true)

                        if(LineLink){
                            window.location.replace(`https://nkab-exchange.net/order/${id}`);
                        } else {
                            window.location.replace(`http://localhost:3000/order/${id}`);
                        }
                      }
                      
                  })
              }
          }
      })
  }

  useEffect(()=>{
    if(NextWaveVerification && userEmail){
      const userWaveData = NextWaveVerification.filter((obj) => {
        return obj.userId === userEmail
      })

      if(userWaveData[0]){
        if(userWaveData[0].userId === userEmail){
          setWaveYes(true)
        }
      }
    }
  },[NextWaveVerification, userEmail, WaveYes])

  const payByWave = () => {
    setNkabSpinner(true)
    if(Option2 === true){
      props.history.push(`/final-transaction/loading/${id}`)
      localStorage.setItem('finalPrice2', finalPrice2)
      localStorage.setItem('SenderCountry', SenderCountry)
      localStorage.removeItem('finalPrice3')
    }else if(Option3 === true || (operatorUserInfoCountry === "Benin" && operatorListName === "Moov Money")){
      props.history.push(`/final-transaction/loading/${id}`)
      localStorage.setItem('finalPrice3', finalPrice3)
      localStorage.setItem('SenderCountry', SenderCountry)
      localStorage.removeItem('finalPrice2')
    }else{
      localStorage.setItem('finalPrice', finalPrice)
      localStorage.setItem('SenderCountry', SenderCountry)
      props.history.push(`/final-transaction/loading/${id}`)
    }
  }

  const payByBsc = () => {
    setNkabSpinner(true)
    localStorage.setItem('finalPrice', finalPrice)
    localStorage.setItem('SenderCountry', SenderCountry)
    props.history.push(`/payment-by-bsc/cheked/${id}`)
  }

  const payByUsdt = () => {
    setNkabSpinner(true)
    localStorage.setItem('finalPrice', finalPrice)
    localStorage.setItem('SenderCountry', SenderCountry)
    props.history.push(`/payment-away/cheked/${id}`)
  }

  const payByTron = () => {
    setNkabSpinner(true)
    localStorage.setItem('finalPrice', finalPrice)
    localStorage.setItem('SenderCountry', SenderCountry)
    props.history.push(`/payment-by-tron/cheked/${id}`)
  }

  const OM      = "Orange Money"
  const MOMO    = "Mobile Money"
  const MOOV   = "Moov Money"
  const WAVE    = "Wave"
  const TM      = "TMoney"
  const FM      = "Free Money"
  const EM      = "EMoney"

  const om      = "../images/om.png"
  const momo    = "../images/momo.png"
  const flooz   = "../images/flooz.png"
  const wave    = "../images/wave.png"
  const tm      = "../images/tm.png"
  const fm      = "../images/fm.png"
  const em      = "../images/em.png"
  
  
  const loadMsg = "Veuillez patienter un moment..."

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  function payByHub2Cansel(){
    setNkabSpinner2(true)
    if(LineLink){
        window.location.replace(`https://nkab-exchange.net/sell-or-buy-crypto`);
    } else {
        window.location.replace(`http://localhost:3000/sell-or-buy-crypto`);
    }

  }

  return (
    <div className="container">
      <div>
        <div className="mt-5 pb-5">
          <div>
            {
              <div>
                <div className={`${themeMode === "true" ? "final-seesion-t" : "final-seesion"}`}>
                  <div className="order-left-side">
                    <div className="nkab-card nkab-card-body">
                      <h4 className="final-step-alert">N°: { id } </h4>
                      <div style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>E-mail : <strong>{UserEmail}</strong></div>
                      <div style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Phone : <strong>{operatorUserInfoPhone}</strong></div>
                      {operatorAction === 'buy' ?  <div style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}> Moyen de paiement : <strong className='uppercase'>{Option2 === true ? "WAVE" : operatorListName}</strong> </div> : operatorAction === 'sel' ? <div style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}> Moyen de reception : <strong className='uppercase'>{operatorListName}</strong></div> : null}
                    </div>
                    <div className="nkab-card nkab-card-body uppercase">
                      {operatorAction === 'buy' ? (
                        <div className='text-center'>
                          <div className='bolder success'>ACHAT</div>
                          <div className='d-flex justify-content-between'>
                            <span>
                              {
                                operatorListName === OM ? 
                                <img className='images-drapeau mr-2' src={om} alt="" />:
                                operatorListName === MOMO ? 
                                <img className='images-drapeau mr-2' src={momo} alt="" />:
                                operatorListName === MOOV ? 
                                <img className='images-drapeau mr-2' src={flooz} alt="" />:
                                operatorListName === WAVE ? 
                                <img className='images-drapeau mr-2' src={wave} alt="" />:
                                operatorListName === TM ? 
                                <img className='images-drapeau mr-2' src={tm} alt="" />:
                                operatorListName === FM ? 
                                <img className='images-drapeau mr-2' src={fm} alt="" />:
                                operatorListName === EM ? 
                                <img className='images-drapeau mr-2' src={em} alt="" />:null
                              }
                              </span>
                              <span><FontAwesomeIcon icon={faArrowAltCircleRight} style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}/></span>
                              <span><img className='images-drapeau mr-2' src={serviceImageLink} alt="" /></span>
                            </div>
                          </div>
                      ) : operatorAction === 'sel' ? (
                        <div className='text-center'>
                          <div className='bolder danger'>VENTE</div>
                          <div className='d-flex justify-content-between'>
                            <span><img className='images-drapeau mr-2' src={serviceImageLink} alt="" /></span>
                            <span><FontAwesomeIcon icon={faArrowAltCircleRight} style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}/></span>
                            <span>
                            {
                              operatorListName === OM ? 
                              <img className='images-drapeau mr-2' src={om} alt="" />:
                              operatorListName === MOMO ? 
                              <img className='images-drapeau mr-2' src={momo} alt="" />:
                              operatorListName === MOOV ? 
                              <img className='images-drapeau mr-2' src={flooz} alt="" />:
                              operatorListName === WAVE ? 
                              <img className='images-drapeau mr-2' src={wave} alt="" />:
                              operatorListName === TM ? 
                              <img className='images-drapeau mr-2' src={tm} alt="" />:
                              operatorListName === FM ? 
                              <img className='images-drapeau mr-2' src={fm} alt="" />:
                              operatorListName === EM ? 
                              <img className='images-drapeau mr-2' src={em} alt="" />:null
                            }
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {/* <div className="nkab-card nkab-card-body">
                      {operatorAction === 'buy' ? (
                        <div className='text-center'>
                          <span>Vous avez choisi d'acheter des <strong>{serviceSymbol}</strong> par <strong>{operatorListName}</strong> via le <strong>{operatorUserInfoPhone}</strong></span><br/>
                          <span>Vous allez recevoir votre quantité <strong>{serviceSymbol}</strong> par l'adresse <strong>{adr_Coin}</strong> à la fin du traitement de votre opération!</span>
                        </div>
                      ) : operatorAction === 'sel' ? (
                        <div className='text-center'>
                        <span>Vous avez choisi de vendre des <strong>{serviceSymbol}</strong> via l'adresse : <strong>{adr_Coin}</strong> </span><br/>
                        <span>Vous allez recevoir votre paie via <strong>{operatorListName}</strong> par le <strong>{operatorUserInfoPhone}</strong> à la fin du traitement de votre opération!</span>
                        </div>
                      ) : null}
                    </div> */}
                  </div>
                  <div className="order-right-side">
                    <div className="nkab-card nkab-card-body">
                      <div>
                        <h2 style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Resumé</h2>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          {operatorAction === 'sel' ? (
                            <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}><strong>Vous nous envoyez</strong></span>
                          ) : null}
                        </div>
                        <div>
                          {operatorAction === 'sel' ? 
                            <span >
                              {serviceSymbol === 'BTC' || serviceSymbol === 'LTC' || serviceSymbol === 'BNB' || serviceSymbol === 'MATIC' || serviceSymbol === 'POL' || serviceSymbol === 'BUSD' || serviceSymbol === 'SHIB' || serviceSymbol === 'SOL' || serviceSymbol === 'ADA' || serviceSymbol === 'LINK' || serviceSymbol === 'ETH' ? 
                                <strong className='success'>{(converted * 1).toFixed(4)}{' '}{serviceSymbol}</strong>:
                                <strong className='success'>{(converted * 1).toFixed(2)}{' '}${' '}{serviceSymbol}</strong>
                              }
                            </span>:null
                           }
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          {operatorAction === 'buy' ? (
                            <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}><strong>Montant en {serviceSymbol}</strong></span>
                          ) : null}
                        </div>
                        <div>
                          {operatorAction === 'buy' ?
                            <span >
                              {serviceSymbol === 'BTC' || serviceSymbol === 'LTC' || serviceSymbol === 'BNB' || serviceSymbol === 'MATIC' || serviceSymbol === 'POL' || serviceSymbol === 'BUSD' || serviceSymbol === 'SHIB' || serviceSymbol === 'SOL' || serviceSymbol === 'ADA' || serviceSymbol === 'LINK' || serviceSymbol === 'ETH' ? 
                                <strong className='success'>{(converted * 1).toFixed(4)}{' '}{serviceSymbol}</strong>:
                                <strong className='success'>{(converted * 1).toFixed(2)}{' '}${' '}{serviceSymbol}</strong>
                              }
                            </span>:null
                          }
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          {operatorAction === 'buy' || operatorAction === 'sel' ? (
                              <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Montant en dollar</span>
                          ) : null}
                        </div>
                        <div>
                          {operatorAction === 'buy' || operatorAction === 'sel' ? (
                              <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>{' '} $ {(dollar * 1).toFixed(2)}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          {operatorAction === 'buy' || operatorAction === 'sel' ? (
                              <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Montant sans frais</span>
                          ) : null}
                        </div>
                        <div>
                          {operatorAction === 'buy' || operatorAction === 'sel' ? (
                              <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}> {cfa} {' XOF'} </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          {operatorAction === 'buy' ? (
                              <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Taux d'achat</span>
                          ) : operatorAction === 'sel' ? (
                            <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Taux de vente</span>
                          ) : null}
                        </div>
                        <div>
                          {operatorAction === 'buy' || operatorAction === 'sel' ? (
                              <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}> {rate} {' XOF'} </span>
                          ) : null}
                        </div>
                      </div>
                      {
                        operatorAction === 'buy' && operatorListName !== 'Wave' ?
                        <div className="alert alert-info text-center">
                          <strong style={{color:"#0E233A"}}>MONTANT TOTAL A DEBITER</strong>{operatorUserInfoCountry === "Benin" && operatorListName === "Moov Money" ? null : <><br/>
                          <span className='blink' style={{color:"red"}}>Faites un choix !</span></> }
                        </div>:null
                      }
                                                            
                      {
                          PaymentSuccess ?<div className='row'>
                              <div className='d-flex justify-content-center'>
                                  <div className="svg-container">    
                                      <svg className="ft-green-tick" xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 48 48" aria-hidden="true">
                                          <circle className="circle" fill="#5bb543" cx="24" cy="24" r="22"/>
                                          <path className="tick" fill="none" stroke="#FFF" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M14 27l5.917 4.917L34 17"/>
                                      </svg>
                                  </div>
                              </div>
                              {/* <div className='mt-3'><span className='text-success'>Rechargement effectué avec succès !</span></div> */}
                          </div>:
                          PaymentFailed ?<div className='row'>
                              <div className='d-flex justify-content-center'>
                                  <div className="svg-container">    
                                      <svg className="ft-red-tick" xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 48 48" aria-hidden="true">
                                          <SmsFailedIcon className="text-danger"/>
                                      </svg>
                                  </div>
                              </div>
                              {/* <div className='mt-3'><span className='text-danger'>Rechargement échoué !</span></div> */}
                          </div>:null
                      }
                      {
                        !PayWith ? <>
                        <div className="d-flex justify-content-between">
                          {
                            Trowed ? null : 
                            <div>
                              {operatorAction === 'buy' ? (
                                <>
                                  {operatorListName === 'Wave' ? (
                                    <strong style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Montant à envoyer </strong>
                                  ) : operatorUserInfoCountry === "Benin" && operatorListName === "Moov Money" ? null : (
                                    <strong>
                                      <div className="form-check">
                                        <input className="form-check-input pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={waveOption1}/>
                                        <label className="form-check-label color-r uppercase pointer" htmlFor="flexRadioDefault1">
                                          {/* {' '}<span style={{fontSize:"12px", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Payer avec {operatorListName}</span><br/>  */}
                                          <span className='uppercase color-r' style={{fontSize:"12px"}}>Paiement automatiquement</span>
                                        </label>
                                      </div>
                                    </strong>
                                  )}
                                </>
                              ) : operatorAction === 'sel' ? (
                                <span><strong style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Montant à recevoir</strong></span>
                              ) : null}
                            </div>
                          }
                          {
                            Trowed ? null :<>
                            {
                               operatorUserInfoCountry === "Benin" && operatorListName === "Moov Money" ? null : 
                               <div style={{fontSize:"12px", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                                <strong> {finalPrice} XOF </strong>
                               </div>
                            }
                            </>
                          }
                        </div>
                        <div className="d-flex justify-content-between">
                          {
                          Trowed ?<>
                          {
                            PaymentSuccess || PaymentFailed ? null : 
                            <div className='row mb-3'>
                              <div className='d-flex justify-content-center'>
                                  <div><div className="nkab-spinner"></div></div>
                              </div>
                              <br/>
                              <div className='mt-3 row text-center'>
                                <div className='text-info'>
                                  Paiement en cours de traitement... <br/>
                                  Merci de patienter un moment <br/>
                                  <strong>NKAB Exchange vous remercie de votre fidélité!</strong>
                                </div>
                              </div>
                            </div>
                          }
                          </>:
                          <>
                            <div>
                              {operatorAction === 'buy' ? (
                                <>
                                  {operatorListName === 'Wave' || operatorListName === "TMoney" ? null : (
                                    <strong>
                                      {
                                        operatorUserInfoCountry === "Benin" && operatorListName === "Moov Money" ? 
                                        <div className="form-check">
                                          <label className="form-check-label color-r uppercase" htmlFor="flexRadioDefault3">
                                            {' '}<span style={{fontSize:"10px"}}>{operatorListName}</span>
                                          </label>
                                        </div> :
                                        <div className="form-check">
                                          <input className="form-check-input pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault3" onClick={waveOption3} />
                                          <label className="form-check-label color-r uppercase pointer" htmlFor="flexRadioDefault3">
                                            {/* {' '}<span style={{fontSize:"12px", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Payer avec {operatorListName}</span><br/>  */}
                                            <span className='uppercase color-r' style={{fontSize:"12px"}}>Paiement semi-automatique</span>
                                          </label>
                                        </div>
                                      }
                                    </strong>
                                  )}
                                </>
                              ) : null}
                            </div>
                          </>
                          }
                          {
                            Trowed ? null:<>
                            {
                              operatorAction === 'buy' && operatorListName !== 'Wave' ?
                              <>
                              {
                                SenderCountry === "Togo" &&  operatorListName === "TMoney" ? null :
                                SenderCountry === "Togo" &&  operatorListName === "Moov Money" ?
                                <div style={{fontSize:"12px", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                                  <strong> {cfa + (cfa * 0.015)} XOF </strong>
                                </div>:
                                <div style={{fontSize:"12px", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                                  <strong> {finalPrice3} XOF </strong>
                                </div>
                                
                              }
                              </>:null
                            }
                            </>
                          }
                        </div>
                        </> : null
                      }
                      {
                        PayWith ? <>
                        {
                          myMoney  === "Orange" ? <div>
                          {
                              <div className='mr-4 ml-1 mt-4'>
                                  {
                                      SenderCountry === ci ? <>
                                      <div className={`ml-4 mr-1 alert alert-info`} role="alert">Composer le <strong>#144*82#</strong> puis votre <strong>code secret</strong> et entrer le <strong>code OTP</strong> ci-dessus ensuite cliquer sur "<strong>CONFIRMER</strong>"!</div>
                                      <div className={`ml-4 mr-1 alert alert-warning`} role="alert"><strong>{finalPrice} XOF</strong> vous sera débité après avoir cliqué sur le bouton <strong>CONFIRMER</strong></div>
                                      </>:null
                                  }
                                  
                              </div>
                          }</div>:
                          myMoney  === "mtn" ? <div>
                          {
                            <div className='mr-4 ml-1 mt-4'>
                              {
                                  SenderCountry === ci ? <>
                                  <div className={`ml-4 mr-1 alert alert-warning`} role="alert"><strong>{finalPrice} XOF</strong> vous sera débité après avoir cliqué sur le bouton <strong>CONFIRMER</strong></div>
                                  </> :null
                              }
  
                            </div>
                          }</div>:
                          myMoney  === "Moov" ? <div>
                          {
                            <div className='mr-4 ml-1 mt-4'>
                              {
                                  SenderCountry === ci ? <>
                                  <div className='mr-4 ml-1 mt-4'>
                                      <div className={`ml-4 mr-1 alert alert-info`} role="alert">Entrez votre <strong>code secret Moov Money</strong> ci-dessous ensuite cliquer sur "<strong>CONFIRMER</strong>". Vous allez recevoir un SMS sur votre téléphone puis valider l'instruction qui s'affiche pour terminer.</div>
                                  </div>
                                  <div className={`ml-4 mr-1 alert alert-warning`} role="alert"><strong>{finalPrice} XOF</strong> vous sera débité après avoir cliqué sur le bouton <strong>CONFIRMER</strong></div>
                                  </>:null
                              }
                              </div>
                          }</div>:null
                        }
                        </>:null
                      }
                      {
                        SenderCountry === ci && PayWith ? <>
                        {
                          myMoney === "Moov" ? 
                          <div className='d-flex justify-content-between mt-3 pl-2 pr-2'>
                              <OutlinedInput
                                  sx={{width:"100%", ml:"2px"}}
                                  onChange={handleCodeSecret}
                                  placeholder="Code secret moov money..."
                                  id="outlined-adornment-password"
                                  type={showPassword ? "text" : "password"}
                                  endAdornment={
                                      <InputAdornment position="end">
                                      <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                      >
                                          {showPassword ? <VisibilityOff /> : <Visibility />}
                                      </IconButton>
                                      </InputAdornment>
                                  }
                                  label="Password"
                                  />
                          </div>:
                          myMoney  === "Orange" ? 
                          <div>
                              <div className='d-flex mt-3 pl-2 pr-2'>
                                  <OutlinedInput
                                      sx={{width:"100%", ml:"2px"}}
                                      onChange={handleCodeSecret}
                                      placeholder="Code OTP..."
                                      id="outlined-adornment-password"
                                      type={showPassword ? "text" : "password"}
                                      endAdornment={
                                          <InputAdornment position="end">
                                          <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPassword}
                                              onMouseDown={handleMouseDownPassword}
                                              edge="end"
                                          >
                                              {showPassword ? <VisibilityOff /> : <Visibility />}
                                          </IconButton>
                                          </InputAdornment>
                                      }
                                      label="Password"
                                      />
                              </div>
                          </div>:
                          myMoney  === "mtn" ? 
                          <div >
                              <div className='d-flex mt-3 pl-2 pr-2'>
                                  <OutlinedInput
                                      sx={{width:"100%", ml:"2px"}}
                                      onChange={handleCodeSecret}
                                      placeholder="Code secret mtn money..."
                                      id="outlined-adornment-password"
                                      type={showPassword ? "text" : "password"}
                                      endAdornment={
                                          <InputAdornment position="end">
                                          <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPassword}
                                              onMouseDown={handleMouseDownPassword}
                                              edge="end"
                                          >
                                              {showPassword ? <VisibilityOff /> : <Visibility />}
                                          </IconButton>
                                          </InputAdornment>
                                      }
                                      label="Password"
                                      />
                              </div>
                          </div>:null
                        }
                        </>:null
                      }
                      {
                        <div>
                        {operatorAction === 'buy' ? (
                          <>
                            {operatorListName === 'Wave' ? (
                              <>
                              {
                                !NkabSpinner? 
                                <button className="PayButton" onClick={payByWave}>
                                  <span className="pay" > Suivant </span>
                                </button> :
                                <button className="PayButton d-flex justify-content-center">
                                 <div className="nkab-spinner"></div>
                              </button>
                              }
                                
                              </>
                            ) : (
                              <>
                              {
                                Trowed ? null :
                                  <form>
                                    {
                                      Option1 === true && Option2 === false && Option3 === false ?
                                      <>
                                      {
                                        SenderCountry === ci ?
                                          <>
                                            <Grid container>
                                              {
                                              !NkabSpinner2? <>
                                                <Grid item xs sx={{ mr: 1}}> 
                                                    <Button type="submit" onClick={payByHub2Cansel} fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#d84949' }} >Annuler </Button>
                                                </Grid>
                                              </>:
                                                <Grid item xs sx={{ mr: 1}}>
                                                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#d84949' }} ><div className="nkab-spinner "></div> </Button>
                                                </Grid>
                                              }
                                              {
                                              !NkabSpinner? <>
                                                <Grid item xs sx={{ ml: 1, mr: 1}}>
                                                    <Button type="submit" onClick={payByHub2} fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#133558' }} >Confirmer </Button> 
                                                </Grid>
                                              </>:
                                                <Grid item xs sx={{ ml: 1, mr: 1}}>
                                                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#133558' }} ><div className="nkab-spinner "></div> </Button>
                                                </Grid>
                                              }
                                            </Grid>
                                            
                                            {
                                              myMoney  === "mtn" ? 
                                              <div>
                                                <div className='mr-4 ml-1 mt-4'>
                                                  <div className='mr-4 ml-1 mt-4'>
                                                      <div className={`ml-4 mr-1 alert alert-info`} role="alert"><strong>CONFIRMEZ</strong> ensuite composez <strong>*133#</strong> apres reception du SMS de debit reçu depuis votre téléphone ensuite vous "<strong>Validez</strong>" pour terminer!</div>
                                                  </div>
                                                </div>
                                              </div>:null
                                            }
                                          </>:
                                          <>
                                            {
                                              !NkabSpinner? 
                                              <button className="PayButton" onClick={payByCinet} type="submit" >
                                                <span className="pay" style={{fontSize:"12px"}}> CONFIRMER </span>
                                              </button> :
                                              <button className="PayButton d-flex justify-content-center">
                                              <div className="nkab-spinner"></div>
                                            </button>
                                            } 
                                          </>
                                      }
                                      </>:
                                      Option1 === false && Option2 === false && Option3 === true ?
                                      <>
                                        {
                                          !NkabSpinner? 
                                          <button className="PayButton" onClick={payByWave} type="submit" >
                                            <span className="pay" style={{fontSize:"12px"}}> Suivant </span>
                                          </button> :
                                          <button className="PayButton d-flex justify-content-center">
                                          <div className="nkab-spinner"></div>
                                        </button>
                                        }
                                      </>:
                                      Option1 === false && Option2 === true && Option3 === false && operatorUserInfoCountry !== "Benin" && operatorListName !== "Moov Money" ?
                                      <>
                                        {
                                          !NkabSpinner? 
                                          <button className="PayButton" onClick={payByWave} type="submit" >
                                            <span className="pay" style={{fontSize:"12px"}}> Suivant </span>
                                          </button> :
                                          <button className="PayButton d-flex justify-content-center">
                                          <div className="nkab-spinner"></div>
                                        </button>
                                        }
                                      </>:null
                                    }
                                  </form>
                              }
                              </>
                            )}
                          </>
                        ) : operatorAction === 'sel' ? (
                          <>
                            {serviceSymbol === 'BNB' || serviceSymbol === 'ETH' || serviceSymbol === 'BUSD' || serviceSymbol === 'SOL' || serviceSymbol === 'ADA' || serviceSymbol === 'DOT' || serviceSymbol === 'SHIB' || serviceSymbol === 'LINK' || serviceSymbol === 'MATIC' ? (
                                      <>
                                      {
                                        !NkabSpinner? 
                                        <button className="PayButton" onClick={payByBsc} type="submit" >
                                          <span className="pay" style={{fontSize:"12px"}}> Suivant </span>
                                        </button> :
                                        <button className="PayButton d-flex justify-content-center">
                                         <div className="nkab-spinner"></div>
                                      </button>
                                      }
                                        
                                      </>
                            ) : serviceSymbol === 'USDT' || serviceSymbol === 'BTC' || serviceSymbol === 'DOGE' || serviceSymbol === 'LTC' || serviceSymbol === 'PAYEER' || serviceSymbol === 'PM' ? (
                              <>
                              {
                                !NkabSpinner? 
                                <button className="PayButton" onClick={payByUsdt} type="submit" >
                                  <span className="pay" style={{fontSize:"12px"}}> Suivant </span>
                                </button> :
                                <button className="PayButton d-flex justify-content-center">
                                 <div className="nkab-spinner"></div>
                              </button>
                              }
                                
                              </>
                            ) : serviceSymbol === 'trx' ? (
                              <>
                              {
                                !NkabSpinner? 
                                <button className="PayButton" onClick={payByTron} type="submit" >
                                  <span className="pay" style={{fontSize:"12px"}}> Suivant </span>
                                </button> :
                                <button className="PayButton d-flex justify-content-center">
                                 <div className="nkab-spinner"></div>
                              </button>
                              }
                                
                              </>
                            ) : (
                              <>
                                {hostUrl ? (
                                  <div>
                                    <div className={`${themeMode === "true" ? "color-r" : "color-white"}`}>
                                      Attention! Vous devez uniquement
                                      confirmer l'envoi avec la crypto
                                      choisie qui est :{' '}
                                      <strong>{serviceName}</strong>
                                      .
                                    </div>
                                    <div className={`${themeMode === "true" ? "color-r" : "color-white"}`}>
                                      Si vous choisissez une autre que
                                      celle ci-dessus, votre envoi sera
                                      belle et bien effectué mais vous
                                      risquiez de ne pas recevoir de
                                      retour!
                                    </div>

                                    <a href={`${hostUrl}`} target="_blank" rel="noreferrer" >
                                      <button className="primary block" onClick={handleStart} >
                                        Suivant !
                                      </button>
                                    </a>
                                  </div>
                                ) : (
                                  <form>
                                    {
                                      loading === false ? 
                                      <>
                                      {
                                        !NkabSpinner? 
                                        <button className="coinPayButton" onClick={payByCoin} type="submit" >
                                          <span className="pay" style={{fontSize:"12px"}}> Suivant </span>
                                        </button> :
                                        <button className="coinPayButton d-flex justify-content-center">
                                         <div className="nkab-spinner"></div>
                                      </button>
                                      }
                                        
                                      </> : 
                                      <LoadingBox loadMsg={loadMsg} />
                                    }
                                  </form>
                                )}
                              </>
                            )}
                          </>
                        ) : null}
                        </div>}
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <ChatCustomer />
      <BottomNavBar />
    </div>
  )
}
