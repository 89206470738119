import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import InfoCoin from '../components/InfoCoin'

const HistoryDetails = (props) => {

  var _ProWallet = localStorage.getItem('_ProWallet')


  const auth                                = useSelector((state) => state.auth)
  const { user }                            = auth
  const [order,             setOrder      ] = useState([])
  const [converted,         setConverted  ] = useState([])
  const [dollar,            setDollar     ] = useState([])
  const [cfa,               setCfa        ] = useState([])
  const orderId                             = props.match.params.id

  if (_ProWallet === 'true') {
    window.location.replace("https://nkab-exchange.net/nkab-pro");
  }


  useEffect(() => {
    const fetchDada = async () => {
      try {
        const { data } = await axios.get(
          `/api/api/v1/orders/${orderId}`,
        )
        setOrder(data)
        setConverted((order.exchange[0].converted * 1).toFixed(5))
        setDollar((order.exchange[0].dollar * 1).toFixed(2))
        setCfa((order.exchange[0].cfa * 1).toFixed(0))
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message

        console.log(message)
      }
    }
    fetchDada()
  }, [orderId, order.exchange])

  return (
    <div>
      <div className="container">
        <InfoCoin />
        <div className="about-session">
          <div className="d-flex justify-content-between client-dashboard">
            <div>
              <h1>Nom : {user.name}</h1>
              <h1>Prénoms : {user.lastName}</h1>
              <h1>Email : {user.email}</h1>
              <h1>Contact : {user.telNumber}</h1>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <h1 className="user-middle-big-text ">
            Plus de détails pour vos opérations effectuées
          </h1>
        </div>

        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 mbr-col-md-10">
                <div className="wrap">
                  <div className="ico-wrap">
                    <span className="mbr-iconfont ">
                      <img
                        className="order-detail-image"
                        src={`.${order.serviceImageLink}`}
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="text-wrap vcenter">
                    <h2 className="mbr-fonts-style mbr-bold mbr-section-title3 display-5">
                      Monsieur {order.operatorUserInfoName}
                      <span>
                        {' '}
                        tente{' '}
                        {order.operatorAction === 'buy' ? (
                          <>d'acheter </>
                        ) : (
                          <></>
                        )}
                        de {order.serviceName} par {order.operatorListName}
                      </span>
                    </h2>
                    <p className="mbr-fonts-style text1 mbr-text display-6">
                      Taux{' '}
                      {order.operatorAction === 'buy' ? <>d'achat </> : <></>}
                      {order.rate}
                      {' CFA'}, quantité {converted} {order.serviceSymbol}{' '}
                      environ {'$'}
                      {dollar} et {cfa}
                      {' CFA'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div></div>
              <div>Créé le {order.createdAt}</div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default HistoryDetails
