import React from 'react'
import { Link } from 'react-router-dom'
import ChatCustomer from '../components/ChatCustomer'
import InfoCoin from '../components/InfoCoin'

const InfoScreen = (props) => {

  var _ProWallet = localStorage.getItem('_ProWallet')

  if (_ProWallet === 'true') {
    window.location.replace("https://nkab-exchange.net/nkab-pro");
  }

  

  return (
    <div>
      <div className="container" style={{backgroundColor:"#90d9ff"}}>
        <InfoCoin/>
        <div className="main-session">
          <div className="buy-item-section-details justify-content-center">
            <div className="d-flex justify-content-center">
              <h1 className="detail-title">Informations</h1>
            </div>
            <div className="d-flex justify-content-center" style={{borderRadius:'50px'}}>
              <button className="buy-button-head">
                <Link
                  to="/#oparation=buy"
                  style={{
                    color: `${'#0e2a47'}`,
                    textTransform: `${'uppercase'}`,
                  }}
                >
                  Je veux acheter des crypto-actifs
                </Link>
              </button>
              <button className="sell-button-head" style={{borderRadius:'50px'}}>
                <Link
                  to="/#oparation=sel"
                  style={{
                    color: `${'#0e2a47'}`,
                    textTransform: `${'uppercase'}`,
                  }}
                >
                  Je veux vendre mes crypto-actifs
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>

      <ChatCustomer />
    </div>
  )
}

export default InfoScreen
