import React, { useEffect, useState } from 'react'
import LoadingBox from './LoadingBox'
import './InfoCoin.css'
import Marquee from 'react-fast-marquee'
import coinGecko from '../api/coinGecko'

export default function InfoCoin({proColor}) {
    const [coins, setCoins] = useState([])

    // useEffect(()=>{
    //     const fetchDada = async () => {
    //       const response = await coinGeckoData.get('/coins/')
    //       setCoins(response.data.coins)
    //     }
    //     fetchDada()
    // },[])

    useEffect(()=>{
      const fetchDada = async () => {
        const response = await coinGecko.get('/coins/markets', {
          params: {
            vs_currency: 'usd',
            ids:
              'bitcoin,litecoin,binancecoin,eos,ethereum,iota,ripple,stellar,tether,dogecoin,tron,binance-usd,shiba-inu,matic-network,chainlink,polkadot,cardano,solana',
          },
        })
        setCoins(response.data)
      }
      fetchDada()
  },[])

    const loadMsg =  "Chargement en cours..."
    
  return (
    <Marquee className='nkab-marquee' gradient={0} pauseOnHover={true} speed={5}>
        <div className="d-flex justify-content-between crypto-variations">
          {
            !coins? <LoadingBox loadMsg={loadMsg} /> :
            <>
            {coins.map((coin, index) => (
              <div key={index} className="crypto-item">
                <span className={`crypto-elment ${proColor!==""?proColor:"color-b"}`}>{coin.name}</span>
                <span className={`crypto-elment ${proColor!==""?proColor:"color-b"}`}> ${coin.current_price.toFixed(2)} </span>
                <span className={ coin.price_change_percentage_24h < 0 ? 'mr-2 text-danger' : 'mr-2 text-success'} >
                  {' '}
                  {coin.price_change_percentage_24h < 0 ? (
                    <i className="fa fa-sort-down mr-1 text-danger"></i>
                  ) : (
                    <i className="fa fa-sort-up mr-1 text-success"></i>
                  )}
                  {coin.price_change_percentage_24h.toFixed(2)}%
                </span>
              </div>
            ))}
            </>
          }
        </div>
      </Marquee>
  )
}
