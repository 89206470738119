import { faBell, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import './NavbarOn.css'
import ReactSwitch from 'react-switch'
import { AppBar, Avatar, Badge, Box, Button, Container, Divider, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material'
import { Logout} from '@mui/icons-material'
import LightModeIcon from '@mui/icons-material/LightMode';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import InfoIcon from '@mui/icons-material/Info';
import QrCodeIcon from '@mui/icons-material/QrCode';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';


const NavbarOn = ({themeMode}) => {

  const userID = localStorage.getItem('userID')
  const token  = localStorage.getItem('token')
  const Logged = localStorage.getItem('userStatus')
  const role   = Number(localStorage.getItem('role'))

  const [ShowItem,                       setShowItem           ] = useState(false)
  const [insistLogout,                   setInsistLogout       ] = useState(false)
  const [operationAlertData,             setOperationAlertData ] = useState([])
  const [GetUser,                        setGetUser            ] = useState([])
  const [User,                           setUser               ] = useState([])
  const [OperationLength,                setOperationLength    ] = useState([])
  const [OperationProLength,             setOperationProLength ] = useState([])
  const [Commission,                     setCommission         ] = useState([])
  const [checked,                        setChecked            ] = useState(false)
  const [UserFounded,                    setUserFounded        ] = useState(false)

  var   _ProWallet = localStorage.getItem('_ProWallet')

  const LineLink = true
  // const LineLink = false

  const STATUS = { STARTED: 'Traitement en cours...', }

  const STATUS2 = {
    STARTED2: 'Traitement en cours...',
    }
  
    const INITIAL_COUNT1 = 30
    const INITIAL_COUNT2 = 300
    const [secondsRemaining1, setSecondsRemaining1] = useState(INITIAL_COUNT1)
    const [secondsRemaining2, setSecondsRemaining2] = useState(INITIAL_COUNT2)
    const [status1,           setStatus1          ] = useState(STATUS.STOPPED)
    const [status2,           setStatus2          ] = useState(STATUS2.STOPPED2)
  
    useEffect(()=>{
      setStatus1(STATUS.STARTED)
    },[STATUS.STARTED])
  
    useEffect(()=>{
      setStatus2(STATUS2.STARTED2)
    },[STATUS2.STARTED2])

  useInterval(
    () => {
    if (secondsRemaining1 > 0) {
        setSecondsRemaining1(secondsRemaining1 - 1)
    } else {
        setStatus1(STATUS.STOPPED)
    }
    },

    status1 === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
)
  
useInterval(
  () => {
  if (secondsRemaining2 > 0) {
      setSecondsRemaining2(secondsRemaining2 - 1)
  } else {
      setStatus2(STATUS2.STOPPED2)
  }
  },

  status2 === STATUS2.STARTED2 ? 1000 : null,
  // passing null stops the interval
)

function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
    savedCallback.current = callback
    }, [callback])

// Set up the interval.
useEffect(() => {
  function tick() {
    savedCallback.current()
  }
  if (delay !== null) {
    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }
}, [delay])
}

  const toogleHandleChange = (val) => {
      localStorage.setItem("_ProWallet", true)
      localStorage.setItem("transActivated", true)
      setChecked(val)
      localStorage.setItem("isRActive",false)

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/nkab-pro");
      } else {
         window.location.replace("http://localhost:3000/nkab-pro");
      }
  }

  const aboutHandler = () => {
    setAnchorElNav(null);

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/about");
    } else {
       window.location.replace("http://localhost:3000/about");
    }
  }

  const contactHandler = () => {
    setAnchorElNav(null);

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/contact");
    } else {
       window.location.replace("http://localhost:3000/contact");
    }
  }

  const walletHandler = () => {
    setAnchorElNav(null);

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/nkab-wallet");
    } else {
       window.location.replace("http://localhost:3000/nkab-wallet");
    }
  }

  const demoHandler = () => {
    setAnchorElNav(null);

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/nkab-demo");
    } else {
       window.location.replace("http://localhost:3000/nkab-demo");
    }
  }

  const whatsappHandler = () => {
    setAnchorElNav(null);
    window.location.replace("https://chat.whatsapp.com/B9DsCr6DeaPLeN4CWbk0R1");
  }

  const telegramHandler = () => {
    setAnchorElNav(null);
    window.location.replace("https://t.me/+wTl8DCgNOwI5YWI0");
  }

  const dashboardHandler = () => {
    setAnchorElNav(null);

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/dashboard");
    } else {
       window.location.replace("http://localhost:3000/dashboard");
    }
  }

  const statistiqueHandler = () => {
    setAnchorElNav(null);

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/stat");
    } else {
       window.location.replace("http://localhost:3000/stat");
    }
  }

  const operationHandler = () => {
    setAnchorElNav(null);

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/operation");
    } else {
       window.location.replace("http://localhost:3000/operation");
    }
  }

  const operationProHandler = () => {
    setAnchorElNav(null);

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/operation-pro");
    } else {
       window.location.replace("http://localhost:3000/operation-pro");
    }
  }

  const commissionHandler = () => {
    setAnchorElNav(null);
    if(LineLink){
      window.location.replace("https://nkab-exchange.net/cmss");
    } else {
       window.location.replace("http://localhost:3000/cmss");
    }
  }

  const maillingHandler = () => {
    setAnchorElNav(null);
    if(LineLink){
      window.location.replace("https://nkab-exchange.net/adsinfo");
    } else {
       window.location.replace("http://localhost:3000/adsinfo");
    }
  }

  const profileHandler = () => {
    setAnchorElNav(null);
    if(LineLink){
      window.location.replace("https://nkab-exchange.net/profile");
    } else {
       window.location.replace("http://localhost:3000/profile");
    }
  }

  const orderhistoryHandler = () => {
    setAnchorElNav(null);
    if(LineLink){
      window.location.replace("https://nkab-exchange.net/orderhistory");
    } else {
       window.location.replace("http://localhost:3000/orderhistory");
    }
  }

  const consultationHandler = () => {
    setAnchorElNav(null);
    if(LineLink){
      window.location.replace("https://nkab-exchange.net/consultation");
    } else {
       window.location.replace("http://localhost:3000/consultation");
    }
  }

  const tarificationHandler = () => {
    setAnchorElNav(null);
    if(LineLink){
      window.location.replace("https://nkab-exchange.net/tarification");
    } else {
       window.location.replace("http://localhost:3000/tarification");
    }
  }

  const enkablisteHandler = () => {
    setAnchorElNav(null);
    if(LineLink){
      window.location.replace("https://nkab-exchange.net/liste-preium");
    } else {
       window.location.replace("http://localhost:3000/liste-preium");
    }
  }

  const homeHandler = () =>{
    setAnchorElNav(null);
    if(LineLink){
      window.location.replace("https://nkab-exchange.net/home");
    } else {
       window.location.replace("http://localhost:3000/home");
    }
  }

  function showItemHandler() {
    setStatus1(STATUS.STARTED)
    setStatus2(STATUS2.STARTED2)
    setShowItem(!ShowItem)
  }

  function handleLogout(){

    localStorage.clear();

    if(_ProWallet === "true"){
      localStorage.setItem("isRActive",false)
      localStorage.setItem("_ProWallet", true)
      localStorage.setItem("userStatus", "Logout")
      localStorage.setItem("isMarchandActive", true)

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
         window.location.replace("http://localhost:3000/login");
      }
    }
    if(_ProWallet === "false"){
      localStorage.setItem("isRActive",false)
      localStorage.setItem("_ProWallet", false)
      localStorage.setItem("userStatus", "Logout")
      localStorage.setItem("isMarchandActive", true)
    }
    
    localStorage.setItem("isRActive",false)

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/login");
    } else {
       window.location.replace("http://localhost:3000/login");
    }
  }

  function handleLoggIn(){
    setInsistLogout(true)
    if(LineLink){
      window.location.replace("https://nkab-exchange.net/login");
    } else {
       window.location.replace("http://localhost:3000/login");
    }
  }

  useEffect(() => {
    if (token && insistLogout === true) {

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/home");
      } else {
         window.location.replace("http://localhost:3000/home");
      }
    }

  }, [token, insistLogout])


  useEffect(() => {
    if(!UserFounded){
      if(secondsRemaining1 > 0){
        const fetchDada12 = async () => {
          try {
            const { data } = await axios.get(
              '/api/v1/get-users'
            )
            if(data.length > 0){
              setGetUser(data)
              if(GetUser.length > 0){
                setUserFounded(true)
              }
            }
          } catch (error) { }
        }
        fetchDada12()
      }
    }

  }, [secondsRemaining1])

  useEffect(() => {

    if(secondsRemaining1 > 0){

      if (GetUser && userID) {
        const userGetted = GetUser.filter((obj) => {
          return obj.ID === Number(userID)
        })
        
        if(userGetted.length > 0){
          setUser(userGetted[0])
        }
      }
    }

  }, [GetUser, userID, User, secondsRemaining1])

  useEffect(() => {
      if(secondsRemaining2 > 0){
        const fetchOperationData = async () => {
            try {
              const { data } = await axios.get(`/api/v1/get-notifiers`)
              setOperationAlertData(data)
    
              const OpAction = data.filter((obj) => {
                return obj.notifySenderName !== "Commission" && obj.notifySenderName !== "premium"
              })
    
              setOperationLength(OpAction.length)
    
              const CmssAction = data.filter((obj) => {
                return obj.notifySenderName === "Commission"
              })
    
              setCommission(CmssAction.length)
    
              const OpProAction = data.filter((obj) => {
                return obj.notifySenderName === "premium"
              })
    
              setOperationProLength(OpProAction.length)
    
            } catch (error) { }
        }
        fetchOperationData()
      }
  }, [secondsRemaining2])


  const notifHandler = async (id) => {
    axios.post(`/api/v1/delete-notifiers`,
      {
        ID: id
      }
    )
  }


  const TRON          = "TRON"
  const BTC           = "Bitcoin"
  const LTC           = "Litecoin"
  const USDT          = "Tether(USDT)"
  const DOGE          = "Dogecoin"
  const BUSD          = "Binance USD"
  const SHIB          = "Shiba Inu"
  const MATIC         = "Polygon"
  const ETH           = "Ethereum"
  const LINK          = "Chainlink"
  const POLKADOT      = "Polkadot"
  const CARDANO       = "Cardano"
  const SOLANA        = "Solana"
  const PAYEER        = "Payeer"
  const PM            = "Perfect Money"
  const BNB           = "Binance Smart Chain"

  const tron          = "../images/tron.png"
  const btc           = "../images/btc.png"
  const ltc           = "../images/ltc.png"
  const usdt          = "../images/usdt.png"
  const doge          = "../images/doge.png"
  const busd          = "../images/busd.png"
  const shiba         = "../images/shiba.png"
  const polygonmatic  = "../images/polygonmatic.png"
  const ethereum      = "../images/eth.png"
  const chainlink     = "../images/chainlink.png"
  const polkadot      = "../images/pokadot.png"
  const cardano       = "../images/cardano.png"
  const solana        = "../images/solana.png"
  const payeer        = "../images/payeer.png"
  const pm            = "../images/pm.png"
  const bnb           = "../images/bnb.png"
  const bni           = "../../images/bni.png"
  const bibici        = "../../images/bicici.png"
  const ecobank       = "../../images/ecob.png"
  const uba           = "../../images/uba.png"
  const wave          = "../../images/wave.png"
  const cash          = "../../images/cash.png"
  const flooz         = "../../images/flooz.png"
  const om            = "../../images/om.png"
  const momo          = "../../images/momo.png"

  const [NkabBage, setNkabBage] = useState(null)

  useEffect(()=>{

    if(operationAlertData.length > 0){

      var count = operationAlertData.length

      if (count === 0) {
        setNkabBage(null)
      }

      if (count > 0 && count < 10) {
        setNkabBage(count)
      }

      if (count > 0 && count > 10) {
        setNkabBage("+9")
      }
    }
  },[operationAlertData.length])

  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  function lightMode(){
    setAnchorElNav(null);
    localStorage.setItem("ThemeMode", true)
  }

  function nightMode(){
    setAnchorElNav(null);
    localStorage.setItem("ThemeMode", false)
  }

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  function backHomeHanlder(){

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/home");
    } else {
       window.location.replace("http://localhost:3000/home");
    }
  }

  return (
    <div>
      {
       Logged === 'Logged' && role === 1 ? <title>{operationAlertData.length === 0 ? null : operationAlertData.length > 0 ? <>({operationAlertData.length})</> : null} Marché d'échange de cryptomonnaie</title> : <title> Marché d'échange de cryptomonnaie</title>
      }
      <AppBar position="static" className={`${themeMode === "true" ? "nav-t css-1x7skt0" : "nav-l css-1x7skt0"}`}>
        <Container maxWidth="xl" className='css-1qnavfx-MuiPaper-root-MuiAppBar-root css-hip9hq-MuiPaper-root-MuiAppBar-root'>
          <Toolbar disableGutters>
            <img src="../../images/logo2.png" className="nkp_logo nkab_logo_mobile mr-2 pointer"  alt="logo nkab" onClick={backHomeHanlder}/>
            {/* <Typography
              variant="h6"
              noWrap
              onClick={homeHandler}
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 800,
                letterSpacing: '.3rem',
                textDecoration: 'none',
              }}
              className={`${themeMode === "true" ? "color-t pointer" : "color-l pointer"}`}
            >
              NKAB EXCHANGE
            </Typography> */}

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                className={`${themeMode === "true" ? "color-t" : "color-l"}`}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} onClick={aboutHandler}><Typography textAlign="center" style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}><InfoIcon/> A propos de nous</Typography></MenuItem>
                <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} onClick={contactHandler}><Typography textAlign="center" style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}><PermContactCalendarIcon/> Support</Typography></MenuItem>
                <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} onClick={walletHandler}><Typography textAlign="center" style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}><QrCodeIcon/> Affiliation</Typography></MenuItem>
                <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} onClick={demoHandler}><Typography textAlign="center" style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}><PlayCircleIcon/> Vidéos démo</Typography></MenuItem>
                <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} onClick={whatsappHandler}>
                  <Typography textAlign="center">
                    <span className="icoFacebook" style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}>
                      <img src="../../images/whatsapp-icon.png" alt="Whatsapp" width="25" />Com Whatsapp
                    </span>
                  </Typography>
                </MenuItem>
                <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} onClick={telegramHandler}>
                  <Typography textAlign="center">
                    <span className="icoFacebook" style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}>
                      <img src="../../images/telegram-icon.png" alt="Telegram" width="25" />Com Telegram
                    </span>
                  </Typography>
                </MenuItem>
                <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`}>
                  <Typography>
                    {
                      themeMode === "true" ?
                      <div className="nav-item pt-2 ml-3" onClick={nightMode}>
                        <span style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}></span> <LightModeIcon className='pointer' style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} /> <span><strong style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}> Thème</strong></span>
                      </div>:
                      <div className="nav-item pt-2 ml-3" onClick={lightMode}>
                        <span style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}></span> <BedtimeIcon className='pointer'  style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} /> <span><strong style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}> Thème</strong></span>
                      </div>
                    }
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              onClick={homeHandler}
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.2rem',
                textDecoration: 'none',
              }}
              className={`${themeMode === "true" ? "color-t pointer" : "color-l pointer"}`}
            >
              NKAB EXCHANGE
            </Typography >
            <Box variant="h5" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex'} }}></Box>
            <Box variant="h5" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex'} }}>
              <Button onClick={aboutHandler} sx={{ my: 2, color: `${themeMode === "true" ? "#90d9ff" : "#0a146e"}`, display: 'block', fontWeight: 700, letterSpacing: '.1rem' }}><InfoIcon/> <span style={{fontSize:"14px"}}>A propos de nous</span></Button>
              <Button onClick={contactHandler} sx={{ my: 2, color: `${themeMode === "true" ? "#90d9ff" : "#0a146e"}`, display: 'block', fontWeight: 700, letterSpacing: '.1rem' }}><PermContactCalendarIcon/><span style={{fontSize:"14px"}}>Support</span></Button>
              <Button onClick={walletHandler} sx={{ my: 2, color: `${themeMode === "true" ? "#90d9ff" : "#0a146e"}`, display: 'block', fontWeight: 700, letterSpacing: '.1rem' }}><QrCodeIcon/> <span style={{fontSize:"14px"}}>Affiliation</span></Button>
              <Button onClick={demoHandler} sx={{ my: 2, color: `${themeMode === "true" ? "#90d9ff" : "#0a146e"}`, display: 'block', fontWeight: 700, letterSpacing: '.1rem' }}><PlayCircleIcon/> <span style={{fontSize:"14px"}}>Vidéos démo</span></Button>
            </Box>
            <Box variant="h5" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex'} }}>
                <Button onClick={whatsappHandler} sx={{ my: 2, color: 'white', display: 'block', letterSpacing: '.1rem' }}>
                  <img src="../../images/whatsapp-icon.png" alt="Whatsapp" width="35" />
                </Button>
                <Button onClick={telegramHandler} sx={{ my: 2, color: 'white', display: 'block', letterSpacing: '.1rem' }}>
                  <img src="../../images/telegram-icon.png" alt="Telegram" width="35" />
                </Button>
                <Button onClick={handleCloseNavMenu} sx={{ my: 2, color: `${themeMode === "true" ? "#90d9ff" : "#0a146e"}`, display: 'block', fontWeight: 700, letterSpacing: '.1rem' }}>
                  {
                    Logged === 'Logged' ?
                      <div className='text-center nkab-deconnect pointer' onClick={handleLogout}>
                        <span> <img src="../../images/nkabico/t_ico_connexion.png" width="25" alt="nkab ico" /></span>
                      </div> :
                      <div className='text-center nkab-deconnect connect-bg pointer' onClick={handleLoggIn}>
                      <span><strong className='community-title icoFacebook' style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}> Connexion </strong></span>
                      </div>
                  }</Button>
                  <Button sx={{ my: 2, color: 'white', display: 'block', fontWeight: 700, letterSpacing: '.1rem' }}>
                  {
                  themeMode === "true" ?
                    <div onClick={nightMode}>
                      <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}></span> <LightModeIcon className='pointer' style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}} />
                    </div>:
                    <div onClick={lightMode}>
                      <span style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}></span> <BedtimeIcon className='pointer'  style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} />
                    </div>
                  }
                  </Button>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                {
                  Logged === 'Logged' ?<>
                  {
                    role === 1 ?
                      <IconButton aria-label={`${NkabBage}`} onClick={showItemHandler} color="inherit" sx={{ mr: 2 }}>
                        <Badge badgeContent={NkabBage} color="error">
                          <NotificationsIcon icon={faBell} style={{fontSize:"25px", color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}/>
                        </Badge>
                      </IconButton>:null
                  }
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar sx={{ width: 32, height: 32 }}>
                        <img src="../../images/nkab-avatar.png" width="30" alt="nkab avatar" />
                      </Avatar>
                    </IconButton>
                  </>:null
                }
              </Tooltip>
              {
                role === 1 ?
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                    <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} ><Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center" onClick={profileHandler}> Profile </Typography></MenuItem>
                    <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} ><Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center" onClick={orderhistoryHandler}> Historique </Typography></MenuItem>
                    <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`}>
                        <Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center"> 
                            <ReactSwitch className='react-switch-handle' checked={checked} onChange={toogleHandleChange} /> Pro 
                        </Typography>
                    </MenuItem>
                    <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} ><Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center" onClick={dashboardHandler}> Tableau de bord </Typography></MenuItem>
                    <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} ><Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center" onClick={statistiqueHandler}> Statistique </Typography></MenuItem>
                    <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} ><Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center" onClick={operationHandler}> Opérations 
                    {OperationLength ? <span className='badge'> {OperationLength > 9 ? "+9" : OperationLength === 0 ? null : OperationLength}</span> : null}</Typography>
                    </MenuItem>
                    <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} ><Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center" onClick={operationProHandler}> Opérations Pro {OperationProLength ? <span className='badge'> {OperationProLength > 9 ? "+9" : OperationProLength === 0 ? null : OperationProLength}</span> : null}</Typography></MenuItem>
                    <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} ><Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center" onClick={commissionHandler}> Commissions {Commission ? <span className='badge'>{Commission}</span> : null} </Typography></MenuItem>
                    <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} ><Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center" onClick={maillingHandler}> Mailling </Typography></MenuItem>
                    <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} ><Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center" onClick={consultationHandler}> Consultation OPR </Typography></MenuItem>
                    {/* <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} ><Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center" onClick={tarificationHandler}> NKAB Currency </Typography></MenuItem> */}
                    <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} ><Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center" onClick={enkablisteHandler}> E-NKAB Liste </Typography></MenuItem>
                    <Divider />
                    <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} ><Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center" onClick={handleLogout}> 
                      <ListItemIcon>
                        <Logout fontSize="small" style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}} />
                      </ListItemIcon>
                      Déconnexion </Typography>
                    </MenuItem>
                </Menu>:
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} ><Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center" onClick={profileHandler}> Profile </Typography></MenuItem>
                <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} ><Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center" onClick={orderhistoryHandler}> Historique </Typography></MenuItem>
                <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`}>
                    <Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center"> 
                        <ReactSwitch className='react-switch-handle' checked={checked} onChange={toogleHandleChange} /> Pro 
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`}><Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center" onClick={handleLogout}> 
                  <ListItemIcon>
                    <Logout fontSize="small"/>
                  </ListItemIcon>
                  Déconnexion </Typography>
                </MenuItem>
                </Menu>
              }
            </Box>
          </Toolbar>
          {
            ShowItem === true ?
              <div className='nkab-notif-content'>
                {operationAlertData.length === 0 ? null :
                  operationAlertData.map((notifyitem) =>
                    <div key={notifyitem.ID} className='nkab-notif-item d-flex justify-content-between'>
                      <div className='nkab-notif-img'>
                        <a href={`/operation#el=${notifyitem.notifyImageLink}%nkzq%id=${notifyitem.ID}`}>
                          {
                            notifyitem.notifyImageLink === TRON ?
                            <img width="50" height="50" src={`../${tron}`} alt="" /> :
                            notifyitem.notifyImageLink === BTC ?
                            <img width="50" height="50" src={`../${btc}`} alt="" /> :
                            notifyitem.notifyImageLink === LTC ?
                            <img width="50" height="50" src={`../${ltc}`} alt="" /> :
                            notifyitem.notifyImageLink === USDT ?
                            <img width="50" height="50" src={`../${usdt}`} alt="" /> :
                            notifyitem.notifyImageLink === DOGE ?
                            <img width="50" height="50" src={`../${doge}`} alt="" /> :
                            notifyitem.notifyImageLink === BUSD ?
                            <img width="50" height="50" src={`../${busd}`} alt="" /> :
                            notifyitem.notifyImageLink === SHIB ?
                            <img width="50" height="50" src={`../${shiba}`} alt="" /> :
                            notifyitem.notifyImageLink === MATIC ?
                            <img width="50" height="50" src={`../${polygonmatic}`} alt="" /> :
                            notifyitem.notifyImageLink === ETH ?
                            <img width="50" height="50" src={`../${ethereum}`} alt="" /> :
                            notifyitem.notifyImageLink === POLKADOT ?
                            <img width="50" height="50" src={`../${polkadot}`} alt="" /> :
                            notifyitem.notifyImageLink === LINK ?
                            <img width="50" height="50" src={`../${chainlink}`} alt="" /> :
                            notifyitem.notifyImageLink === CARDANO ?
                            <img width="50" height="50" src={`../${cardano}`} alt="" /> :
                            notifyitem.notifyImageLink === SOLANA ?
                            <img width="50" height="50" src={`../${solana}`} alt="" /> :
                            notifyitem.notifyImageLink === PAYEER ?
                            <img width="50" height="50" src={`../${payeer}`} alt="" /> :
                            notifyitem.notifyImageLink === PM ?
                            <img width="50" height="50" src={`../${pm}`} alt="" /> :
                            notifyitem.notifyImageLink === BNB ?
                            <img width="50" height="50" src={`../${bnb}`} alt="" /> :
                            notifyitem.notifyImageLink === "BNI" ?
                            <img width="50" height="50" src={`${bni}`} alt="" /> :
                            notifyitem.notifyImageLink === "BICICI" ?
                            <img width="50" height="50" src={`${bibici}`} alt="" /> :
                            notifyitem.notifyImageLink === "ECOBANK" ?
                            <img width="50" height="50" src={`${ecobank}`} alt="" /> :
                            notifyitem.notifyImageLink === "UBA" ?
                            <img width="50" height="50" src={`${uba}`} alt="" /> :
                            notifyitem.notifyImageLink === "Wave" ?
                            <img width="50" height="50" src={`${wave}`} alt="" /> :
                            notifyitem.notifyImageLink === "Cash" ?
                            <img width="50" height="50" src={`${cash}`} alt="" /> :
                            notifyitem.notifyImageLink === "Moov" ?
                            <img width="50" height="50" src={`${flooz}`} alt="" /> :
                            notifyitem.notifyImageLink === "Orange" ?
                            <img width="50" height="50" src={`${om}`} alt="" /> :
                            notifyitem.notifyImageLink === "mtn" ?
                            <img width="50" height="50" src={`${momo}`} alt="" /> :
                            notifyitem.notifyImageLink === "Cash" ?
                            <img width="50" height="50" src="../images/cahs2.png" alt="" /> : null
                          }
                        </a>
                      </div>
                      <div className='nkab-notif-description p-2'>
                        {
                          notifyitem.notifyOperatorAction === "Retrait" ?
                            <a href={`/cmss#el=nkabcmss%nkzq%id=${notifyitem.ID}`}>
                              <strong className='uppercase' style={{ color: '#0a146e' }}>{notifyitem.notifyOperatorAction} de {notifyitem.notifySenderName}</strong> <br />
                              <strong> {notifyitem.notifySenderPhone} <br />
                                {notifyitem.notifySenderEmail}</strong> | {notifyitem.updatedAt.slice(0, 10)} à{' '} {Number(notifyitem.updatedAt.slice(11,13))}{notifyitem.updatedAt.slice(13, 19)}
                            </a> :
                            notifyitem.notifyOperatorAction === "RECHARGE" || notifyitem.notifyOperatorAction === "TRANSFERT" || notifyitem.notifyOperatorAction === "ACHAT" ?
                            <a href={`/operation-pro`}>
                              <strong className='uppercase' style={{ color: '#0a146e' }}>{notifyitem.notifyOperatorAction} par {notifyitem.notifyImageLink}</strong> <br />
                              <strong> {notifyitem.notifySenderPhone} <br />
                                {notifyitem.notifySenderEmail}</strong> | {notifyitem.updatedAt.slice(0, 10)} à{' '} {Number(notifyitem.updatedAt.slice(11,13))}{notifyitem.updatedAt.slice(13, 19)}
                            </a> :
                            <a href={`/operation#el=${notifyitem.notifyImageLink}%nkzq%id=${notifyitem.ID}`}>
                              <strong className='uppercase' style={{ color: '#0a146e' }}>{notifyitem.notifyOperatorAction === "buy" ? `ACHAT` : notifyitem.notifyOperatorAction === "sel" ? `VENTE` : notifyitem.notifyOperatorAction} de {notifyitem.notifyImageLink}</strong> <br />
                              <strong>{notifyitem.notifySenderName} | {notifyitem.notifySenderPhone} <br />
                                {notifyitem.notifySenderEmail}</strong> | {notifyitem.updatedAt.slice(0, 10)} à{' '} {Number(notifyitem.updatedAt.slice(11,13))}{notifyitem.updatedAt.slice(13, 19)}
                            </a>
                        }
                      </div>
                      <div className='nkab-notif-option'>
                        <span className='nkab-notif-trash' onClick={() => notifHandler(notifyitem.ID)}><FontAwesomeIcon icon={faTrashAlt} /></span>
                      </div>
                    </div>
                  )}

              </div> : null
          }
        </Container>
      </AppBar>
    </div>
  )
}

export default NavbarOn
