import { Mood } from '@mui/icons-material'
import { Table, TableBody, TableCell, TableContainer, TableHead, Paper, TableRow } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import coinGecko from '../api/coinGecko'
import MoodBadIcon from '@mui/icons-material/MoodBad';

const Variations = () => {

    const STATUS = { STARTED: 'Traitement en cours...', }
    
      const INITIAL_COUNT1 = 300
    
      const [secondsRemaining1,            setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
      const [status1,                      setStatus1           ] = useState(STATUS.STOPPED)
    
      useEffect(()=>{
        setStatus1(STATUS.STARTED)
      },[STATUS.STARTED])
  
    useInterval(
      () => {
      if (secondsRemaining1 > 0) {
          setSecondsRemaining1(secondsRemaining1 - 1)
      } else {
          setStatus1(STATUS.STOPPED)
      }
      },
  
      status1 === STATUS.STARTED ? 1000 : null,
      // passing null stops the interval
  )
  
  function useInterval(callback, delay) {
      const savedCallback = useRef()
  
      // Remember the latest callback.
      useEffect(() => {
      savedCallback.current = callback
      }, [callback])
  
  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
  }
  
  var [themeMode, setThemeMode] = useState()
  
  let ThemeMode = localStorage.getItem("ThemeMode")
  
  useEffect(()=>{
    
    if(secondsRemaining1 > 0){
      setThemeMode(ThemeMode)
    }
  
  },[ThemeMode, secondsRemaining1])

  const [coins2, setCoins2] = useState([])

  useEffect(()=>{
    
    const fetchDada2 = async () => {

      const response = await coinGecko.get('/coins/markets', {
        params: {
          vs_currency: 'usd',
          ids:
            'bitcoin,tether,binancecoin,binance-usd,tron,litecoin,dogecoin',
        },
      })
      if(response.data.length > 0){
        setCoins2(response.data)
      }
    }
    fetchDada2()
  },[])


  return (

    <div className='nkab-com mt-3 mb-5'>
      <TableContainer component={Paper} style={{fontSize:"25px", backgroundColor:`${themeMode === "true" ? "#0E233A" : ""}`}}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{fontSize:"17px", fontWeight:"600", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Noms</TableCell>
              <TableCell style={{fontSize:"17px", fontWeight:"600", color:`${themeMode === "true" ? "#90d9ff" : ""}`}} align="right">Dernier prix</TableCell>
              <TableCell style={{fontSize:"17px", fontWeight:"600", color:`${themeMode === "true" ? "#90d9ff" : ""}`}} align="right">Variation 24h</TableCell>
              <TableCell style={{fontSize:"17px", fontWeight:"600", color:`${themeMode === "true" ? "#90d9ff" : ""}`}} align="right">Etat</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coins2.map((coin) => (
              <TableRow
                key={coin.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <span><img src={coin.image} width="30" height="30" alt="logo nkab" /> <span className='bolder uppercase ml-2' style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>{coin.name}</span></span><span className={`${themeMode === "true" ? "color-w ml-2 uppercase" : "color-w ml-2 uppercase"}`}>{coin.symbol}</span>
                  
                </TableCell>
                <TableCell style={{fontSize:"17px", color:`${themeMode === "true" ? "#90d9ff" : ""}`}} align="right">$ {coin.current_price}</TableCell>
                <TableCell style={{fontSize:"17px"}} align="right">
                  <span className={ coin.price_change_percentage_24h < 0 ? 'mr-2 text-danger' : 'mr-2 text-success'} >
                    {coin.price_change_percentage_24h < 0 ? <i className="fa fa-sort-down mr-1 text-danger"></i> : <i className="fa fa-sort-up mr-1 text-success"></i>}
                    {coin.price_change_percentage_24h.toFixed(2)}%
                  </span>
                </TableCell>
                <TableCell style={{fontSize:"17px"}} align="right">
                  <span> {coin.price_change_percentage_24h < 0 ? <MoodBadIcon style={{color:"#d05460"}} /> : <Mood style={{color:"#2f805a"}} />} </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default Variations