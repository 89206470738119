import axios from 'axios'
import React, { useEffect, useState } from 'react'
import InfoCoin from '../components/InfoCoin'
import './NewServiceScreen.css'

const NewOperatorScreen = (props) => {

  var   _ProWallet                                        = localStorage.getItem('_ProWallet')
  const Logged                                            = localStorage.getItem('userStatus')

  const [operatorName,           setOperatorName        ] = useState()
  const [operatorSymbol,         setOperatorSymbol      ] = useState()
  const [operatorImageLink,      setOperatorImageLink   ] = useState()

  const LineLink = true

  if (_ProWallet === 'true') {

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/nkab-pro");
    } else {
       window.location.replace("http://localhost:3000/nkab-pro");
    }
  }

  useEffect(()=>{
    if(Logged === 'Logout'){

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
         window.location.replace("http://localhost:3000/login");
      }
    }
  },[Logged])



  function handleSubmit() {
    axios
      .post('/api/api/v1/operators/create-operator', {
        operatorName,
        operatorSymbol,
        operatorImageLink,
      })
      .then(
        (response) => {
          // console.log(response)
        },
        (error) => {
          console.log(error)
        },
      )
      props.history.push('/dashboard')
  }


  return (
    <div>
      <div className="container" style={{backgroundColor:"#90d9ff"}}>
        <InfoCoin />

        <div className="">
          <div className="d-flex justify-content-center">
            <h1 className="user-middle-big-text service-elment">
              Création d'operateur d'achat et vente
            </h1>
          </div>
          <div></div>
          <div className="color-w services-session">
            <form className="form  d-flex" onSubmit={handleSubmit}>
              <div className="register-logo-session">
                <img
                  className="register-logo"
                  src="../images/logo.png"
                  alt="Logo NKAB Exchange"
                />
              </div>
              <div className="services-input p-4">
                <div className="services-input-div">
                  <input
                    className="input-register"
                    type="text"
                    id="name"
                    placeholder="Ex: Orange Money"
                    required
                    onChange={(e) => setOperatorName(e.target.value)}
                  />
                </div>
                <div className="services-input-div">
                  <input
                    className="input-register"
                    type="text"
                    id="symbol"
                    placeholder="Ex: OM"
                    required
                    onChange={(e) => setOperatorSymbol(e.target.value)}
                  />
                </div>
                <div className="services-input-div">
                  <input
                    className="input-register"
                    type="text"
                    id="link"
                    placeholder="Ex: ../images/om.png"
                    required
                    onChange={(e) => setOperatorImageLink(e.target.value)}
                  />
                </div>
                <div>
                  <label />
                  <button className="primary" type="submit">
                    Je valide
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

export default NewOperatorScreen
