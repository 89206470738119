import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DomLoading from '../components/loadings/DomLoading/DomLoading'
import { Button, Grid } from '@mui/material'
import { useRef } from 'react'

export default function OperationConfirmationScreen(props) {

  const STATUS = { STARTED: 'Traitement en cours...', }
  
  const INITIAL_COUNT1 = 300

  const [secondsRemaining1,            setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
  const [status1,                      setStatus1           ] = useState(STATUS.STOPPED)

  useEffect(()=>{
    setStatus1(STATUS.STARTED)
  },[STATUS.STARTED])

useInterval(
  () => {
  if (secondsRemaining1 > 0) {
      setSecondsRemaining1(secondsRemaining1 - 1)
  } else {
      setStatus1(STATUS.STOPPED)
  }
  },

  status1 === STATUS.STARTED ? 1000 : null,
  // passing null stops the interval
)

function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
  savedCallback.current = callback
  }, [callback])

// Set up the interval.
useEffect(() => {
function tick() {
  savedCallback.current()
}
if (delay !== null) {
  let id = setInterval(tick, delay)
  return () => clearInterval(id)
}
}, [delay])
}

var [themeMode, setThemeMode] = useState()

let ThemeMode = localStorage.getItem("ThemeMode")

useEffect(()=>{

if(secondsRemaining1 > 0){
  setThemeMode(ThemeMode)
}

},[ThemeMode, secondsRemaining1])

  var   _ProWallet                                             = localStorage.getItem('_ProWallet')
  const Logged                                                 = localStorage.getItem('userStatus')
  const role                                                   = localStorage.getItem('role')



  const [editMoney,                 setEditMoney             ] = useState(false)
  const [Montant,                   setMontant               ] = useState(0)
  const [loading,                   setLoading               ] = useState(false)
  const [loading2,                  setLoading2              ] = useState(false)
  const [isCopied,                  setIsCopied              ] = useState(false);
  const [copyText,                  setCopyText              ] = useState(false);                  
  const [WitnessesStopReq,          setWitnessesStopReq      ] = useState(false)
  const [Operation,                 setOperation             ] = useState([])
  const [GetUser,                   setGetUser               ] = useState([])
  const [User,                      setUser                  ] = useState([])

  const { id }                    = useParams()
  const _adresse_a_copier         = `${!Operation?null:Operation.adr_Coin}`


  if (_ProWallet === 'true') {
    window.location.replace("https://nkab-exchange.net/nkab-pro");
  }



  useEffect(()=>{
    if(Logged === 'Logout'){
      window.location.replace("https://nkab-exchange.net/login");
    }

    if (Number(role) !== 1) {
      window.location.replace("https://nkab-exchange.net/");
    }
  },[Logged, role])



  useEffect(() => {

    const fetchDada = async () => {
      try {
        if(!WitnessesStopReq){
          const { data } = await axios.get('/api/v1/operations')

          if(data && id){
            const dataGetted = data.filter((obj) => {
              return obj.ID === Number(id)
            })
  
            setOperation(dataGetted[0])
          if(data.length > 0){
            setWitnessesStopReq(true)
          }
        }
      }

      } catch (error) {
        console.log(error)
      }
    }

    fetchDada()
  }, [id, Operation, WitnessesStopReq])


  useEffect(()=>{

    const fetchDada12 = async () => {
      try {
        const { data } = await axios.get('/api/v1/get-users')
        setGetUser(data)
      } catch (error) {}
    }
    fetchDada12()

  },[])

  useEffect(()=>{

    if(GetUser && Operation.SenderId){
      const userGetted = GetUser.filter((obj) => {
        return obj.ID === Number(Operation.SenderId)
      })
      setUser(userGetted[0])
    }

  },[GetUser, Operation.SenderId, User])


  useEffect(()=>{
    if(_adresse_a_copier){
      setCopyText(_adresse_a_copier)
    }
  },[_adresse_a_copier])

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  const successbyadminhandler = async () => {
    setLoading(true)
    const validation = 'validé'
    if(validation){
      axios.post(
          `/api/v1/operation/validation`,
          {
            ID: Number(id),
            isAdminCompleted: validation
          },
        )
        .then(
          (response) => {
            if (response.data.msg === 'success') {
  
              const email = Operation.SenderEmail
              const op = Operation.operatorAction
              const opdate = Operation.updatedAt
              const qt = (Number(Operation.converted)*1).toFixed(5)
              const opname = Operation.serviceSymbol
              const opaddress = Operation.adr_Coin
              const opid = Operation.OrderId

              if(email && op && opdate && qt && opname && opaddress && opid){
                axios.post(
                    `/api/service-success`,
                    {
                      email,
                      op,
                      opdate,
                      qt,
                      opname,
                      opaddress,
                      opid,
                    },
                  )
                  .then( (response) => { 
                    if(response.data.message === "Message envoyé avec succès! "){
                      window.location.replace('https://nkab-exchange.net/operation')
                    } 
                  } )
              }
            }
          },
          (error) => {
            console.log(error)
          },
        )
    }
  }

  function canselbyadminhandler(){
    setLoading2(true)
    const validation = 'annulé'
    if(validation){
      axios.post(
          `/api/v1/operation/validation`,
          {
            ID: Number(id),
            isAdminCompleted: validation
          },
        )
        .then(
          (response) => {
            if (response) {
              window.location.replace('https://nkab-exchange.net/operation')
            }
          },
          (error) => {
            console.log(error)
          },
        )
    }
  }

  function editedbyadminhandler(){
    setEditMoney(true)
  }

  return (
    <div className="container">
      <div className="py-5 text-center">
        <h2>NKAB CONFIRMATION ACHAT</h2>
          <div className='withdrav-content'>
            <div className="card text-center">
              <div className="card-body">
                <h5 className="card-title">
                  {
                    Operation.operatorAction === 'sel' ? 'Vente de ' : 
                    Operation.operatorAction === 'buy' ? 'Achat de ' : null
                  }
                  <span className='danger bolder uppercase'>{Operation.serviceName}</span> sous l'adresse :
                  <div className='row d-flex text-center'>
                      <span className='refInput'>{copyText}</span>
                      <span className='button-isCopied p-2 mt-2' onClick={handleCopyClick}>
                        <span>{isCopied ? 'Adresse copiée!' : "Copier l'adresse"}</span>
                      </span>
                    </div>
                </h5>
                <h5 className='success bolder'>
                  Quantité : 
                    {
                      Operation.serviceSymbol === "USDT" ? 
                      <>{(Operation.dollar * 1).toFixed(2)}$ {Operation.serviceSymbol}</>:
                      Operation.serviceSymbol === "PAYEER" ? 
                      <>{(Operation.converted * 1).toFixed(2)} {Operation.serviceSymbol}</>:
                      Operation.serviceSymbol === "PM" ? 
                      <>{(Operation.converted * 1).toFixed(2)} {Operation.serviceSymbol}</>:
                      Operation.serviceSymbol === "trx" ? 
                      <>{(Operation.converted * 1).toFixed(2)} {Operation.serviceSymbol}</>:
                      <>{(Operation.converted * 1).toFixed(5)} {Operation.serviceSymbol}</>
                    }
                </h5>
              </div>
              <div className="card-footer text-muted">
                {!Operation.updatedAt?<DomLoading />:Operation.updatedAt.slice(0,10)}{' '}à{' '}
                {!Operation.updatedAt?<DomLoading />:Number(Operation.updatedAt.slice(11,13))}
                {!Operation.updatedAt?<DomLoading />:Operation.updatedAt.slice(13,19)}
              </div>
            </div>
          </div> 
        <div className="withdrav-content">
          <h4 className="text-center mt-3 mb-3">
            <span style={{fontWeight:"600", fontSize:"30px", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
              {Operation.SenderChange} XOF
            </span>
          </h4>
          <ul className="list-group mb-3">
            <li className="list-group-item d-flex justify-content-between lh-sm">
              <div>
                <h6 className="my-0">Produit</h6>
              </div>
              <span className="text-muted">
                {Operation.serviceName}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between lh-sm">
              <div>
                <h6 className="my-0">Quantité</h6>
              </div>
              <span className="text-muted">
                {
                  Operation.serviceSymbol === "USDT" ? 
                  <>{(Operation.dollar * 1).toFixed(2)}$ {Operation.serviceSymbol}</>:
                  Operation.serviceSymbol === "PAYEER" ? 
                  <>{(Operation.converted * 1).toFixed(2)} {Operation.serviceSymbol}</>:
                  Operation.serviceSymbol === "PM" ? 
                  <>{(Operation.converted * 1).toFixed(2)} {Operation.serviceSymbol}</>:
                  Operation.serviceSymbol === "trx" ? 
                  <>{(Operation.converted * 1).toFixed(2)} {Operation.serviceSymbol}</>:
                  <>{(Operation.converted * 1).toFixed(5)} {Operation.serviceSymbol}</>
                }
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between lh-sm">
              <div>
                <h6 className="my-0">Taux</h6>
              </div>
              <span className="text-muted">
                {!Operation ? <DomLoading /> : Operation.rate} XOF
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <span>Total (XOF)</span>
              <strong>
                { editMoney? <input type='number' placeholder='Entrez un nouveau montant' onChange={(e)=>setMontant(e.target.value)} />:
                <>{Operation.SenderChange} XOF</> } 
              </strong>
            </li>
          </ul>

          {/* <span className="card p-2" >
            {
              loading === false ? 
              <div className="input-group">
                <span className="w-100 btn btn-primary btn-lg" onClick={successbyadminhandler}> Confirmer l'achat </span>
              </div> :
              loading === true ? 
              <div className="input-group">
                <span className="w-100 btn btn-primary btn-lg">
                  <LoadingBox loadMsg={loadMsg} />
                </span>
              </div> : <DomLoading />
            }
          </span> */}

            <Grid container>
              <Grid item xs sx={{ mr: 1}}>
                {
                  loading2 === false ? 
                  <Button type="submit" onClick={canselbyadminhandler} fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#d84949' }} >Annuler </Button>:
                  loading2 === true ?
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#d84949' }} ><div className="nkab-spinner "></div> </Button>:null
                }
              </Grid>
              <Grid item xs sx={{ ml: 1, mr: 1}}>
                {
                  loading === false ? 
                  <Button type="submit" onClick={successbyadminhandler} fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#133558' }} >Confirmer </Button> :
                  loading === true ? 
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#133558' }} ><div className="nkab-spinner "></div> </Button> : null
                }
              </Grid>
              <Grid item xs sx={{ ml: 1}}>
                <Button
                  type="submit"
                  onClick={editedbyadminhandler}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, bgcolor: '#c7b301' }}
                >Editer </Button>
              </Grid>
            </Grid>

            {/* <span className="p-2 d-flex justify-content-between">
                <div className="input-group">
                    <button className="w-100 btn btn-danger btn-lg" type="submit" onClick={canselbyadminhandler}> Annuler </button>
                </div>
                <div className="input-group">
                    <button className="w-100 btn bg-or btn-lg" type="submit" onClick={editedbyadminhandler}> Editer </button>
                </div>
            </span> */}
        </div>
      </div>
    </div>
  )
}
