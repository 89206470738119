import React, { useEffect, useRef, useState } from 'react'
import './Tarifications.css'
import CurrencyUpdateData from '../components/currencyUpdateData/page'
import CurrencyRateUpdateData from '../components/currencyRateUpdateData/page'
import CurrencyRateChargeProUpdateData from '../components/currencyRateChargeProUpdateData/page'
import CurrencyRateChargeCouvUpdateData from '../components/currencyRateChargeCouvUpdateData/page'
import SemiAutoRateUpdateData from '../components/semiAutoRateUpdateData/page'

const Tarifications = (props) => {

    const Logged    = localStorage.getItem('userStatus')
    const role      = localStorage.getItem('role')
  
     if (Logged === 'Logout') {
       props.history.push('/login')
     }
  
     if (Number(role) !== 1) {
       props.history.push('/')
     }

     const LineLink = true
     // const LineLink = false

    var [themeMode, setThemeMode] = useState()

    const STATUS = { STARTED: 'Traitement en cours...', }
    
      const INITIAL_COUNT1 = 300
    
      const [secondsRemaining1, setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
      const [status1, setStatus1 ] = useState(STATUS.STOPPED)
    
      useEffect(()=>{
        setStatus1(STATUS.STARTED)
      },[STATUS.STARTED])
  
    useInterval(
      () => {
      if (secondsRemaining1 > 0) {
          setSecondsRemaining1(secondsRemaining1 - 1)
      } else {
          setStatus1(STATUS.STOPPED)
      }
      },
  
      status1 === STATUS.STARTED ? 1000 : null,
      // passing null stops the interval
  )
  
  function useInterval(callback, delay) {
      const savedCallback = useRef()
  
      // Remember the latest callback.
      useEffect(() => {
      savedCallback.current = callback
      }, [callback])
  
  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
  }
    
    let ThemeMode = localStorage.getItem("ThemeMode")
    
    useEffect(()=>{
      
      if(secondsRemaining1 > 0){
        setThemeMode(ThemeMode)
      }
    
    },[ThemeMode, secondsRemaining1])


  return (
    <div className='container mt-5'>
        <CurrencyUpdateData themeMode={themeMode} LineLink={LineLink} />
        <CurrencyRateUpdateData themeMode={themeMode} LineLink={LineLink} />
        <CurrencyRateChargeProUpdateData themeMode={themeMode} LineLink={LineLink} />
        <CurrencyRateChargeCouvUpdateData themeMode={themeMode} LineLink={LineLink} />
        <SemiAutoRateUpdateData themeMode={themeMode} LineLink={LineLink} />
    </div>
  )
}

export default Tarifications