import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {
  showErrMessage,
  showSuccessMessage,
} from '../components/utils/notification/Notification'
import { isLength, isMatch } from '../components/utils/validation/Validation'
import './Authentification.css'

const initialState = {
  password: '',
  cf_password: '',
  err: '',
  success: '',
}

export default function ResetPassword(props) {

  var _ProWallet         = localStorage.getItem('_ProWallet')
    
  const [data,             setData] = useState(initialState)
  const token_id         = props.match.url.slice(20, 200)
  const email            = localStorage.getItem('email')
  const [Success,          setSuccess     ] = useState('')
  const { password, cf_password, err      } = data

  if (_ProWallet === 'true') {
    window.location.replace("https://nkab-exchange.net/nkab-pro");
  }

  const handleChangeInput = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value, err: '', success: '' })
  }

  const handleResetPassword = async () => {
    if (isLength(password))
      return setData({
        ...data,
        err: 'Password must be at least 6 characters!',
        success: '',
      })

    if (!isMatch(password, cf_password))
      return setData({ ...data, err: 'Password did not mutch!', success: '' })

    try {
      const res = await axios.post('/api/reset_password',
        { password, token_id, email }
      )
      setSuccess(res.data.message)
    } catch (err) { 
      console.log(err)
    }
  }

  useEffect(()=>{
    if(Success){
      localStorage.removeItem('email')
    }
  },[Success])

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-md-offset-12">
          <div className="panel panel-default">
            <div className="panel-body">
              <div className="text-center" style={{ padding: `${'40px 0'}` }}>
                <div style={{ marginBottom: `${'10px'}` }}>
                  {err && showErrMessage(err)}
                  {Success && showSuccessMessage(Success) }
                </div>
                <div> {Success && <span className='btn btn-success'><a href="/login" className="nav-link">Se connecter</a></span> } </div>
                <h4> <i className="fa fa-lock fa-4x" style={{ color: `${'#c7b301'}`}}></i> </h4>
                <h2 className="text-center">
                  Reinitialisation de mot de passe
                </h2>
                <div>Vous pouvez reinitialiser votre mot de passe ici.</div>
                <div className="panel-body">
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        id="password"
                        value={password}
                        name="password"
                        placeholder="Password"
                        className="form-control input-signin input-add"
                        type="password"
                        onChange={handleChangeInput}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        id="cf_password"
                        value={cf_password}
                        name="cf_password"
                        placeholder="Confirm Password"
                        className="form-control input-signin input-add"
                        type="password"
                        onChange={handleChangeInput}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      style={{
                        backgroundColor: `${'#c7b301'}`,
                        border: `${'none'}`,
                      }}
                      name="recover-submit"
                      className="btn btn-lg btn-primary btn-block"
                      value="Reinitialiser le mot de passe"
                      type="submit"
                      onClick={handleResetPassword}
                    />
                  </div>
                  <input
                    type="hidden"
                    className="hide"
                    name="token"
                    id="token"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
