import React, { useEffect, useRef, useState } from 'react'
import LoadingBox from '../components/LoadingBox'
import axios from 'axios'
import ChatCustomer from '../components/ChatCustomer'
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import BottomNavBar from '../components/BottomNavBar'

export default function FinalWavePaymentScreen(props) {

  const STATUS = {
    STARTED: 'Traitement en cours...',
  }

  const Logged                               = localStorage.getItem('userStatus')
  const codePostalCountry                    = localStorage.getItem('codePostalCountry')
  const userEmail                            = localStorage.getItem('userEmail')
  const SenderCountry                        = localStorage.getItem('SenderCountry')
  const finalPrice2                          = localStorage.getItem('finalPrice2')
  const finalPrice3                          = localStorage.getItem('finalPrice3')
  const serviceImageLink                     = localStorage.getItem('serviceImageLink')
  const cfa                                  = Number(localStorage.getItem('cfa'))
  const operatorListName                     = localStorage.getItem('operatorListName')
  const operatorAction                       = localStorage.getItem('operatorAction')
  const operatorUserInfoName                 = localStorage.getItem('operatorUserInfoName')
  const operatorUserInfoLastName             = localStorage.getItem('operatorUserInfoLastName')
  const operatorUserInfoPhone                = localStorage.getItem('operatorUserInfoPhone')
  const operatorUserInfoId                   = localStorage.getItem('operatorUserInfoId')
  const operatorUserInfoCountry              = localStorage.getItem('operatorUserInfoCountry')
  const serviceSymbol                        = localStorage.getItem('serviceSymbol')
  const serviceName                          = localStorage.getItem('serviceName')
  const converted                            = Number(localStorage.getItem('converted'))
  const dollar                               = Number(localStorage.getItem('dollar'))
  const rate                                 = Number(localStorage.getItem('rate'))
  const adr_Coin                             = localStorage.getItem('adr_Coin')
  const tetherNet                            = localStorage.getItem('tetherNet')
  // const lat                               = localStorage.getItem('lat')
  // const long                              = localStorage.getItem('long')

  const [stopExchangeCount,          setStopExchangeCount    ] = useState(false)
  const [finalPrice,                 setFinalPrice           ] = useState()
  const [displayConfirme,            setDisplayConfirme      ] = useState(false)
  const [NKAB_Access,                setNKAB_Access          ] = useState(false)
  const [WavePhone,                  setWavePhone            ] = useState('')
  const [montantTotal,               setMontantTotal         ] = useState()

  const [UserEmail, setUserEmail] = useState("")

  useEffect(()=>{
    setUserEmail(userEmail)
  },[userEmail])


  const STATUS2 = {
    STARTED2: 'Traitement en cours...',
  }


  const INITIAL_COUNT    = 10
  const INITIAL_COUNT2   = 300
  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT)
  const [secondsRemaining2, setSecondsRemaining2] = useState(INITIAL_COUNT2)
  const [status, setStatus] = useState(STATUS.STOPPED)
  const [status2, setStatus2] = useState(STATUS2.STOPPED2)
  const [statusCount, setStatusCount] = useState()

  const secondsToDisplay   = secondsRemaining % 60
  const minutesRemaining   = (secondsRemaining - secondsToDisplay) / 60
  const minutesToDisplay   = minutesRemaining % 60
  const hoursToDisplay     = (minutesRemaining - minutesToDisplay) / 60
  
  const {id}                                            = useParams()
  const wavePaymentLink                                 = 'https://pay.wave.com/m/M_DyjfDYDUCSym/c/ci/'
  const adrTotal                                        = WavePhone.length
  const limitAdrCount                                   = 8

  const [ActualNumber, setActualNumber] = useState([])
  const [ActualOmNumber, setActualOmNumber] = useState([])
  const [ActualMomoNumber, setActualMomoNumber] = useState([])
  const [ActualMoovNumber, setActualMoovNumber] = useState([])
  const [MarchandStatus, setMarchandStatus] = useState([])
  const [OrdinaryStatus, setOrdinaryStatus] = useState([])
  
  useEffect(() => {
    const fetchMarchandStatus = async () => {
      try {

        const { data } = await axios.get('/api/v1/marchand-status')

        setMarchandStatus(data[0].status)

      } catch (error) { }
    }
    fetchMarchandStatus()

    const fetchOrdinaryStatus = async () => {
      try {

        const { data } = await axios.get('/api/v1/ordinary-status')

        setOrdinaryStatus(data[0].status)

      } catch (error) { }
    }
    fetchOrdinaryStatus()

    const fetchOrdinaryNumbers = async () => {
      try {

        const { data } = await axios.get('/api/v1/ordinary-numbers')

        setActualNumber(data[0].content)

      } catch (error) { }
    }
    fetchOrdinaryNumbers()

    const fetchOrdinaryOmNumbers = async () => {
      try {

        const { data } = await axios.get('/api/v1/ordinary-om-numbers')

        setActualOmNumber(data[0].content)

      } catch (error) { }
    }
    fetchOrdinaryOmNumbers()

    const fetchOrdinaryMomoNumbers = async () => {
      try {

        const { data } = await axios.get('/api/v1/ordinary-momo-numbers')

        setActualMomoNumber(data[0].content)

      } catch (error) { }
    }
    fetchOrdinaryMomoNumbers()

    const fetchOrdinaryMoovNumbers = async () => {
      try {

        const { data } = await axios.get('/api/v1/ordinary-moov-numbers')

        setActualMoovNumber(data[0].content)

      } catch (error) { }
    }
    fetchOrdinaryMoovNumbers()

  }, [])



  useEffect(()=>{
    if(Logged === 'Logout'){
      window.location.replace("https://nkab-exchange.net/login");
    }
  },[Logged])


  function alerter() {
    props.history.push(`/orderhistory#1`)
  }

  useEffect(() => {
    setStatusCount(twoDigits(secondsRemaining))
  }, [secondsRemaining, statusCount])

  useEffect(() => {
    setStatus(STATUS.STARTED)
  }, [STATUS.STARTED])
  
  useEffect(()=>{
    setStatus2(STATUS2.STARTED2)
  },[STATUS2.STARTED2])

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1)
      } else {
        setStatus(STATUS.STOPPED)
      }
    },

    status === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
  )
  
  useInterval(
    () => {
    if (secondsRemaining2 > 0) {
        setSecondsRemaining2(secondsRemaining2 - 1)
    } else {
        setStatus2(STATUS2.STOPPED2)
    }
    },
  
    status2 === STATUS2.STARTED2 ? 1000 : null,
    // passing null stops the interval
  )

  var [themeMode, setThemeMode] = useState()
  
  let ThemeMode = localStorage.getItem("ThemeMode")
  
  useEffect(()=>{
    
    if(secondsRemaining2 > 0){
      setThemeMode(ThemeMode)
    }
  
  },[ThemeMode, secondsRemaining2])

  useEffect(() => {
    if (
      Number(twoDigits(minutesRemaining)) === 0 &&
      Number(twoDigits(secondsToDisplay)) === 0
    ) {
      setDisplayConfirme(true)
    }
  }, [minutesRemaining, secondsToDisplay])

  
  useEffect(()=>{
    if(id){
      //BUY
      if(operatorAction === 'buy'){
        if(operatorUserInfoCountry === "Côte d'Ivoire"){
          if(operatorListName === 'Orange Money' || operatorListName === 'Mobile Money' || operatorListName === 'Moov Money'){
            const montantTotal = Number( (cfa + (cfa * 0.035)).toFixed(0), )
            setMontantTotal(montantTotal)
          }

          if(operatorListName === 'Wave'){
            const montantTotal = Number( (cfa + (cfa * 0.015)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(operatorUserInfoCountry === "Sénégal"){
          if(operatorListName === 'Orange Money' || operatorListName === 'Free Money'){
            const montantTotal = Number( (cfa + (cfa * 0.025)).toFixed(0), )
            setMontantTotal(montantTotal)
          }

          if(operatorListName === 'Wave'){
            const montantTotal = Number( (cfa + (cfa * 0.018)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(operatorUserInfoCountry === "Togo"){
          if(operatorListName === 'Moov Money'){
            const montantTotal = Number( (cfa + (cfa * 0.025)).toFixed(0), )
            setMontantTotal(montantTotal)
          }

          if(operatorListName === 'TMoney'){
            const montantTotal = Number( (cfa + (cfa * 0.035)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(operatorUserInfoCountry === "Niger"){
          if(operatorListName === 'Orange Money' || operatorListName === 'Moov Money'){
            const montantTotal = Number( (cfa + (cfa * 0.02)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(operatorUserInfoCountry === "Burkina Fasso"){
          if(operatorListName === 'Orange Money'){
            const montantTotal = Number( (cfa + (cfa * 0.045)).toFixed(0), )
            setMontantTotal(montantTotal)
          }

          if(operatorListName === 'Moov Money'){
            const montantTotal = Number( (cfa + (cfa * 0.035)).toFixed(0), )
            setMontantTotal(montantTotal)
          }

          if(operatorListName === 'Wave'){
            const montantTotal = Number( (cfa + (cfa * 0.018)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(operatorUserInfoCountry === "Mali"){
          if(operatorListName === 'Wave'){
            const montantTotal = Number( (cfa + (cfa * 0.018)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(operatorUserInfoCountry === "Benin"){
          if(operatorListName === 'Mobile Money'){
            const montantTotal = Number( (cfa + (cfa * 0.027)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }
      }

      //SELL
      if(operatorAction === 'sel'){
        if(operatorUserInfoCountry === "Côte d'Ivoire"){
          if(operatorListName === 'Orange Money' || operatorListName === 'Mobile Money' || operatorListName === 'Moov Money' || operatorListName === 'Wave'){
            const montantTotal = Number( (cfa - (cfa * 0.02)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(operatorUserInfoCountry === "Sénégal"){
          if(operatorListName === 'Orange Money' || operatorListName === 'Free Money' || operatorListName === 'Wave'){
            const montantTotal = Number( (cfa - (cfa * 0.02)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(operatorUserInfoCountry === "Togo"){
          if(operatorListName === 'Moov Money' || operatorListName === 'TMoney'){
            const montantTotal = Number( (cfa - (cfa * 0.02)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(operatorUserInfoCountry === "Niger"){
          if(operatorListName === 'Moov Money' || operatorListName === 'Orange Money'){
            const montantTotal = Number( (cfa - (cfa * 0.02)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(operatorUserInfoCountry === "Burkina Fasso"){
          if(operatorListName === 'Moov Money' || operatorListName === 'TMoney'){
            const montantTotal = Number( (cfa - (cfa * 0.02)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(operatorUserInfoCountry === "Benin"){
          if(operatorListName === 'Mobile Money'){
            const montantTotal = Number( (cfa - (cfa * 0.02)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

      }
    }
  },[id, cfa, operatorAction, operatorListName, operatorUserInfoCountry])

  useEffect(() => {
    if(montantTotal){
      const cfa_nbr = montantTotal
      const cfa_nbr_to_text = cfa_nbr.toString()
      const cfa_nbr_last_char = cfa_nbr_to_text.slice(-1)
      const last_cfa_char_nbr = Number(cfa_nbr_last_char)
      if (stopExchangeCount === false) {
      
        if (last_cfa_char_nbr === 0) {
          const FinalCount = montantTotal
          setFinalPrice(FinalCount)
          setStopExchangeCount(true)
        }
        if (last_cfa_char_nbr === 5) {
          const FinalCount = montantTotal
          setFinalPrice(FinalCount)
          setStopExchangeCount(true)
        }
        if (last_cfa_char_nbr === 1) {
          const FinalCount = montantTotal - 1
          setFinalPrice(FinalCount)
          setStopExchangeCount(true)
        }
        if (last_cfa_char_nbr === 2) {
          const FinalCount = montantTotal - 2
          setFinalPrice(FinalCount)
          setStopExchangeCount(true)
        }
        if (last_cfa_char_nbr === 3) {
          const FinalCount = montantTotal - 3
          setFinalPrice(FinalCount)
          setStopExchangeCount(true)
        }
        if (last_cfa_char_nbr === 4) {
          const FinalCount = montantTotal - 4
          setFinalPrice(FinalCount)
          setStopExchangeCount(true)
        }
        if (last_cfa_char_nbr === 5) {
          const FinalCount = montantTotal + 0
          setFinalPrice(FinalCount)
          setStopExchangeCount(true)
        }
        if (last_cfa_char_nbr === 6) {
          const FinalCount = montantTotal - 1
          setFinalPrice(FinalCount)
          setStopExchangeCount(true)
        }
        if (last_cfa_char_nbr === 7) {
          const FinalCount = montantTotal - 2
          setFinalPrice(FinalCount)
          setStopExchangeCount(true)
        }
        if (last_cfa_char_nbr === 8) {
          const FinalCount = montantTotal - 3
          setFinalPrice(FinalCount)
          setStopExchangeCount(true)
        }
        if (last_cfa_char_nbr === 9) {
          const FinalCount = montantTotal - 4
          setFinalPrice(FinalCount)
          setStopExchangeCount(true)
        }
      }
    }
  }, [
    montantTotal,
    finalPrice,
    stopExchangeCount,
  ])

  const price = `${(Number(finalPrice2) === 0 &&  Number(finalPrice3) === 0) && (operatorUserInfoCountry !== 'Burkina Fasso' || operatorUserInfoCountry !== 'Mali' || operatorUserInfoCountry !== 'Sénégal') ? finalPrice : 
  (Number(finalPrice2) !== 0 && Number(finalPrice3) === 0) ? finalPrice2 : 
  (Number(finalPrice2) === 0 && Number(finalPrice3) !== 0) && (operatorUserInfoCountry !== 'Burkina Fasso' || operatorUserInfoCountry !== 'Mali' || operatorUserInfoCountry !== 'Sénégal') ? finalPrice3 :
  (Number(finalPrice2) === 0 && Number(finalPrice3) !== 0) && (operatorUserInfoCountry === 'Burkina Fasso' || operatorUserInfoCountry === 'Mali' || operatorUserInfoCountry === 'Sénégal') ? finalPrice : null}`

  const confirmeWaveHandler = () => {
    if(NKAB_Access === false){
      const SenderName = `${operatorUserInfoLastName} ${operatorUserInfoName}`
      const SenderPhone = (operatorListName === "Wave" || Number(finalPrice2) !== 0) && (operatorUserInfoCountry !== "Burkina Fasso" && operatorUserInfoCountry !== "Mali" && operatorUserInfoCountry !== "Sénégal") ? operatorUserInfoPhone : WavePhone
      const SenderChange = SenderCountry === "Togo" &&  operatorListName === "Moov Money" ? cfa + (cfa * 0.015) : price
      const PaymentMethod = ((Number(finalPrice2) === 0 && Number(finalPrice3) === 0) || (Number(finalPrice2) === 0 && Number(finalPrice3) !== 0)) ? operatorListName : (Number(finalPrice2) !== 0 && Number(finalPrice3) === 0) ? "Wave" : null
      const SenderId = operatorUserInfoId
      const OrderId = id
  
      if (
        SenderName &&
        SenderPhone &&
        SenderChange &&
        PaymentMethod &&
        SenderId &&
        OrderId &&
        operatorAction &&
        SenderCountry &&
        serviceName &&
        serviceSymbol &&
        converted &&
        dollar && 
        rate &&
        adr_Coin
      ) {
        axios.post(
            '/api/v1/operation/create',
            {
              SenderId,
              SenderCountry,
              SenderName,
              SenderPhone,
              SenderChange : Number(SenderChange),
              PaymentMethod,
              OrderId,
              operatorAction,
              SenderEmail: UserEmail,
              serviceName,
              serviceSymbol,
              tetherNet,
              converted,
              dollar,
              rate,
              adr_Coin,
              pmode : "wave",
              createdAt: new Date(),
              updatedAt: new Date(),
            },
          )
          .then(
            (response) => {
              setNKAB_Access(true)
              if(response){
                function alertHandler() {
                  confirmAlert({
                    title: `Votre demande de confirmation d'envoi de ${(!finalPrice2 && !finalPrice3) ? finalPrice : (finalPrice2 && !finalPrice3) ? finalPrice2 : (!finalPrice2 && finalPrice3) ? finalPrice3 : SenderCountry === "Togo" &&  operatorListName === "Moov Money" ? cfa + (cfa * 0.015) : null} XOF a été faite avec succès ! `,
                    message: `Nous vous remercions d'avoir fait la demande et vous invitons à suivre le 
                    statut du traitement de votre opération dans votre historique !`,
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => alerter(),
                      },
                    ],
                  })
                }
                setNKAB_Access(true)
                alertHandler()
                if((operatorListName === "Wave" || Number(finalPrice2) !== 0) && (operatorUserInfoCountry !== "Burkina Fasso" && operatorUserInfoCountry !== "Mali" && operatorUserInfoCountry !== "Sénégal")){
                  window.location.replace(`${wavePaymentLink}`)
                }
              }
            },
            (error) => {
              console.log(error)
            },
          )
      }
    }
  }


  const loadingType = 'Décompte en cours...'
  const loadingAdres = "Contrôle de contact entrant en cours..."

  const OM      = "Orange Money"
  const MOMO    = "Mobile Money"
  const MOOV    = "Moov Money"
  const WAVE    = "Wave"
  const TM      = "TMoney"
  const FM      = "Free Money"
  const EM      = "EMoney"

  const om      = "../images/om.png"
  const momo    = "../images/momo.png"
  const flooz   = "../images/flooz.png"
  const wave    = "../images/wave.png"
  const wave_carre    = "../images/wave_carre.png"
  const tm      = "../images/tm.png"
  const fm      = "../images/fm.png"
  const em      = "../images/em.png"

  return (
    <div className="container">
      <div>
        <div className="mt-5 pb-5">
          <div>
            {
              <div>
                <div className={`${themeMode === "true" ? "final-seesion final-seesion-t" : "final-seesion"}`}>
                  <div className="order-left-side">
                    <div className="nkab-card nkab-card-body">
                      <h4 className="final-step-alert">N°: {id } </h4>
                      {operatorAction === 'buy' ? (
                        <>
                          <p style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                            Moyen de paiement :{' '}
                            <strong>{!finalPrice3 && finalPrice2 ? "Wave..." : operatorListName }</strong>
                          </p>
                          <p style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                            Finalisez l'envoi de{' '}
                            <strong>
                              {
                                SenderCountry === "Togo" &&  operatorListName === "Moov Money" ? <>{cfa + (cfa * 0.015)} </> : <>{ price }</>
                              } XOF </strong> 
                               {operatorListName === "Wave" || Number(finalPrice2) !== 0 ? "": operatorListName === "Orange Money" && codePostalCountry === "Côte d'Ivoire" ? "en scannant le code QR ou en composant la syntaxe ci-dessous":"aux numéros ci-dessous"}
                               {operatorListName === "Wave" || Number(finalPrice2) !== 0 ? "via le bouton ": operatorListName === "Orange Money" && codePostalCountry === "Côte d'Ivoire" ? " aux numéros ci-dessous":"aux numéros ci-dessous"}
                            {operatorListName === "Orange Money" && codePostalCountry === "Côte d'Ivoire" ? null:<>
                            depuis votre {(!finalPrice2 && finalPrice3) ? "numéro": "application"}{' '}
                            <strong>{((!finalPrice2 && !finalPrice3) || (!finalPrice2 && finalPrice3)) ? operatorListName : (finalPrice2 && !finalPrice3) ? "Wave" : null}</strong>
                            </>}  avant <strong>5 munites</strong>{operatorListName === "Wave" || Number(finalPrice2) !== 0 ? " et revenez sur le site" : null}.
                          </p>
                          <div>
                            {
                              operatorListName === "Orange Money" && codePostalCountry === "Côte d'Ivoire" ? 
                                <>
                                {
                                  OrdinaryStatus === "enabled" && MarchandStatus === "disabled" ? 
                                  <div className="qr-code-div text-center">
                                    <div className="qr-code-span">
                                      <div style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>  Composez la syntaxe ci-dessous depuis votre téléphone pour finaliser le paiement. <br/><br/>
                                        <h5><strong className='color-or'> #144*1*1# </strong></h5><br/>
                                      </div>
                                      <div><h5 style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Nos numéros : <br/><strong className='color-or'> {ActualNumber}</strong></h5></div>
                                    </div>
                                  </div> :
                                  OrdinaryStatus === "disabled" && MarchandStatus === "enabled" ? 
                                  <div className="qr-code-div text-center">
                                    <div className="qr-code-span">
                                      <div> Veuillez scanner ce code QR ci-dessous depuis votre <strong className='color-or uppercase'>application Orange Money</strong> et choisir l'option <strong className='text-info uppercase'>Payer un marchand</strong> afin de  finaliser la transaction de
                                      <strong className='success'> {price} XOF </strong>
                                      </div>
                                    </div>
                                    <div>
                                      <img src="../../images/qr_code_om.png" alt="qr code om nkab exchange" />
                                    </div><br/>
                                    <div className='color-w bolder'>OU</div>
                                    <div className="qr-code-span">
                                      <div> Si vous n'avez pas l'application, vous pouvez composer la syntaxe ci-dessous depuis votre téléphone pour finaliser le paiement. <br/><br/>
                                        <h5><strong className='text-info'> #144*491# </strong></h5><br/>
                                      </div>
                                      <div><h5>Numéro marchand : <br/><strong className='text-info'> +225 07 06 13 15 04</strong></h5></div>
                                    </div>
                                  </div>:null
                                  }
                                </>
                              : 
                              <div>
                                  { 
                                    ((operatorListName === "Wave" || Number(finalPrice2) !== 0) && (operatorUserInfoCountry !== "Burkina Fasso" && operatorUserInfoCountry !== "Mali" && operatorUserInfoCountry !== "Sénégal")) ? null :
                                    <><label className='color-b bolder'>Nos numéros sur lesquels vous devez payer :</label><br/></>
                                  }
                                  {
                                    ((operatorListName === "Wave" || Number(finalPrice2) !== 0) && (operatorUserInfoCountry !== "Burkina Fasso" && operatorUserInfoCountry !== "Mali" && operatorUserInfoCountry !== "Sénégal")) ? 
                                    <div className='text-center mt-5 mb-5' style={{borderRadius:'5px'}}>
                                      <h4 className='color-b bolder my_last_wave_button' onClick={confirmeWaveHandler}>
                                        <span className='color-black' style={{fontSize:'14px'}}>Cliquez ici pour payer avec votre téléphone <img className='images-drapeau ml-2mr-2' src={`../${wave_carre}`} alt="" /></span><br/>
                                        <span style={{fontSize:'30px', textShadow: '2px 2px #00040a'}}><strong>{`${(!finalPrice2 && !finalPrice3) ? finalPrice : (finalPrice2 && !finalPrice3) ? finalPrice2 : (!finalPrice2 && finalPrice3) ? finalPrice3 : null} XOF`}</strong></span>
                                      </h4>
                                    </div> : null
                                  }
                                <div className='tel_blink blink pl-2'>
                                  {
                                    operatorListName === "Wave" && Number(finalPrice2) === 0 && (operatorUserInfoCountry === "Burkina Fasso" || operatorUserInfoCountry === "Mali" || operatorUserInfoCountry === "Sénégal") ? 
                                    <div><h4><strong>+225 {ActualOmNumber}</strong></h4> </div> : null
                                  }
                                  {
                                    operatorListName === "Orange Money" && Number(finalPrice2) === 0  ? 
                                    <>
                                      <div> <h4><strong>+225 {ActualOmNumber}</strong></h4> </div>
                                    </> : null
                                  }
                                  {
                                    operatorListName === "Mobile Money" && Number(finalPrice2) === 0 ? 
                                    <div> <h4><strong>+225 {ActualMomoNumber}</strong></h4> </div> : null
                                  }
                                  {
                                    operatorListName === "Moov Money" && Number(finalPrice2) === 0 ? 
                                    <div><h4><strong>+225 {ActualMoovNumber}</strong></h4> </div> : null
                                  }
                                </div>
                              </div>
                            }
                          </div>
                        </>
                      ) : operatorAction === 'sel' ? (
                        <p style={{color:`${themeMode === "true" ? "#ffa2a2" : "color-r"}`}}>
                          Vous recevrez votre paiement selon l'opérateur réseaux que vous avez choisi précédemment qui est: [{operatorListName}]
                        </p>
                      ) : null}
                    </div>
                    <div className="nkab-card nkab-card-body uppercase">
                      {operatorAction === 'buy' ? (
                        <div className='text-center'>
                          <div className='bolder success'>ACHAT</div>
                          <div className='d-flex justify-content-between'>
                            <span>
                              {
                                operatorListName === OM ? 
                                <img className='images-drapeau mr-2' src={`../${om}`} alt="" />:
                                operatorListName === MOMO ? 
                                <img className='images-drapeau mr-2' src={`../${momo}`} alt="" />:
                                operatorListName === MOOV ? 
                                <img className='images-drapeau mr-2' src={`../${flooz}`} alt="" />:
                                operatorListName === WAVE ? 
                                <img className='images-drapeau mr-2' src={`../${wave}`} alt="" />:
                                operatorListName === TM ? 
                                <img className='images-drapeau mr-2' src={`../${tm}`} alt="" />:
                                operatorListName === FM ? 
                                <img className='images-drapeau mr-2' src={`../${fm}`} alt="" />:
                                operatorListName === EM ? 
                                <img className='images-drapeau mr-2' src={`../${em}`} alt="" /> : null
                              }
                              </span>
                              <span ><FontAwesomeIcon icon={faArrowAltCircleRight} style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}} /></span>
                              <span ><img className='images-drapeau mr-2' src={`../${serviceImageLink}`} alt="" /></span>
                            </div>
                          </div>
                      ) : operatorAction === 'sel' ? (
                        <div className='text-center'>
                          <div className='bolder danger'>VENTE</div>
                          <div className='d-flex justify-content-between'>
                            <span><img className='images-drapeau mr-2' src={`../${serviceImageLink}`} alt="" /></span>
                            <span><FontAwesomeIcon icon={faArrowAltCircleRight} style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}/></span>
                            <span>
                            {
                                operatorListName === OM ? 
                                <img className='images-drapeau mr-2' src={`../${om}`} alt="" />:
                                operatorListName === MOMO ? 
                                <img className='images-drapeau mr-2' src={`../${momo}`} alt="" />:
                                operatorListName === MOOV ? 
                                <img className='images-drapeau mr-2' src={`../${flooz}`} alt="" />:
                                operatorListName === WAVE ? 
                                <img className='images-drapeau mr-2' src={`../${wave}`} alt="" />:
                                operatorListName === TM ? 
                                <img className='images-drapeau mr-2' src={`../${tm}`} alt="" />:
                                operatorListName === FM ? 
                                <img className='images-drapeau mr-2' src={`../${fm}`} alt="" />:
                                operatorListName === EM ? 
                                <img className='images-drapeau mr-2' src={`../${em}`} alt="" /> : null
                            }
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {
                    (operatorListName === "Wave" || Number(finalPrice2) !== 0) && (operatorUserInfoCountry !== "Burkina Fasso" && operatorUserInfoCountry !== "Mali" && operatorUserInfoCountry !== "Sénégal") ? null:
                    <div className="order-right-side">
                      <div className="nkab-card nkab-card-body">
                        <div>
                          <h5 className="confirmeCounter">
                            <div>
                              {displayConfirme === false ? (
                                <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Bouton de validation dans...</span>
                              ) : displayConfirme === true ? (
                                <>
                                <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}> <br/>
                                  Renseignez votre numéro de téléphone ayant servi à valider la transaction !
                                </span><br/><br/>
                                <input
                                  className="wavePaymentNameInput"
                                  type="text"
                                  placeholder="Entrez votre numéro de téléphone créditeur"
                                  onChange={(e) => setWavePhone(e.target.value)
                                  }
                                />
                                </>
                              ) : null}
                            </div>
                            {displayConfirme === false ? (
                              <div className='bolder' style={{color:`${themeMode === "true" ? "#90d9ff" : "color-r"}`}}>
                                <span> {Number(twoDigits(minutesRemaining))} {'mn'}{' '} </span>
                                <span>{' : '}</span>
                                <span> {Number(twoDigits(secondsToDisplay))} {'s'}{' '} </span>
                              </div>
                            ) : displayConfirme === true ? null : null}
                          </h5>
                        </div>
                      </div>
                      {displayConfirme === false ? (
                        <>
                          <LoadingBox loadingType={loadingType} />
                          {
                            operatorListName === "Orange Money" && codePostalCountry === "Côte d'Ivoire" ? null : 
                              <div className='tel_blink blink pl-2'>
                                {
                                  operatorListName === "Wave" && Number(finalPrice2) === 0 && (operatorUserInfoCountry === "Burkina Fasso" || operatorUserInfoCountry === "Mali" || operatorUserInfoCountry === "Sénégal") ? 
                                  <div><h4><strong>+225 {ActualOmNumber}</strong></h4> </div> : null
                                }
                                {
                                  operatorListName === "Orange Money" && Number(finalPrice2) === 0  ? 
                                  <>
                                    <div> <h4><strong>+225 {ActualOmNumber}</strong></h4> </div>
                                  </> : null
                                }
                                {
                                  operatorListName === "Mobile Money" && Number(finalPrice2) === 0 ? 
                                  <div> <h4><strong>+225 {ActualMomoNumber}</strong></h4> </div> : null
                                }
                                {
                                  operatorListName === "Moov Money" && Number(finalPrice2) === 0 ? 
                                  <div><h4><strong>+225  {ActualMoovNumber}</strong></h4> </div> : null
                                }
                              </div>
                          }
                        </>
                      ) : displayConfirme === true ? (
                        <>
                          <div className="nkab-card nkab-card-body">
                            {
                              !WavePhone && adrTotal < limitAdrCount ? 
                              <h2 className="confirmeDisplaing2" > Valider</h2>:
                              WavePhone && adrTotal >= limitAdrCount ? 
                              <h2 className="confirmeDisplaing" onClick={confirmeWaveHandler} >
                                Valider </h2>:<LoadingBox loadingType={loadingAdres} />
                            }
                          </div>
                          {
                            operatorListName === "Orange Money" && codePostalCountry === "Côte d'Ivoire" ? null :
                            <div className='tel_blink blink pl-2'>
                              {
                                operatorListName === "Wave" && Number(finalPrice2) === 0 && (operatorUserInfoCountry === "Burkina Fasso" || operatorUserInfoCountry === "Mali" || operatorUserInfoCountry === "Sénégal") ?
                                <div><h4><strong>+225 {ActualOmNumber}</strong></h4> </div> : null
                              }
                              {
                                operatorListName === "Orange Money" && Number(finalPrice2) === 0  ?
                                <>
                                  <div> <h4><strong>+225 {ActualOmNumber}</strong></h4> </div>
                                </> : null
                              }
                              {
                                operatorListName === "Mobile Money" && Number(finalPrice2) === 0 ?
                                <div> <h4><strong>+225 {ActualMomoNumber}</strong></h4> </div> : null
                              }
                              {
                                operatorListName === "Moov Money" && Number(finalPrice2) === 0 ?
                                <div><h4><strong>+225  {ActualMoovNumber}</strong></h4> </div> : null
                              }
                            </div>
                          }
                        </>
                      ) : null}
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <ChatCustomer />
      <BottomNavBar />
    </div>
  )
}

function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const twoDigits = (num) => String(num).padStart(2, '0')