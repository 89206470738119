import React, { useState, useEffect, useRef }  from 'react';
import './NkabPro.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt, faKey, faListAlt, faMoneyBill, faPlusCircle, faSignOutAlt, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import ReactSwitch from 'react-switch';
import coinGecko from '../api/coinGecko';
import axios from 'axios';
import { Alert, AppBar, Avatar, Box, Button, Card, CardActions, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, Link, Menu, MenuItem, OutlinedInput, Radio, RadioGroup, Toolbar, Tooltip, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MenuIcon from '@mui/icons-material/Menu';
import LightModeIcon from '@mui/icons-material/LightMode';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import Footer from '../components/Footer';
import HomeVariations from '../components/HomeVariations';
import RInterfaceComponent from '../components/RInterfaceComponent';
import BInterfaceComponent from '../components/BInterfaceComponent';
import TInterfaceComponent from '../components/TInterfaceComponent';
import OpInterfaceComponent from '../components/OpInterfaceComponent';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';



const NkabPro = (props) => {

    const transActivated         = localStorage.getItem('transActivated')
    const NewCode                = localStorage.getItem('NewCode')
    const UpdateCode             = localStorage.getItem('UpdateCode')
    const userPhone              = localStorage.getItem('userPhone')
    const userEmail              = localStorage.getItem('userEmail')
    const Logged                 = localStorage.getItem('userStatus')
    const r_opsd                 = localStorage.getItem("r_opsd")
    const t_opsd                 = localStorage.getItem("t_opsd")
    const b_opsd                 = localStorage.getItem("b_opsd")
    const userID                 = localStorage.getItem('userID')
    var   _ProWallet             = localStorage.getItem('_ProWallet')
    var   _options               = localStorage.getItem("_options")
    const moneyImage             = localStorage.getItem("moneyImage")
    const moneyName              = localStorage.getItem("moneyName")
    const banqueName             = localStorage.getItem("banqueName")
    const changeImage            = localStorage.getItem("changeImage")
    const userLastName           = localStorage.getItem("userLastName")
    const userName               = localStorage.getItem("userName")
    const nkab_trans_reference   = localStorage.getItem("nkab_trans_reference")
    const nkab_rge_reference     = localStorage.getItem("nkab_rge_reference")
    const nkab_trans_id          = localStorage.getItem("nkab_trans_id")
    const nkab_rge_id            = localStorage.getItem("nkab_rge_id")
    const LastStatus             = localStorage.getItem("LastStatus")
    const amount                 = localStorage.getItem("amount")
    const phone                  = localStorage.getItem("phone")
    const buy_sop                = localStorage.getItem("buy_sop")
    const trans_sop              = localStorage.getItem("trans_sop")
    const rch_sop                = localStorage.getItem("rch_sop")
    const RI = localStorage.getItem("RI")
    const BI = localStorage.getItem("BI")
    const TI = localStorage.getItem("TI")
    const OpI = localStorage.getItem("OpI")


    const [CodePin,                 setCodePin                  ] = useState()
    const [Montant,                 setMontant                  ] = useState(0)
    const [BankOptionClicked1,      setBankOptionClicked1       ] = useState("")
    const [BankOptionClicked2,      setBankOptionClicked2       ] = useState("")
    const [BankOptionClicked3,      setBankOptionClicked3       ] = useState("")
    const [BankOptionClicked4,      setBankOptionClicked4       ] = useState("")
    const [BanqueStatus,            setBanqueStatus             ] = useState(false)
    const [DangerControler,         setDangerControler          ] = useState(false)
    const [PassCode,                setPassCode                 ] = useState(false)
    const [Profil,                  setProfil                   ] = useState(false)
    const [passwordInput,           setPasswordInput            ] = useState("")
    const [coins,                   setCoins                    ] = useState([])
    const [coins2,                  setCoins2                   ] = useState([])
    const [Listing,                 setListing                  ] = useState(false)
    const [SeeDoc,                  setSeeDoc                   ] = useState(false)
    const [RechargeData,            setRechargeData             ] = useState([])
    const [TransData,               setTransData                ] = useState([])
    const [BuyData,                 setBuyData                  ] = useState([])
    const [PCodes,                  setPCodes                   ] = useState([])
    const [Wallets,                 setWallets                  ] = useState([])
    const [RechargeWallets,         setRechargeWallets          ] = useState([])
    const [BuyingWallets,           setBuyingWallets            ] = useState([])
    const [TransWallets,            setTransWallets             ] = useState([])
    const [PremiumsWallets,         setPremiumsWallets       ] = useState([])
    const [MyCodePin,               setMyCodePin                ] = useState([])
    const [MyCodePinID,             setMyCodePinID              ] = useState([])
    const [MySuccess,               setMySuccess                ] = useState("")
    const [Loader,                  setLoader                   ] = useState(false)
    const [SeePrice,                setSeePrice                 ] = useState(false)
    const [isActive,                setActive                   ] = useState(false)
    const [GetUser,                 setGetUser                  ] = useState([])
    const [User,                    setUser                     ] = useState([])
    const [checked,                 setChecked                  ] = useState(true)
    const [BuySuccess,              setBuySuccess               ] = useState(false)
    const [BuyFailed,               setBuyFailed                ] = useState(false)
    const [TransSuccess,            setTransSuccess             ] = useState(false)
    const [TransFailed,             setTransFailed              ] = useState(false)
    const [PaymentSuccess,          setPaymentSuccess           ] = useState(false)
    const [PaymentFailed,           setPaymentFailed            ] = useState(false)
    const [Phone,                   setPhone                    ] = useState("")



    const [NT_ProrateBNB, setNT_ProrateBNB] = useState([])
    const [NT_ProrateBTC, setNT_ProrateBTC] = useState([])
    const [NT_ProrateTRON, setNT_ProrateTRON] = useState([])
    const [NT_ProrateUSDT, setNT_ProrateUSDT] = useState([])
    const [NT_ProrateLINK, setNT_ProrateLINK] = useState([])
    const [NT_ProrateMATIC, setNT_ProrateMATIC] = useState([])
    const [NT_ProrateDOTE, setNT_ProrateDOTE] = useState([])
    const [NT_ProrateBUSD, setNT_ProrateBUSD] = useState([])
    const [NT_ProratePM, setNT_ProratePM] = useState([])
    const [NT_ProrateSOL, setNT_ProrateSOL] = useState([])
    const [NT_ProrateADA, setNT_ProrateADA] = useState([])
    const [NT_ProrateSHIBA, setNT_ProrateSHIBA] = useState([])
    const [NT_ProrateDOGE, setNT_ProrateDOGE] = useState([])
    const [NT_ProrateLTC, setNT_ProrateLTC] = useState([])

    const [showPassword, setShowPassword] = useState(false);
    const [RF, setRF] = useState([]);
  
    const [counter, setCounter] = useState(30);
    const [StopOperation, setStopOperation] = useState(false)

    const LineLink = true
    // const LineLink = false
    
    useEffect(()=>{
        const fetchRFData = async () => {
            if(!StopOperation){
                try {
                    const res = await axios.get('api/v1/all-p-op')
                    if(res.data.length > 0 && userID){
                      const ResFilted = res.data.filter((items) => {
                        return items.userId === Number(userID)
                      })
                            
                      if(ResFilted.length > 0 && counter === 0){
                      
                        const items = ResFilted.slice(0, 30)
                    
                        if(items.length > 0){
                          setRF(items)
                          setStopOperation(true)
                        }
                    
                      } else if(ResFilted.length > 0 && counter > 0){
                    
                        const items = ResFilted.slice(0, counter)
                    
                        if(items.length > 0){
                          setRF(items)
                          setStopOperation(true)
                        }
                      }
                    }
                } catch (error) { }
            }
        }
        fetchRFData()
  
    },[userID, counter, StopOperation])

    const _ci       = "Côte d'Ivoire"
    const _sen      = "Sénégal"
    const _burk     = "Burkina Faso"
    const _benin    = "Bénin"
    const _togo     = "Togo"
    const _mali     = "Mali"
    const _niger    = "Niger"

    const INIT = { montant: 0, converted: 0, cfa: 0, dollar: 0 }
    const [exchange, setExchange] = useState(INIT)
  
    useEffect(()=>{

        if(Logged === 'Logged'){
            if(_ProWallet === "false"){

                if(LineLink){
                  window.location.replace("https://nkab-exchange.net/sell-or-buy-crypto");
                } else {
                   window.location.replace("http://localhost:3000/sell-or-buy-crypto");
                }
            }
        }

        if(Logged === 'Logout'){

            if(LineLink){
              window.location.replace("https://nkab-exchange.net/login");
            } else {
               window.location.replace("http://localhost:3000/login");
            }
        }
  
    },[Logged, props.history, _ProWallet, LineLink])

    useEffect(() => {
        if(r_opsd === "true" || t_opsd === "true" || b_opsd === "true"){
            setActive(true)
        }
    }, [r_opsd,t_opsd,b_opsd])
  
    const handleCloser = () => {
        setLoader(false)
        localStorage.setItem("r_opsd",false)
        localStorage.setItem("t_opsd",false)
        localStorage.setItem("b_opsd",false)
        setActive(false)
        setStatus1(STATUS.STOPPED)
    };

    const STATUS = { STARTED: 'Traitement en cours...', }

    useEffect(()=>{
        if(r_opsd === "true" || t_opsd === "true" || b_opsd === "true"){
            setStatus(STATUS.STARTED)
        }
    },[r_opsd,t_opsd,b_opsd,STATUS.STARTED])

    const INITIAL_COUNT1 = 3600

    const [secondsRemaining1, setSecondsRemaining1] = useState(INITIAL_COUNT1)
    const [status1, setStatus1] = useState(STATUS.STOPPED)

    const INITIAL_COUNT = 600
  
    const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT)
    const [status, setStatus] = useState(STATUS.STOPPED)
    const [statusCount, setStatusCount] = useState()
  
    const secondsToDisplay = secondsRemaining % 60
    const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60

    const[last_cfa_char_nbr, setLast_cfa_char_nbr] = useState()
    const [total,setTotal] = useState()

    useEffect(()=>{
        setStatus1(STATUS.STARTED)
    },[STATUS.STARTED])
    
    useEffect(()=>{

        if(secondsRemaining1 > 0){
          setTotal(secondsRemaining1)
          const cfa_nbr = total
          if(cfa_nbr){
            const cfa_nbr_to_text = cfa_nbr.toString()
            const cfa_nbr_last_char = cfa_nbr_to_text.slice(-2)
            setLast_cfa_char_nbr(Number(cfa_nbr_last_char))
          }
        }

      },[secondsRemaining1, total])

      useEffect(() => {
        setStatusCount(twoDigits(secondsRemaining))
      }, [secondsRemaining, statusCount])

      useInterval(
          () => {
          if (secondsRemaining > 0) {
              setSecondsRemaining(secondsRemaining - 1)
          } else {
              setStatus(STATUS.STOPPED)
          }
          if (secondsRemaining1 > 0) {
              setSecondsRemaining1(secondsRemaining1 - 1)
          } else {
              setStatus1(STATUS.STOPPED)
          }
          },
  
          status === STATUS.STARTED ? 1000 : null,
  
          status1 === STATUS.STARTED ? 1000 : null,
          // passing null stops the interval
      )

      function useInterval(callback, delay) {
          const savedCallback = useRef()
  
          // Remember the latest callback.
          useEffect(() => {
          savedCallback.current = callback
          }, [callback])
  
      // Set up the interval.
      useEffect(() => {
        function tick() {
          savedCallback.current()
        }
        if (delay !== null) {
          let id = setInterval(tick, delay)
          return () => clearInterval(id)
        }
      }, [delay])
      }

    const twoDigits = (num) => String(num).padStart(2, '0')
  
    const handleCloser2 = () => { localStorage.setItem('isRActive', false)};

    const [isMenuVisible, setMenuVisibility] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        const isVisible = prevScrollPos > currentScrollPos;
    
        setMenuVisibility(isVisible);
        setPrevScrollPos(currentScrollPos);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [prevScrollPos]);



  const STATUS2 = {
    STARTED2: 'Traitement en cours...',
    }
  
    const INITIAL_COUNT2 = 35
    const [secondsRemaining2, setSecondsRemaining2] = useState(INITIAL_COUNT2)
    const [status2,           setStatus2          ] = useState(STATUS2.STOPPED2)

    useEffect(()=>{
        setStatus2(STATUS2.STARTED2)
        if (secondsRemaining2 === 0){
            setSecondsRemaining2(INITIAL_COUNT2)
        }
    },[secondsRemaining2, STATUS2.STARTED2, INITIAL_COUNT2])
  
    useInterval(
    () => {
    if (secondsRemaining2 > 0) {
        setSecondsRemaining2(secondsRemaining2 - 1)
    } else {
        setStatus2(STATUS2.STOPPED2)
    }
    },

    status2 === STATUS2.STARTED2 ? 1000 : null,
    // passing null stops the interval
    )


    useEffect(() => {
  
      const fetchDada12 = async () => {
        try {
          const { data } = await axios.get('/api/v1/get-users')
          setGetUser(data)
        } catch (error) { }
      }
      fetchDada12()
  
    }, [])
  
    useEffect(() => {
  
      if (GetUser && userID) {
        const userGetted = GetUser.filter((obj) => {
          return obj.ID === Number(userID)
        })
        if(userGetted.length > 0){
            setUser(userGetted[0])
        }
      }
  
    }, [GetUser, userID, User])

    const [MerchantId, setMerchantId] = useState("")
    const [MerchantIdKey, setMerchantIdKey] = useState("")
    const [PayInRate, setPayInRate] = useState([])
    const [PayOutRate, setPayOutRate] = useState([])

    const ci = "Côte d'Ivoire"
    const sen = "Sénégal"
    const burk = "Burkina Fasso"
    const benin = "Bénin"
    const togo = "Togo"
    const mali = "Mali"
    const niger = "Niger"
    const cam = "Cameroun"
    const guinee = "Guinée"

    const toogleHandleChange = (val) => {
        localStorage.setItem("_ProWallet", false)
        localStorage.setItem("transActivated", true)
        setChecked(val)
        localStorage.setItem("isRActive",false)
    }

    useEffect(()=>{
      const fetchDada = async () => {
        const response = await coinGecko.get('/coins/markets', {
          params: {
            vs_currency: 'usd',
            ids:
              'bitcoin,litecoin,binancecoin,eos,ethereum,iota,ripple,stellar,tether,dogecoin,tron,binance-usd,shiba-inu,matic-network,chainlink,polkadot,cardano,solana',
          },
        })
        setCoins(response.data)
      }
      fetchDada()
      
      const fetchDada2 = async () => {

        const response = await coinGecko.get('/coins/markets', {
          params: {
            vs_currency: 'usd',
            ids:
              'bitcoin,litecoin,tether,dogecoin,tron,binance-usd',
          },
        })
        setCoins2(response.data)
      }
      fetchDada2()
    },[])



    const btcTaux      = NT_ProrateBTC
    const usdtTaux     = NT_ProrateUSDT
    const pmTaux       = NT_ProratePM
    const bnbTaux      = NT_ProrateBNB
    const adaTaux      = NT_ProrateADA
    const dogeTaux     = NT_ProrateDOGE
    const solTaux      = NT_ProrateSOL
    const tronTaux     = NT_ProrateTRON
    const ltcTaux      = NT_ProrateLTC
    const maticTaux    = NT_ProrateMATIC
    const dotTaux      = NT_ProrateDOTE
    const shibTaux     = NT_ProrateSHIBA
    const busdTaux     = NT_ProrateBUSD
    const linkTaux     = NT_ProrateLINK

    
    const taux = moneyName === "Bitcoin"?btcTaux:moneyName === "Tether"?usdtTaux:moneyName === "Binance USD"?busdTaux:moneyName === "Perfect Money"?pmTaux:moneyName === "Litecoin"?ltcTaux:moneyName === "Binance"?bnbTaux:moneyName === "Cardano"?adaTaux:moneyName === "Polkadot"?dotTaux:moneyName === "Polygon"?maticTaux:moneyName === "Shiba"?shibTaux:moneyName === "Solana"?solTaux:moneyName === "Tron"?tronTaux:moneyName === "Chain link"?linkTaux:moneyName === "Dogecoin"?dogeTaux:600

    const buylimit = 400
    const buyMin = buylimit*taux

    const charginglimit = 16
    const chargingMin = charginglimit*taux

    useEffect(()=>{

        const fetchDada = async () => {

            if(secondsRemaining2 > 0){
                try {
                    const { data } = await axios.get('/api/v1/get-p-recharge')
                    setRechargeData(data)
    
                    const rchDataFilted = data.filter((obj)=>{
                        return obj.tr_Id === rch_sop
                    })
    
                    if(rchDataFilted[0].status === "successful"){
        
                        setPaymentSuccess(rchDataFilted[0].status)

                        if(PaymentSuccess === true){

                            localStorage.removeItem("nkab_rge_reference")
                            localStorage.removeItem("nkab_rge_id")
                            localStorage.removeItem("hub2token")
                            localStorage.removeItem("hub2id")
                            localStorage.removeItem("LastStatus")
                            localStorage.removeItem("amount")
                            localStorage.removeItem("phone")
                            localStorage.removeItem("rch_sop")
                            localStorage.removeItem("trans_sop")
                            setSeeDoc(false)
                            setLoader(false)
                            setMontant(0)
                            localStorage.removeItem("banqueImage")
                            localStorage.removeItem("banqueName")
                            localStorage.removeItem("changeImage")
                            localStorage.removeItem("changeName")
                            localStorage.removeItem("moneyImage")
                            localStorage.removeItem("moneyName")
                            localStorage.removeItem("banqueClickedName")
                            setBanqueStatus(false)
                            setBankOptionClicked1("")
                            setBankOptionClicked2("")
                            setBankOptionClicked3("")
                            setBankOptionClicked4("")
                        }
                        
                    }
    
                    if(rchDataFilted[0].status === "failed"){

                        setPaymentFailed(rchDataFilted[0].status)

                        if(PaymentFailed === true){
                            localStorage.removeItem("nkab_rge_reference")
                            localStorage.removeItem("nkab_rge_id")
                            localStorage.removeItem("hub2token")
                            localStorage.removeItem("hub2id")
                            localStorage.removeItem("LastStatus")
                            localStorage.removeItem("amount")
                            localStorage.removeItem("phone")
                            localStorage.removeItem("rch_sop")
                            localStorage.removeItem("trans_sop")
                            setSeeDoc(false)
                            setLoader(false)
                            setMontant(0)
                            localStorage.removeItem("banqueImage")
                            localStorage.removeItem("banqueName")
                            localStorage.removeItem("changeImage")
                            localStorage.removeItem("changeName")
                            localStorage.removeItem("moneyImage")
                            localStorage.removeItem("moneyName")
                            localStorage.removeItem("banqueClickedName")
                            setBanqueStatus(false)
                            setBankOptionClicked1("")
                            setBankOptionClicked2("")
                            setBankOptionClicked3("")
                            setBankOptionClicked4("")
                        }
    
    
                        
                    }
    
                } catch (error) {}
    
                try {
                    const { data } = await axios.get('/api/v1/get-p-trans')
                    setTransData(data)
    
                    const transDataFilted = data.filter((obj)=>{
                        return obj.tr_Id === trans_sop
                    })
    
                    if(transDataFilted[0].status === "successful"){
    
                        setTransSuccess(transDataFilted[0].status)

                        if(TransSuccess === true){

                            localStorage.removeItem("nkab_rge_reference")
                            localStorage.removeItem("nkab_rge_id")
                            localStorage.removeItem("_options")
                            localStorage.removeItem("nkabMarkTitle")
                            localStorage.removeItem("hub2token")
                            localStorage.removeItem("hub2id")
                            localStorage.removeItem("LastStatus")
                            localStorage.removeItem("amount")
                            localStorage.removeItem("phone")
                            localStorage.removeItem("rch_sop")
                            localStorage.removeItem("trans_sop")
                            setSeeDoc(false)
                            setLoader(false)
                            setMontant(0)
                            localStorage.removeItem("banqueImage")
                            localStorage.removeItem("banqueName")
                            localStorage.removeItem("changeImage")
                            localStorage.removeItem("changeName")
                            localStorage.removeItem("moneyImage")
                            localStorage.removeItem("moneyName")
                            localStorage.removeItem("banqueClickedName")
                            setBanqueStatus(false)
                            setBankOptionClicked1("")
                            setBankOptionClicked2("")
                            setBankOptionClicked3("")
                            setBankOptionClicked4("")
                        }
                        
                    }
    
                    if(transDataFilted[0].status === "failed"){

                        setTransFailed(transDataFilted[0].status)

                        if(TransFailed === true){
                            localStorage.removeItem("nkab_rge_reference")
                            localStorage.removeItem("nkab_rge_id")
                            localStorage.removeItem("hub2token")
                            localStorage.removeItem("hub2id")
                            localStorage.removeItem("LastStatus")
                            localStorage.removeItem("amount")
                            localStorage.removeItem("phone")
                            localStorage.removeItem("rch_sop")
                            localStorage.removeItem("trans_sop")
                            setSeeDoc(false)
                            setLoader(false)
                            setMontant(0)
                            localStorage.removeItem("banqueImage")
                            localStorage.removeItem("banqueName")
                            localStorage.removeItem("changeImage")
                            localStorage.removeItem("changeName")
                            localStorage.removeItem("moneyImage")
                            localStorage.removeItem("moneyName")
                            localStorage.removeItem("banqueClickedName")
                            setBanqueStatus(false)
                            setBankOptionClicked1("")
                            setBankOptionClicked2("")
                            setBankOptionClicked3("")
                            setBankOptionClicked4("")
                        }
    
                    }
                } catch (error) {}
    
                try {
                    const { data } = await axios.get('/api/v1/get-p-buy')
                    setBuyData(data)
    
                    const buyDataFilted = data.filter((obj)=>{
                        return obj.tr_Id === buy_sop
                    })
    
                    if(buyDataFilted[0].status === "successful"){
    
                        setBuySuccess(buyDataFilted[0].status)

                        if(BuySuccess === true){

                            localStorage.removeItem("nkab_rge_reference")
                            localStorage.removeItem("nkab_rge_id")
                            localStorage.removeItem("hub2token")
                            localStorage.removeItem("hub2id")
                            localStorage.removeItem("LastStatus")
                            localStorage.removeItem("amount")
                            localStorage.removeItem("phone")
                            localStorage.removeItem("rch_sop")
                            localStorage.removeItem("buy_sop")
                            setSeeDoc(false)
                            setLoader(false)
                            setMontant(0)
                            localStorage.removeItem("banqueImage")
                            localStorage.removeItem("banqueName")
                            localStorage.removeItem("changeImage")
                            localStorage.removeItem("changeName")
                            localStorage.removeItem("moneyImage")
                            localStorage.removeItem("moneyName")
                            localStorage.removeItem("banqueClickedName")
                            setBanqueStatus(false)
                            setBankOptionClicked1("")
                            setBankOptionClicked2("")
                            setBankOptionClicked3("")
                            setBankOptionClicked4("")
                        }
                        
                    }
    
                    if(buyDataFilted[0].status === "failed"){

                        setBuyFailed(buyDataFilted[0].status)

                        if(BuyFailed === true){
                            localStorage.removeItem("nkab_rge_reference")
                            localStorage.removeItem("nkab_rge_id")
                            localStorage.removeItem("hub2token")
                            localStorage.removeItem("hub2id")
                            localStorage.removeItem("LastStatus")
                            localStorage.removeItem("amount")
                            localStorage.removeItem("phone")
                            localStorage.removeItem("rch_sop")
                            localStorage.removeItem("buy_sop")
                            setSeeDoc(false)
                            setLoader(false)
                            setMontant(0)
                            localStorage.removeItem("banqueImage")
                            localStorage.removeItem("banqueName")
                            localStorage.removeItem("changeImage")
                            localStorage.removeItem("changeName")
                            localStorage.removeItem("moneyImage")
                            localStorage.removeItem("moneyName")
                            localStorage.removeItem("banqueClickedName")
                            setBanqueStatus(false)
                            setBankOptionClicked1("")
                            setBankOptionClicked2("")
                            setBankOptionClicked3("")
                            setBankOptionClicked4("")
                        }
    
                    }
                } catch (error) {}
    
                try {
                    const { data } = await axios.get('/api/v1/get-p-newcp')
                    setPCodes(data)
                } catch (error) {}

            }

        }

        fetchDada()

    },[rch_sop, secondsRemaining2, trans_sop, PaymentSuccess, STATUS.STOPPED, TransFailed, TransSuccess])

    const [PremiumLength, setPremiumLength] = useState([])
    const [PremiumsData, setPremiumsData] = useState([])
    const [AllPremiums, setAllPremiums] = useState([])
    
    useEffect(()=>{

    const fetchData = async () => {
        try {

        const { data } = await axios.get('/api/premium-pack/subscribe')

        if(data.length > 0){

            const mypremiumFeilted = data.filter((obj)=>{
                return obj.userId === userID
            })

            setPremiumLength(mypremiumFeilted.length)
            setPremiumsData(mypremiumFeilted[0])
            setAllPremiums(mypremiumFeilted)
        }

        } catch (error) { }
    }
    fetchData()

    },[userID])
    
  
    useEffect(()=>{
        if(secondsRemaining2 === 0 || secondsRemaining2 === 5 || secondsRemaining2 === 10 || secondsRemaining2 === 15 || secondsRemaining2 === 20 || secondsRemaining2 === 25 || secondsRemaining2 === 30 || secondsRemaining2 === 35){
          
            if((RechargeData.length > 1 || BuyData.length > 1 || TransData.length > 1) && User.ID){
          
              ///////////////// RECHARGE ///////////////////
              if(RechargeData.length > 0){
                  const RechargeUserDatafiltered = RechargeData.filter(obj => {
                      return obj.userId === User.ID.toString();
                    });
      
                    if(RechargeUserDatafiltered.length > 0){
      
                      const RechargeDatafiltered = RechargeUserDatafiltered.filter(obj => {return obj.status === "successful";});
          
                      const RechargeDatas = (RechargeDatafiltered.reduce((total, currentItem) =>  total = total + currentItem.rbserved , 0 ));
  
                      if(RechargeDatas > 0){
                          setRechargeWallets(RechargeDatas)
                      } else {
                          setRechargeWallets(0)
                      }
      
                    }
  
              } else {
                  setRechargeWallets(0)
              }
          
              ///////////////// ACHAT ///////////////////
              const BuyUserDatafiltered = BuyData.filter(obj => {
                  return obj.userId === User.ID.toString();
                });
  
                if(BuyUserDatafiltered.length > 0){
                  
                  const BuyDatafiltered = BuyUserDatafiltered.filter(obj => {return obj.status === "successful"});
      
                  const BuyDatas = (BuyDatafiltered.reduce((total, currentItem) =>  total = total + currentItem.montant , 0 ));
  
                  if(BuyDatas > 0){
                      setBuyingWallets(BuyDatas)
                  } else {
                      setBuyingWallets(0)
                  }
  
              } else {
                  setBuyingWallets(0)
              }
          
              ///////////////// TRANSFERT ///////////////////
              const TransUserDatafiltered = TransData.filter(obj => {
                  return obj.userId === User.ID.toString();
                });
  
              if(TransUserDatafiltered.length > 0){
  
                  const TransDatafiltered = TransUserDatafiltered.filter(obj => {return obj.status === "successful";});
      
                  const TransDatas = (TransDatafiltered.reduce((total, currentItem) =>  total = total + currentItem.montant , 0 ));
  
                  if(TransDatas > 0){
                      setTransWallets(TransDatas)
                  } else {
                      setTransWallets(0)
                  }
  
              } else {
                  setTransWallets(0)
              }
          
              ///////////////// PREMIUM ///////////////////
              if(AllPremiums.length > 0){
                  const PremiumUserDatafiltered = AllPremiums.filter(obj => {
                      return obj.userId === User.ID.toString();
                    });
      
                  if(PremiumUserDatafiltered.length > 0){
          
                      const PremiumsDatas = (PremiumUserDatafiltered.reduce((total, currentItem) =>  total = total + currentItem.premiumPrice , 0 ));
      
                      if(PremiumsDatas > 0){
                          setPremiumsWallets(PremiumsDatas)
                      } else {
                          setPremiumsWallets(0)
                      }
      
                  }
              } else {
                  setPremiumsWallets(0)
              }
  
              const Total = RechargeWallets - (BuyingWallets + TransWallets + PremiumsWallets)
          
              setWallets(Total)
  
          
              ///////////////// TOTAL ///////////////////
              if(RechargeWallets === 0 && BuyingWallets === 0 && TransWallets === 0 && PremiumsWallets === 0){ setWallets(0) }
  
              if(RechargeWallets > 1 && BuyingWallets === 0 && TransWallets === 0 && PremiumsWallets === 0){
  
                  const Total = RechargeWallets
          
                  setWallets(Total)
  
              }
  
              if(RechargeWallets > 1 && BuyingWallets > 1 && TransWallets === 0 && PremiumsWallets === 0){
  
                  const Total = RechargeWallets - BuyingWallets
          
                  setWallets(Total)
          
              }
  
              if(RechargeWallets > 1 && BuyingWallets === 0 && TransWallets > 1 && PremiumsWallets === 0){
  
                  const Total = RechargeWallets - TransWallets
          
                  setWallets(Total)
          
              }
              
              if(RechargeWallets > 1 && BuyingWallets > 1 && TransWallets > 1 && PremiumsWallets === 0){
  
                  const Total = RechargeWallets - (BuyingWallets + TransWallets)
          
                  setWallets(Total)
  
              }
              
              if(RechargeWallets > 1 && BuyingWallets > 1 && TransWallets > 1 && PremiumsWallets > 1){
  
                  const Total = RechargeWallets - (BuyingWallets + TransWallets + PremiumsWallets)
          
                  setWallets(Total)
  
              }
              
              if(RechargeWallets > 1 && BuyingWallets === 0 && TransWallets === 0 && PremiumsWallets > 1){
  
                  const Total = RechargeWallets - PremiumsWallets
          
                  setWallets(Total)
  
              }
              
              if(RechargeWallets > 1 && BuyingWallets === 0 && TransWallets > 1 && PremiumsWallets > 1){
  
                  const Total = RechargeWallets - (TransWallets + PremiumsWallets)
          
                  setWallets(Total)
  
              }
              
              if(RechargeWallets > 1 && BuyingWallets > 1 && TransWallets === 0 && PremiumsWallets > 1){
  
                  const Total = RechargeWallets - (BuyingWallets +  PremiumsWallets)
          
                  setWallets(Total)
  
              }
            }
        }
  
    },[secondsRemaining2, RechargeData, BuyData, User, BuyingWallets, RechargeWallets, Wallets, TransData, TransWallets, AllPremiums, PremiumsWallets])


    const [ProUserFilted, setProUserFilted] = useState([])

    useEffect(()=>{

        const fetchData = async () => {
            try {
    
            const { data } = await axios.get('/api/nkabpro')
    
            if(data.length > 0){
    
                const proUserFilted = data.filter((obj)=>{
                    return obj.userId === userID
                })

                if(proUserFilted.length === 1){
                    setProUserFilted(proUserFilted[0])

                    const ProPrice = Wallets

                    if(ProPrice > 0 && ProUserFilted.proPrice){
                        if(ProPrice !== Number(ProUserFilted.proPrice)){

                            try {
                                
                                axios.post(`/api/update-nkabpro`,
                                {
                                    ID : ProUserFilted.ID,
                                    proPrice: ProPrice,
                                    createdAt: new Date(),
                                    updatedAt: new Date(),
                                },
                                )
                        
                            } catch (error) { }
                        }
                    }
                }
    
                if(proUserFilted.length === 0){

                    const ProPhone = userPhone
                    const ProEmail = userEmail
                    const ProPrice = Wallets
                
                    if(userID && ProEmail && ProPhone && ProPrice > 0){
                
                        axios.post('/api/create-nkabpro',
                            {
                                userId:         userID,
                                proEmail:       ProEmail,
                                proPhone:       ProPhone,
                                proPrice:       ProPrice,
                                createdAt:      new Date(),
                                updatedAt:      new Date(),
                            },
                          )
                          .then((res)=>{
                            if(res.data.msg === 'success'){
                                
                            }
                          })
                
                    }
                }
            } else if(data.length === 0){
    
                const proUserFilted = data.filter((obj)=>{
                    return obj.userId === userID
                })

                if(proUserFilted.length === 0){

                    const ProPhone = userPhone
                    const ProEmail = userEmail
                    const ProPrice = Wallets
                
                    if(userID && ProEmail && ProPhone && ProPrice > 0){
                
                        axios.post('/api/create-nkabpro',
                            {
                                userId:         userID,
                                proEmail:       ProEmail,
                                proPhone:       ProPhone,
                                proPrice:       ProPrice,
                                createdAt:      new Date(),
                                updatedAt:      new Date(),
                            },
                          )
                          .then((res)=>{
                            if(res.data.msg === 'success'){
                                
                            }
                          })
                
                    }
                }
            }
    
            } catch (error) { }
        }
        fetchData()
    
        },[userID, secondsRemaining2, Wallets, userEmail, userPhone])
  
    useEffect(()=>{
          
        if(PCodes.length > 0 && userID){
      
          ///////////////// RECHARGE ///////////////////
          const PCodesDatafiltered = PCodes.filter(obj => {return obj.userId === userID;});

          if(PCodesDatafiltered.length > 0){
            setMyCodePin(PCodesDatafiltered[0].codep)
            setMyCodePinID(PCodesDatafiltered[0].ID)
          }
        }

  },[PCodes, userID])


    useEffect(()=>{

        if((Number(Montant)) && Number(exchange.cfa) && _options){

            if(Number(exchange.cfa) && _options === "ACHAT"){ 
            
                if((Number(exchange.cfa) < buyMin)){ setDangerControler("controler_danger") }

                if((Number(exchange.cfa) > Wallets)){ setDangerControler("controler_danger") }

                if((Number(exchange.cfa) >= buyMin) && (Number(exchange.cfa) < Wallets)){ setDangerControler("controler_success") }
            }

            if(Number(exchange.cfa) && _options === "RECHARGE"){ 
            
                if((Number(exchange.cfa) < chargingMin)){ setDangerControler("controler_danger") }
        
                if((Number(exchange.cfa) >= chargingMin)){ setDangerControler("controler_success") }
            }
    
            if(Number(exchange.cfa) && _options === "TRANSFERT"){ 
            
                if((Number(exchange.cfa) < Wallets)){ setDangerControler("controler_success") }
        
                if((Number(exchange.cfa) > Wallets)){ setDangerControler("controler_danger") }
            }
    
            // if(Number(exchange.cfa) && _options === "RECHARGE"){ setRECHARGEObserved(Number(Wallets)+Number(exchange.cfa)) }
    
            // if(Number(exchange.cfa) && (_options === "ACHAT" || _options === "TRANSFERT")){ setObserved(Number(Wallets)-Number(exchange.cfa)) }
        }

        // if(!Number(exchange.cfa)){
        //     setObserved(Number(Wallets))
        //     setRECHARGEObserved(Number(Wallets))
        // }

    },[Montant, _options, Wallets, exchange.cfa, buyMin, chargingMin])

    function ChoixOption5(){
        localStorage.setItem("_options", "NEWCODE")
        setPassCode(true)
        setListing(false)
        setProfil(false)
        setSeeDoc(false)

        if(LineLink){
          window.location.replace("https://nkab-exchange.net/nkab-pro");
        } else {
           window.location.replace("http://localhost:3000/nkab-pro");
        }
    }

    function logoutHandler(){

        localStorage.clear();

        localStorage.setItem("isRActive",false)

        if(_ProWallet === "true"){
          localStorage.setItem("_ProWallet", true)
          localStorage.setItem("userStatus", "Logout")

          if(LineLink){
            window.location.replace("https://nkab-exchange.net/nkab-pro");
          } else {
             window.location.replace("http://localhost:3000/nkab-pro");
          }
        }

        if(_ProWallet === "false"){
          localStorage.setItem("_ProWallet", false)
          localStorage.setItem("userStatus", "Logout")
        }
        if(transActivated === "true"){
          localStorage.setItem("transActivated", true)
        }
        if(transActivated === "false"){
          localStorage.setItem("transActivated", false)
        }

        if(LineLink){
          window.location.replace("https://nkab-exchange.net/nkab-pro");
        } else {
           window.location.replace("http://localhost:3000/nkab-pro");
        }

    }

    function ClickNewCodeHandler(){
        handleClose();
        localStorage.setItem('NewCode', 'ok')
    }

    const NewCodeHandler = () => {
  
        setLoader(true)
        setStatus1(STATUS.STARTED)
  
        if(userID && userEmail && userLastName && userName && passwordInput){
            axios.post('/api/v1/p-code-pin/generate',
                {
                  userId : userID,
                  fullName : `${userLastName} ${userName}`,
                  codep: passwordInput,
                  email : userEmail.toLowerCase(),
                  createdAt: new Date(),
                  updatedAt: new Date(),
                },
            ).then((res)=>{
              if(res.data.msg === "success"){
                localStorage.removeItem("NewCode")
                localStorage.removeItem("UpdateCode")

                if(LineLink){
                  window.location.replace("https://nkab-exchange.net/nkab-pro");
                } else {
                   window.location.replace("http://localhost:3000/nkab-pro");
                }
              }
            })
        }
  
    }
    const UpdateCodeHandler = async () => {

        setLoader(true)

        // if(passwordInput !== "" && passwordInput.length > 3 && MyCodePinID > 0){
            if(passwordInput !== "" && passwordInput.length > 3){

            axios.post(`/api/v1/p-newcp/newcp`,
                {
                  ID: Number(MyCodePinID),
                  codep: passwordInput
                },
              )
              .then((res) => {
                  if (res.data.msg === "success") {
                    localStorage.removeItem("NewCode")
                    localStorage.removeItem("UpdateCode")

                    if(LineLink){
                      window.location.replace("https://nkab-exchange.net/nkab-pro");
                    } else {
                       window.location.replace("http://localhost:3000/nkab-pro");
                    }
                  }
                }
              )

        }
    }

    useEffect(()=>{

        const fetchDada12 = async () => {

            if(secondsRemaining2 === 0 || secondsRemaining2 === 5 || secondsRemaining2 === 10 || secondsRemaining2 === 15 || secondsRemaining2 === 20 || secondsRemaining2 === 25 || secondsRemaining2 === 30){
                try {
    
                    if(nkab_trans_reference !== ""){
    
                        var requestOptions = {
                            method: 'GET',
                            redirect: 'follow'
                        };
            
                        axios.post(`/api/transfers-step2`, {nkab_trans_id, requestOptions, MerchantId, MerchantIdKey})
                        .then(result => {
    
                            const MyStatus = result.data.status

                            if(MyStatus === 401){
                                localStorage.setItem('nkabWarning', "Quelque chose ne va pas!")
                            }
    
                    
                        if(_options === "TRANSFERT") {
    
                            if(MyStatus === "successful"){

                                localStorage.setItem('nkabMarkTitle', "successful")
    
                                setTransSuccess(MyStatus)
                            
                                if(nkab_trans_reference !== ""){
                
                                    axios.post('/api/v1/p-trans/create',
                                        {
                                            operationUId : nkab_trans_reference,
                                            userId : userID,
                                            tr_Id: nkab_trans_id,
                                            options : _options,
                                            changeName : localStorage.getItem("changeName"),
                                            changeImage : localStorage.getItem("changeImage"),
                                            montant : Number(localStorage.getItem("amount")),
                                            email : localStorage.getItem("email"),
                                            fullName : `${userLastName} ${userName}`,
                                            phone : localStorage.getItem("phone"),
                                            status: "successful",
                                            createdAt: new Date(),
                                            updatedAt: new Date(),
                                        },
                                    ).then((res)=>{ 
                                        if(res){
                                            setMySuccess("ok")
                                            axios.post('/api/v1/notification/create-notif',
                                                {
                                                    notifierId : nkab_trans_reference,
                                                    notifyImageLink : localStorage.getItem("changeName"),
                                                    notifyServicesId : nkab_trans_reference,
                                                    notifyOperatorAction : _options,
                                                    notifySenderName : "premium",
                                                    notifySenderPhone : localStorage.getItem("phone"),
                                                    notifySenderEmail : localStorage.getItem("email"),
                                                    createdAt: new Date(),
                                                    updatedAt: new Date(),
                                                },
                                            ).then((res)=>{
                                                if(res){
                                                    axios.post('/api/v1/all-p-op/create', {
                                                        operationUId : `ref_${nkab_trans_reference}`,
                                                        userId : userID,
                                                        tr_Id: nkab_trans_id,
                                                        options: _options,
                                                        moneyName : localStorage.getItem("changeName"),
                                                        moneyImage : localStorage.getItem("changeImage"),
                                                        montant :  Number(localStorage.getItem("amount")),
                                                        converted : 0,
                                                        dollar: 0,
                                                        fullName : `${userLastName} ${userName}`,
                                                        phone : Phone,
                                                        status: "successful",
                                                        taux : 0,
                                                        netAddress : "No net Address",
                                                        adress : "no Adress",
                                                        email : userEmail.toLowerCase(),
                                                        numcompte : "null",
                                                        rdv : "null",
                                                        times : "null",
                                                        createdAt: new Date(),
                                                        updatedAt: new Date(),
                                                    },
                                                )}
                                            })
                                        }
                                        
                                    })
                                }
                            }else if(MyStatus === "failed"){

                                localStorage.setItem('nkabMarkTitle', "failed")
                
                                setTransFailed(MyStatus)
                            
                                if(nkab_trans_reference !== ""){
                                    axios.post('/api/v1/p-trans/create',
                                        {
                                            operationUId : nkab_trans_reference,
                                            userId : userID,
                                            tr_Id: nkab_trans_id,
                                            options : _options,
                                            changeName : localStorage.getItem("changeName"),
                                            changeImage : localStorage.getItem("changeImage"),
                                            montant : Number(localStorage.getItem("amount")),
                                            email : localStorage.getItem("email"),
                                            fullName : `${userLastName} ${userName}`,
                                            phone : localStorage.getItem("phone"),
                                            status: "failed",
                                            createdAt: new Date(),
                                            updatedAt: new Date(),
                                        },
                                    ).then((res)=>{ 
                                        if(res){
                                            setMySuccess("ok")
                                            axios.post('/api/v1/notification/create-notif',
                                                {
                                                    notifierId : nkab_trans_reference,
                                                    notifyImageLink : localStorage.getItem("changeName"),
                                                    notifyServicesId : nkab_trans_reference,
                                                    notifyOperatorAction : _options,
                                                    notifySenderName : "premium",
                                                    notifySenderPhone : localStorage.getItem("phone"),
                                                    notifySenderEmail : localStorage.getItem("email"),
                                                    createdAt: new Date(),
                                                    updatedAt: new Date(),
                                                },
                                            ).then((res)=>{
                                                if(res){
                                                    axios.post('/api/v1/all-p-op/create', {
                                                        operationUId : `ref_${nkab_trans_reference}`,
                                                        userId : userID,
                                                        tr_Id: nkab_trans_id,
                                                        options : _options,
                                                        moneyName : localStorage.getItem("changeName"),
                                                        moneyImage : localStorage.getItem("changeImage"),
                                                        montant :  Number(localStorage.getItem("amount")),
                                                        converted : 0,
                                                        dollar: 0,
                                                        fullName : `${userLastName} ${userName}`,
                                                        phone : Phone,
                                                        status: "failed",
                                                        taux : 0,
                                                        netAddress : "No net Address",
                                                        adress : "no Adress",
                                                        email : userEmail.toLowerCase(),
                                                        numcompte : "null",
                                                        rdv : "null",
                                                        times : "null",
                                                        createdAt: new Date(),
                                                        updatedAt: new Date(),
                                                    },
                                                )}
                                            })
                                        }
                                        
                                    })
                                }
                            }
                        }
                            })
                        }
                } catch (error) {}
            }
        }
        fetchDada12()

    },[secondsRemaining2, nkab_trans_id, MerchantId, MerchantIdKey])


    useEffect(()=>{

        const fetchDada13 = async () => {

            if(secondsRemaining2 === 0 || secondsRemaining2 === 5 || secondsRemaining2 === 10 || secondsRemaining2 === 15 || secondsRemaining2 === 20 || secondsRemaining2 === 25 || secondsRemaining2 === 30 || secondsRemaining2 === 35){
                if(nkab_rge_reference !== ""){

                    try {
                        if(localStorage.getItem("hub2id") && localStorage.getItem("hub2token")){

                            const hub2id = localStorage.getItem("hub2id")
                            const hub2token = localStorage.getItem("hub2token")

                                var requestOptions = {
                                    method: 'GET',
                                    redirect: 'follow'
                                };
                
                                axios.post(`/api/payment-step3`, {requestOptions, MerchantId, MerchantIdKey, hub2id, hub2token})
                                .then(result => {

                                    if(result.data.payments.length > 0){

                                        if(result.data.payments[0].status === 401){
                                            localStorage.setItem('nkabWarning', "Quelque chose ne va pas!")
                                        }

                                        localStorage.setItem("LastStatus", result.data.payments[0].status) 
                        
                                        if(_options === "RECHARGE") {
                                
                                            if(LastStatus === "successful"){

                                                localStorage.setItem('nkabMarkTitle', "successful")
                                
                                                setPaymentSuccess(LastStatus)
                                            
                                                if(nkab_rge_reference !== ""){
                                
                                                    axios.post('/api/v1/p-recharge/create',
                                                        {
                                                            operationUId : nkab_rge_reference,
                                                            userId : userID,
                                                            tr_Id: nkab_rge_id,
                                                            options : _options,
                                                            banqueName : localStorage.getItem("banqueName"),
                                                            banqueImage : localStorage.getItem("banqueImage"),
                                                            fullName : `${userLastName} ${userName}`,
                                                            email : userEmail.toLowerCase(),
                                                            phone : phone,
                                                            rbserved : amount,
                                                            dollar: 0,
                                                            numcompte : "Pas de Compte",
                                                            rdv : "0",
                                                            status : "successful",
                                                            times : "0",
                                                            createdAt: new Date(),
                                                            updatedAt: new Date(),
                                                        },
                                                    ).then((res)=>{ 
                                                        if(res){
                                                            setMySuccess("ok")
                                                            axios.post('/api/v1/notification/create-notif',
                                                                {
                                                                    notifierId : nkab_rge_reference,
                                                                    notifyImageLink : localStorage.getItem("banqueName"),
                                                                    notifyServicesId : nkab_rge_reference,
                                                                    notifyOperatorAction : _options,
                                                                    notifySenderName : "premium",
                                                                    notifySenderPhone : phone,
                                                                    notifySenderEmail : userEmail.toLowerCase(),
                                                                    createdAt: new Date(),
                                                                    updatedAt: new Date(),
                                                                },
                                                            ).then((res)=>{
                                                                if(res){
                                                                    axios.post('/api/v1/all-p-op/create', {
                                                                        operationUId : `ref_${nkab_rge_reference}`,
                                                                        userId : userID,
                                                                        tr_Id: nkab_rge_id,
                                                                        options : _options,
                                                                        moneyName : localStorage.getItem("banqueName"),
                                                                        moneyImage : localStorage.getItem("banqueImage"),
                                                                        montant :  amount,
                                                                        converted : 0,
                                                                        dollar: 0,
                                                                        fullName : `${userLastName} ${userName}`,
                                                                        phone : phone,
                                                                        status: "successful",
                                                                        taux : 0,
                                                                        netAddress : "No Address",
                                                                        adress : "No Adress",
                                                                        email : userEmail.toLowerCase(),
                                                                        numcompte : "Pas de Compte",
                                                                        rdv : "0",
                                                                        times : "0",
                                                                        createdAt: new Date(),
                                                                        updatedAt: new Date(),
                                                                    },
                                                                )}
                                                            })
                                                        }
                                                        
                                                    })
                                                }
                                            }else if(LastStatus === "failed"){

                                                localStorage.setItem('nkabMarkTitle', "failed")
                                
                                                setPaymentFailed(LastStatus)
                                            
                                                if(nkab_rge_reference !== ""){
                                
                                                    axios.post('/api/v1/p-recharge/create',
                                                        {
                                                            operationUId : nkab_rge_reference,
                                                            userId : userID,
                                                            tr_Id: nkab_rge_id,
                                                            options : _options,
                                                            banqueName : localStorage.getItem("banqueName"),
                                                            banqueImage : localStorage.getItem("banqueImage"),
                                                            fullName : `${userLastName} ${userName}`,
                                                            email : userEmail.toLowerCase(),
                                                            phone : phone,
                                                            rbserved : amount,
                                                            dollar: 0,
                                                            numcompte : "Pas de Compte",
                                                            rdv : "0",
                                                            status : "failed",
                                                            times : "0",
                                                            createdAt: new Date(),
                                                            updatedAt: new Date(),
                                                        },
                                                    ).then((res)=>{ 
                                                        if(res){
                                                            setMySuccess("ok")
                                                            axios.post('/api/v1/notification/create-notif',
                                                                {
                                                                    notifierId : nkab_rge_reference,
                                                                    notifyImageLink : localStorage.getItem("banqueName"),
                                                                    notifyServicesId : nkab_rge_reference,
                                                                    notifyOperatorAction : _options,
                                                                    notifySenderName : "premium",
                                                                    notifySenderPhone : phone,
                                                                    notifySenderEmail : userEmail.toLowerCase(),
                                                                    createdAt: new Date(),
                                                                    updatedAt: new Date(),
                                                                },
                                                            ).then((res)=>{
                                                                if(res){
                                                                    axios.post('/api/v1/all-p-op/create', {
                                                                        operationUId : `ref_${nkab_rge_reference}`,
                                                                        userId : userID,
                                                                        tr_Id: nkab_rge_id,
                                                                        options : _options,
                                                                        moneyName : localStorage.getItem("banqueName"),
                                                                        moneyImage : localStorage.getItem("banqueImage"),
                                                                        montant :  amount,
                                                                        converted : 0,
                                                                        dollar: 0,
                                                                        fullName : `${userLastName} ${userName}`,
                                                                        phone : phone,
                                                                        status: "failed",
                                                                        taux : 0,
                                                                        netAddress : "No Address",
                                                                        adress : "No Adress",
                                                                        email : userEmail.toLowerCase(),
                                                                        numcompte : "Pas de Compte",
                                                                        rdv : "0",
                                                                        times : "0",
                                                                        createdAt: new Date(),
                                                                        updatedAt: new Date(),
                                                                    },
                                                                )}
                                                            })
                                                        }
                                                        
                                                    })
                                                }
                                            }
                                        }
                                    }
                                })

                        }
                    } catch (error) {}

                }
            }
        }
        fetchDada13()

    },[secondsRemaining2, nkab_rge_reference, LastStatus, MerchantId, MerchantIdKey])

    useEffect(()=>{
    if(_options === "RECHARGE"){
        localStorage.removeItem("moneyName")
    }
    if(_options === "TRANSFERT"){
        localStorage.removeItem("banqueName")
    }
    },[_options])

    var [BuyItemAlert, setBuyItemAlert] = useState("")
    var [TransItemAlert, setTransItemAlert] = useState("")

    useEffect(()=>{

        if(_options === "ACHAT" && (Number(Montant) > 1) && !moneyImage){
            setBuyItemAlert("Vous devez choisir un opérateur et ressaisissez le montant à nouveau !")
            setTransItemAlert("")
        }

        if(_options === "ACHAT" && (Number(Montant) === 0) && moneyImage !== ""){
            setBuyItemAlert("")
            setTransItemAlert("")
        }

    },[_options, Montant, moneyImage, changeImage, Phone, banqueName])

  const NkabPackPrice1 = 10
  const NkabPackPrice2 = 60
  const NkabPackPrice3 = 120

  const [premium, setPremium] = useState(false)
  const [NkabPack1, setNkabPack1] = useState(false)
  const [NkabPack2, setNkabPack2] = useState(false)
  const [NkabPack3, setNkabPack3] = useState(false)
  const [NkabSpinner,  setNkabSpinner] = useState(false)
  const [PremiumStatus,  setPremiumStatus] = useState("")
  const [OpListe,  setOpListe] = useState(false)
  const [Msg,  setMsg] = useState("")
  const [NkabPackPrice,  setNkabPackPrice] = useState([])

  const radioHandler1 = (e) => {
    const {value} = e.target
    setPremiumStatus(value)
  }

  const radioHandler2 = (e) => {
    const {value} = e.target
    setPremiumStatus(value)
  }

  const radioHandler3 = (e) => {
    const {value} = e.target
    setPremiumStatus(value)
  }

  function premiumPack(){
    setPremium(!premium)
    setOpListe(OpListe)
  }

  function premiumPack2(){

    if(LineLink){
    window.location.replace("https://nkab-exchange.net/nkab-pro");
    } else {
    window.location.replace("http://localhost:3000/nkab-pro");
    }

  }

  function finalNkabPackBack(){
    setNkabPack1(false)
    setNkabPack2(false)
    setNkabPack3(false)
    setNkabSpinner(false)
    setPremium(true)
  }

    const date = new Date();

    const NkabPackLastMinutes = `${date.getHours()}:${date.getMinutes()}`

    const NkabPackFirstDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`

    const NkabPackLastDate = `${date.getDate()}/${date.getMonth() + 2}/${date.getFullYear()}`

  function finalNkabPack(){

    setNkabSpinner(true)
    setNkabPack1(false)
    setNkabPack2(false)
    setNkabPack3(false)

    if(PremiumStatus && userID && NkabPackPrice && NkabPackFirstDate && NkabPackLastDate && NkabPackLastMinutes){

        axios.post('/api/create-premium-pack/subscribe',
            {
                userId:             userID,
                premiumStatus:      PremiumStatus,
                premiumPrice:       NkabPackPrice,
                premiumFirstDate:   NkabPackFirstDate,
                premiumLastDate:    NkabPackLastDate,
                premiumLastMinutes: NkabPackLastMinutes,
                premiumActive:      true,
                createdAt:          new Date(),
                updatedAt:          new Date(),
            },
          )
          .then((res)=>{
            if(res.data.msg === 'success'){
                setMsg("Votre abonnement effectué avec succès!")
            }
          })

    }

  }

  useEffect(()=>{
      if(NkabPackFirstDate === PremiumsData.premiumLastDate){

        const fetchData = async () => {
          try {
            
            axios.post(`/api/update-premium-pack/subscribe`,
              {
                ID : PremiumsData.ID,
                premiumActive: false,
                createdAt: new Date(),
                updatedAt: new Date(),
              },
            )
            .then(
              (response) => {
                if (response.data.msg === 'success') {

                  if(LineLink){
                  window.location.replace("https://nkab-exchange.net/nkab-pro");
                  } else {
                  window.location.replace("http://localhost:3000/nkab-pro");
                  }
  
                }
              },
              (error) => {
                console.log(error)
              },
            )
    
          } catch (error) { }
        }
        fetchData()

      }
  },[NkabPackFirstDate, PremiumsData, LineLink])

  function premiumDesable(){

    try {
            
        axios.post(`/api/update-premium-pack/subscribe`,
          {
            ID : PremiumsData.ID,
            premiumActive: false,
            createdAt: new Date(),
            updatedAt: new Date(),
          },
        )
        .then(
          (response) => {
            if (response.data.msg === 'success') {

              if(LineLink){
              window.location.replace("https://nkab-exchange.net/nkab-pro");
              } else {
              window.location.replace("http://localhost:3000/nkab-pro");
              }

            }
          },
          (error) => {
            console.log(error)
          },
        )

      } catch (error) { }
  }

//   const [imageUrl, setImageUrl] = useState(null);
//   const [image, setImage] = useState(null);
//   const [imagePath, setImagePath] = useState('');


//   const handleImageUrlChange = (e) => {

//         const file = e.target.files[0];
//         setImageUrl(e.target.files[0])

//         const reader = new FileReader();

//         reader.onloadend = () => {
//             setImage(reader.result);
//         };
    
//         if (file) {
//             reader.readAsDataURL(file);
//         }

//         if (file) {
//             const reader = new FileReader();
//             reader.onload = function (event) {
//                 // Récupérer le chemin local de l'image
//                 const localPath = URL.createObjectURL(file);
//                 setImagePath(localPath);
//             };
//             reader.readAsDataURL(file);
//         }
//     };

    // const handleSaveImage = async () => {

    //     const formData = new FormData();
    //     formData.append('image', imageUrl);

    //     console.log(formData)

    //     try {
    //         const response = await axios.post('/api/v1/bordereau-img', formData, {
    //             headers: {
    //             'Content-Type': 'multipart/form-data',
    //             },
    //         });

    //       console.log(response)

    //     //   if (response.status === 200) {
    //     //     alert('Image téléchargée avec succès.');
    //     //     setImage(null); // Réinitialiser l'état de l'image après le téléchargement réussi si nécessaire
    //     //   } else {
    //     //     alert('Une erreur est survenue lors du téléchargement de l\'image.');
    //     //   }

    //     } catch (error) {
    //         console.error('Erreur lors du téléchargement de l\'image:', error);
    //         // alert('Une erreur est survenue lors du téléchargement de l\'image.');
    //     }
    // };



    // const handleSaveImage = async (e) => {
    //     e.preventDefault()
    //     try {

    //         if(imageUrl){

    //             var formdata = new FormData()
    //             // console.log(file)
    //             formdata.append('file', imageUrl)

    //             console.log(imageUrl)
        
    //             const res = await axios.post('/api/v1/bordereau-img', formdata)
    //             console.log(res)

    //         }
    //     } catch (err) {}
    //   }


    // const [CLimage, setCLImage] = useState('');
    // const [loading, setLoading] = useState(false);

    // const CLOUD_NAME = 'dlo6aumre'
    // const CLOUD_API_KEY = 535486837517372
    // const CLOUD_API_SECRET = 'dIP1yh7k0t0DgJemmRpbwofGcco'

    // const uploadImage = async (e) => {
    //     setLoading(true);
    //     const file = e.target.files[0];
    //     const formData = new FormData();
    //     formData.append('file', file);
    //     formData.append('upload_preset', 'dIP1yh7k0t0DgJemmRpbwofGcco');
    //     const response = await fetch('https://api.cloudinary.com/v1_1/dlo6aumre/image/upload', {
    //     method: 'POST',
    //     body: formData
    //     });
    //     const data = await response.json();
    //     setCLImage(data.secure_url);
    //     setLoading(false);
    // };

    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [res, setRes] = useState({});
    const handleSelectFile = (e) => setFile(e.target.files[0]);

    const handleUpload = async () => {
        try {
            setLoading(true);
            const data = new FormData();
            data.append("my_file", file);
            const res = await axios.post('/api/v1/bordereau-img', data);
            setRes(res.data);
            console.log(res.data)
        } catch (error) {
            alert(error.message);
        } finally {
            setLoading(false);
        }
    };




    const [insistLogout, setInsistLogout ] = useState(false)

    useEffect(() => {
      if (insistLogout === true) {
  
        if(LineLink){
          window.location.replace("https://nkab-exchange.net/nkab-pro");
        } else {
           window.location.replace("http://localhost:3000/nkab-pro");
        }
      }
  
    }, [insistLogout])

    function handleLogout(){
  
      localStorage.clear();
  
      if(_ProWallet === "true"){
        localStorage.setItem("isRActive",false)
        localStorage.setItem("_ProWallet", true)
        localStorage.setItem("userStatus", "Logout")
  
        if(LineLink){
          window.location.replace("https://nkab-exchange.net/login");
        } else {
           window.location.replace("http://localhost:3000/login");
        }
      }
      if(_ProWallet === "false"){
        localStorage.setItem("isRActive",false)
        localStorage.setItem("_ProWallet", false)
        localStorage.setItem("userStatus", "Logout")
      }
      
      localStorage.setItem("isRActive",false)
  
      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
         window.location.replace("http://localhost:3000/login");
      }
    }

    function handleLoggIn(){
      setInsistLogout(true)
      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
         window.location.replace("http://localhost:3000/login");
      }
    }


    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
  
    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };

    var [themeMode, setThemeMode] = useState()
    
    let ThemeMode = localStorage.getItem("ThemeMode")

    const homeHandler = () =>{
      setAnchorElNav(null);
      if(LineLink){
        window.location.replace("https://nkab-exchange.net/nkab-pro");
      } else {
         window.location.replace("http://localhost:3000/nkab-pro");
      }
    }

    function backHomeHanlder(){
  
      if(LineLink){
        window.location.replace("https://nkab-exchange.net/nkab-pro");
      } else {
         window.location.replace("http://localhost:3000/nkab-pro");
      }
    }
    
    useEffect(()=>{
      
      if(secondsRemaining2 > 0){
        setThemeMode(ThemeMode)
      }
    
    },[ThemeMode, secondsRemaining2])

    function lightMode(){
      setAnchorElNav(null);
      localStorage.setItem("ThemeMode", true)
    }
  
    function nightMode(){
      setAnchorElNav(null);
      localStorage.setItem("ThemeMode", false)
    }

    const whatsappHandler = () => {
      setAnchorElNav(null);
      window.location.replace("https://chat.whatsapp.com/B9DsCr6DeaPLeN4CWbk0R1");
    }
  
    const telegramHandler = () => {
      setAnchorElNav(null);
      window.location.replace("https://t.me/+wTl8DCgNOwI5YWI0");
    }

    const [ ProOptions, setProOptions] = useState(false)

    function MoreOptions(){
        setProOptions(!ProOptions)
    }

    const [ RInterface, setRInterface] = useState(false)
    const [ BInterface, setBInterface] = useState(false)
    const [ TInterface, setTInterface] = useState(false)
    const [ OpInterface, setOpInterface] = useState(false)

    const activated = "active"

    function RSection(){ 
        setRInterface(!RInterface) 
        setBInterface(false) 
        setTInterface(false)
        localStorage.setItem('RI', 'RInterface')
        localStorage.setItem('BI', '')
        localStorage.setItem('TI', '')
        localStorage.setItem('OpI', '')
    }
    function BSection(){ 
        setRInterface(false) 
        setBInterface(!BInterface) 
        setTInterface(false) 
        localStorage.setItem('RI', '')
        localStorage.setItem('BI', 'BInterface')
        localStorage.setItem('TI', '')
        localStorage.setItem('OpI', '')
    }
    function TSection(){ 
        setRInterface(false) 
        setBInterface(false)
        setTInterface(!TInterface) 
        localStorage.setItem('RI', '')
        localStorage.setItem('BI', '')
        localStorage.setItem('TI', 'TInterface')
        localStorage.setItem('OpI', '')
    }
    function OpSection(){ 
        setRInterface(false) 
        setBInterface(false)
        setTInterface(false) 
        setOpInterface(!OpInterface) 
        localStorage.setItem('RI', '')
        localStorage.setItem('BI', '')
        localStorage.setItem('TI', '')
        localStorage.setItem('OpI', 'OpInterface')
    }

    const OpSelected = "2px solid #fff"

    const [ CodeEmail, setCodeEmail] = useState([])

    const handleEmail = (e) => {
        const { value } = e.target
        setCodeEmail(value)
      }

    const handlePasswordChange =(evnt)=>{
        setPasswordInput(evnt.target.value);
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const handleChangeInput = (e) => {
      const { value } = e.target
      setCodePin(value)
    }

    const [open, setOpen] = useState(false);

    const handleClose = () => {
      setOpen(false);
      localStorage.removeItem("NewCode")
      localStorage.removeItem("UpdateCode")
    };

    const [coinDetails, setCoinDetails] = useState([])
  
    useEffect(()=>{
      
      const fetchDada2 = async () => {
  
        const response = await coinGecko.get('/coins/markets', {
          params: {
            vs_currency: 'usd',
            ids:
              'bitcoin',
          },
        })
        if(response.data.length > 0){
            setCoinDetails(response.data[0])
        }
      }
      fetchDada2()
    },[])

    const [QCircle1, setQCircle1] = useState(false)
    const [QCircle2, setQCircle2] = useState(false)
    const [QCircle3, setQCircle3] = useState(false)
    const [QCircle4, setQCircle4] = useState(false)
    const [QCircle5, setQCircle5] = useState(false)
    const [QCircle6, setQCircle6] = useState(false)

    function Circle1(){
        setQCircle1(!QCircle1)
        setQCircle2(false)
        setQCircle3(false)
        setQCircle4(false)
        setQCircle5(false)
        setQCircle6(false)
    }

    function Circle2(){
        setQCircle1(false)
        setQCircle2(!QCircle2)
        setQCircle3(false)
        setQCircle4(false)
        setQCircle5(false)
        setQCircle6(false)
    }

    function Circle3(){
        setQCircle1(false)
        setQCircle2(false)
        setQCircle3(!QCircle3)
        setQCircle4(false)
        setQCircle5(false)
        setQCircle6(false)
    }

    function Circle4(){
        setQCircle1(false)
        setQCircle2(false)
        setQCircle3(false)
        setQCircle4(!QCircle4)
        setQCircle5(false)
        setQCircle6(false)
    }

    function Circle5(){
        setQCircle1(false)
        setQCircle2(false)
        setQCircle3(false)
        setQCircle4(false)
        setQCircle5(!QCircle5)
        setQCircle6(false)
    }

    function Circle6(){
        setQCircle1(false)
        setQCircle2(false)
        setQCircle3(false)
        setQCircle4(false)
        setQCircle5(false)
        setQCircle6(!QCircle6)
    }

    function CircleNo(){
        setQCircle1(false)
        setQCircle2(false)
        setQCircle3(false)
        setQCircle4(false)
        setQCircle5(false)
        setQCircle6(false)
    }
  
    function seePriceHandler(){
        setSeePrice(true)
    }
    function unSeePriceHandler(){
        setSeePrice(false)
    }


  const [coinsBTC, setCoinsBTC] = useState([])
  const [coinsUSDT, setCoinsUSDT] = useState([])
  const [coinsBNB, setCoinsBNB] = useState([])
  const [coinsTRON, setCoinsTRON] = useState([])
  const [coinsDOGE, setCoinsDOGE] = useState([])
  const [coinsLTC, setCoinsLTC] = useState([])

  useEffect(()=>{
    
    const fetchDada2 = async () => {

      const response = await coinGecko.get('/coins/markets', {
        params: {
          vs_currency: 'usd',
          ids:
            'bitcoin',
        },
      })
      if(response.data.length > 0){
        setCoinsBTC(response.data)
      }
    }
    fetchDada2()
  },[])

  useEffect(()=>{
    
    const fetchDada2 = async () => {

      const response = await coinGecko.get('/coins/markets', {
        params: {
          vs_currency: 'usd',
          ids:
            'tether',
        },
      })
      if(response.data.length > 0){
        setCoinsUSDT(response.data)
      }
    }
    fetchDada2()
  },[])

  useEffect(()=>{
    
    const fetchDada2 = async () => {

      const response = await coinGecko.get('/coins/markets', {
        params: {
          vs_currency: 'usd',
          ids:
            'binancecoin',
        },
      })
      if(response.data.length > 0){
        setCoinsBNB(response.data)
      }
    }
    fetchDada2()
  },[])

  useEffect(()=>{
    
    const fetchDada2 = async () => {

      const response = await coinGecko.get('/coins/markets', {
        params: {
          vs_currency: 'usd',
          ids:
            'tron',
        },
      })
      if(response.data.length > 0){
        setCoinsTRON(response.data)
      }
    }
    fetchDada2()
  },[])

  useEffect(()=>{
    
    const fetchDada2 = async () => {

      const response = await coinGecko.get('/coins/markets', {
        params: {
          vs_currency: 'usd',
          ids:
            'dogecoin',
        },
      })
      if(response.data.length > 0){
        setCoinsDOGE(response.data)
      }
    }
    fetchDada2()
  },[])

  useEffect(()=>{
    
    const fetchDada2 = async () => {

      const response = await coinGecko.get('/coins/markets', {
        params: {
          vs_currency: 'usd',
          ids:
            'litecoin',
        },
      })
      if(response.data.length > 0){
        setCoinsLTC(response.data)
      }
    }
    fetchDada2()
  },[])

  return (
    <div>
        <div>
            <AppBar position="static" className={`${themeMode === "true" ? "nav-t css-1x7skt0" : "nav-l css-1x7skt0"}`}>
                <Container maxWidth="xl" className='css-1qnavfx-MuiPaper-root-MuiAppBar-root css-hip9hq-MuiPaper-root-MuiAppBar-root'>
                    <Toolbar disableGutters>
                        <img src="../../images/logo2.png" className="nkp_logo nkab_logo_mobile mr-2 pointer"  alt="logo nkab" onClick={backHomeHanlder}/>
                        <Typography
                        variant="h6"
                        noWrap
                        onClick={homeHandler}
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            textDecoration: 'none',
                        }}
                        className={`${themeMode === "true" ? "color-t pointer" : "color-l pointer"}`}
                        >
                        NKAB EXCHANGE
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            className={`${themeMode === "true" ? "color-t" : "color-l"}`}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                            display: { xs: 'block', md: 'none' },
                            }}
                        >
                            <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"} ${_options === "RECHARGE"?"nkp_Options":"recharge-text"}`} onClick={RSection}><Typography textAlign="center" style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}><FontAwesomeIcon icon={faPlusCircle} /> Recharger</Typography></MenuItem>
                            <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"} ${_options === "ACHAT"?"nkp_Options":"buy-text"}`} onClick={BSection}><Typography textAlign="center" style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}><FontAwesomeIcon icon={faMoneyBill} /> Acheter</Typography></MenuItem>
                            <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"} ${_options === "TRANSFERT"?"nkp_Options":"transfert-text"}`} onClick={TSection}><Typography textAlign="center" style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}><FontAwesomeIcon icon={faExchangeAlt} /> Transférer</Typography></MenuItem>
                            <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"} ${_options === "NEWCODE"?"nkp_Options":"newcode-text"}`} onClick={ClickNewCodeHandler}><Typography textAlign="center" style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}><FontAwesomeIcon icon={faKey} /> Générer un code</Typography></MenuItem>
                            <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} onClick={whatsappHandler}>
                            <Typography textAlign="center">
                                <span className="icoFacebook" style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}>
                                <img src="../../images/whatsapp-icon.png" alt="Whatsapp" width="25" />Communauté
                                </span>
                            </Typography>
                            </MenuItem>
                            <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`} onClick={telegramHandler}>
                            <Typography textAlign="center">
                                <span className="icoFacebook" style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}>
                                <img src="../../images/telegram-icon.png" alt="Telegram" width="25" />Communauté
                                </span>
                            </Typography>
                            </MenuItem>
                            <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`}>
                            <Typography>
                                {
                                ThemeMode === "true" ?
                                <div className="nav-item pt-2 ml-3" onClick={nightMode}>
                                    <span style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}></span> <LightModeIcon className='pointer' style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} /> <span><strong style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}> Thème</strong></span>
                                </div>:
                                <div className="nav-item pt-2 ml-3" onClick={lightMode}>
                                    <span style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}></span> <BedtimeIcon className='pointer'  style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} /> <span><strong style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}> Thème</strong></span>
                                </div>
                                }
                            </Typography>
                            </MenuItem>
                        </Menu>
                        </Box>
                        <Typography
                        variant="h5"
                        noWrap
                        onClick={homeHandler}
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.2rem',
                            textDecoration: 'none',
                        }}
                        className={`${themeMode === "true" ? "color-t pointer" : "color-l pointer"}`}
                        >
                        NKAB EXCHANGE
                        </Typography >
                        <Box variant="h5" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex'} }}></Box>
                        <Box variant="h5" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex'} }}>
                        <Button onClick={RSection} sx={{ my: 2, color: `${themeMode === "true" ? "#90d9ff" : "#0a146e"}`, display: 'block', fontWeight: 700, letterSpacing: '.1rem' }}><FontAwesomeIcon icon={faPlusCircle} /> <span style={{fontSize:"14px"}}> Recharger</span></Button>
                        <Button onClick={BSection} sx={{ my: 2, color: `${themeMode === "true" ? "#90d9ff" : "#0a146e"}`, display: 'block', fontWeight: 700, letterSpacing: '.1rem' }}><FontAwesomeIcon icon={faMoneyBill} /><span style={{fontSize:"14px"}}> Acheter</span></Button>
                        <Button onClick={TSection} sx={{ my: 2, color: `${themeMode === "true" ? "#90d9ff" : "#0a146e"}`, display: 'block', fontWeight: 700, letterSpacing: '.1rem' }}><FontAwesomeIcon icon={faExchangeAlt} /> <span style={{fontSize:"14px"}}> Transférer</span></Button>
                        <Button onClick={ClickNewCodeHandler} sx={{ my: 2, color: `${themeMode === "true" ? "#90d9ff" : "#0a146e"}`, display: 'block', fontWeight: 700, letterSpacing: '.1rem' }}><FontAwesomeIcon icon={faKey} /> <span style={{fontSize:"14px"}}> Générer un code</span></Button>
                        </Box>
                        <Box variant="h5" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex'} }}>
                            <Button onClick={whatsappHandler} sx={{ my: 2, color: 'white', display: 'block', letterSpacing: '.1rem' }}>
                            <img src="../../images/whatsapp-icon.png" alt="Whatsapp" width="35" />
                            </Button>
                            <Button onClick={telegramHandler} sx={{ my: 2, color: 'white', display: 'block', letterSpacing: '.1rem' }}>
                            <img src="../../images/telegram-icon.png" alt="Telegram" width="35" />
                            </Button>
                            <Button onClick={handleCloseNavMenu} sx={{ my: 2, color: `${themeMode === "true" ? "#90d9ff" : "#0a146e"}`, display: 'block', fontWeight: 700, letterSpacing: '.1rem' }}>
                            {
                                Logged === 'Logged' ?
                                <div className='text-center nkab-deconnect deconnect-bg pointer' onClick={handleLogout}>
                                    <span><strong className='community-title icoFacebook' style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}> Déconnexion </strong></span>
                                </div> :
                                <div className='text-center nkab-deconnect connect-bg pointer' onClick={handleLoggIn}>
                                <span><strong className='community-title icoFacebook' style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}> Connexion </strong></span>
                                </div>
                            }</Button>
                            <Button sx={{ my: 2, color: 'white', display: 'block', fontWeight: 700, letterSpacing: '.1rem' }}>
                            {
                                ThemeMode === "true" ?
                                <div onClick={nightMode}>
                                <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}></span> <LightModeIcon className='pointer' style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}} /> <span><strong style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}> Thème</strong></span>
                                </div>:
                                <div onClick={lightMode}>
                                <span style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}></span> <BedtimeIcon className='pointer'  style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} /> <span><strong style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}> Thème</strong></span>
                                </div>
                            }
                            </Button>
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                {
                                Logged === 'Logged' ?<>
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar sx={{ width: 32, height: 32 }}>
                                        <img src="../../images/nkab-avatar.png" width="30" alt="nkab avatar" />
                                    </Avatar>
                                    </IconButton>
                                </>:null
                                }
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                            >
                            <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`}>
                                <Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center"> 
                                <span className='nkab-premium'>Premium</span> 
                                </Typography>
                            </MenuItem>
                            <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`}>
                                <Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center"> 
                                    <FontAwesomeIcon icon={faUserAlt} /> Profil 
                                </Typography>
                            </MenuItem>
                            <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`}>
                                <Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center" onClick={OpSection}> 
                                    <FontAwesomeIcon icon={faListAlt} /> Historique 
                                </Typography>
                            </MenuItem>
                            <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`}>
                                <Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center"> 
                                    <ReactSwitch className='react-switch-handle' checked={checked} onChange={toogleHandleChange} /> Lite 
                                </Typography>
                            </MenuItem>
                            <MenuItem className={`${themeMode === "true" ? "nav-t" : "nav-l"}`}>
                                <Typography style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} textAlign="center" onClick={logoutHandler}> 
                                    <FontAwesomeIcon icon={faSignOutAlt} /> Déconnexion 
                                </Typography>
                            </MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>

        <div>
            <div className='row pt-5 pb-3 container-t'>
                <div className='col-md-1'></div>
                <div id='beginning' className="change-container2-t col-md-4">
                    <HomeVariations themeMode={themeMode} RInterface = {RInterface} RI = {RI} BInterface = {BInterface} BI = {BI} TInterface = {TInterface} TI = {TI} />
                    {/* {
                        RInterface || BInterface || TInterface || RI === "RInterface" || BI === "BInterface" || TI === "TInterface"  ?
                        <HomeVariations themeMode={themeMode} RInterface = {RInterface} RI = {RI} BInterface = {BInterface} BI = {BI} TInterface = {TInterface} TI = {TI} />:
                        <div className={`${themeMode === "true" ? "text-center wellcome-title wellcome-title-t" : "text-center wellcome-title"}`}>
                            <h1 className='mb-4 animate__animated animate__zoomInDown'><strong className='color-or'>Alimentez</strong> votre E-NKAB et faitent vos achats en toute sécurité ! </h1>
                            <div>
                                <div className='d-flex justify-content-center'>
                                    <h4 className='pt-1' style={{fontSize:"17px", color:`${themeMode === "true" ? "#8ad8ff56" : "#0a146e"}`}}>Votre solde estimé</h4>
                                    <div className='nkp_eye ml-2 pointer'>
                                        {
                                            !SeePrice ?
                                            <img onClick={seePriceHandler} style={{color:"#c7b301"}} width="17" height="17" src="https://img.icons8.com/ios/50/c7b301/visible--v1.png" alt="visible--v1"/>:
                                            <img onClick={unSeePriceHandler} style={{color:"#c7b301"}} width="17" height="17" src="https://img.icons8.com/ios/50/c7b301/closed-eye.png" alt="closed-eye"/>
                                        }
                                    </div>
                                </div>
                                {
                                    !SeePrice ?
                                    <div onClick={seePriceHandler} className='d-flex justify-content-center mt-4'>
                                        <div className='uppercase pointer' style={{fontSize:"50px", color:"#c7b301"}}>********</div>
                                    </div>:
                                    <div onClick={unSeePriceHandler} className='d-flex justify-content-center mt-4'>
                                        <div className='uppercase bolder pointer' style={{fontSize:"25px", color:"#c7b301"}}>{(Wallets*1).toFixed(2)} XOF</div>
                                    </div>
                                }
                            </div>
                        </div>
                    } */}
                    <div className='mb-4 mt-2 nkab-estimated-account'>
                        <div className='d-flex justify-content-center'>
                            <h4 className='pt-1' style={{fontSize:"17px", color:"#8ad8ff56"}}>Votre solde estimé</h4>
                            <div className='nkp_eye ml-2 pointer'>
                                {
                                    !SeePrice ?
                                    <img onClick={seePriceHandler} style={{color:"#c7b301"}} width="17" height="17" src="https://img.icons8.com/ios/50/c7b301/visible--v1.png" alt="visible--v1"/>:
                                    <img onClick={unSeePriceHandler} style={{color:"#c7b301"}} width="17" height="17" src="https://img.icons8.com/ios/50/c7b301/closed-eye.png" alt="closed-eye"/>
                                }
                            </div>
                        </div>
                        {
                            !SeePrice ?
                            <div onClick={seePriceHandler} className='d-flex justify-content-center mt-4'>
                                <div className='uppercase pointer' style={{fontSize:"50px", color:"#c7b301"}}>********</div>
                            </div>:
                            <div onClick={unSeePriceHandler} className='d-flex justify-content-center mt-4'>
                                <div className='uppercase bolder pointer' style={{fontSize:"25px", color:"#c7b301"}}>{(Wallets*1).toFixed(2)} XOF</div>
                            </div>
                        }
                    </div>
                    <div className='d-flex justify-content-center animate__fadeInDown animate__animated'>
                        <Box noValidate sx={{ mt: 1 }}>
                            <Grid container>
                                <div className='d-flex justify-content-around mb-3'>
                                    <div className='nkab-main-btn1 text-center mr-2 ml-2' style={{borderBottom:`${RInterface || RI === "RInterface"?OpSelected:"none"}`, background:`${RInterface || RI === "BInterface" ?"none":"#1a2a41"}`, color:`${RInterface || RI === "BInterface" ?"#c7b301":""}`}} onClick={RSection}><FontAwesomeIcon icon={faPlusCircle} />{' '}Recharge</div>
                                    <div className='nkab-main-btn2 text-center mr-2 ml-2' style={{borderBottom:`${BInterface || BI === "BInterface" ?OpSelected:"none"}`, background:`${BInterface || BI === "BInterface" ?"none":"#1a2a41"}`, color:`${BInterface || BI === "BInterface" ?"#c7b301":""}`}} onClick={BSection}><FontAwesomeIcon icon={faMoneyBill} />{' '}Achat</div>
                                    <div className='nkab-main-btn3 text-center mr-2 ml-2' style={{borderBottom:`${TInterface || TI === "TInterface" ?OpSelected:"none"}`, background:`${TInterface || TI === "BInterface" ?"none":"#1a2a41"}`, color:`${TInterface || TI === "BInterface" ?"#c7b301":""}`}} onClick={TSection}><FontAwesomeIcon icon={faExchangeAlt} />{' '}Transfert</div>
                                </div>
                            {/* {
                            ProOptions || RI === "RInterface" || BI === "BInterface" || TI === "TInterface" ? 
                            <div className='d-flex justify-content-around mt-3 mb-3'>
                                <div className='nkab-main-btn1 text-center mr-2 ml-2' style={{borderBottom:`${RInterface || RI === "RInterface"?OpSelected:"none"}`, background:`${RInterface || RI === "BInterface" ?"none":"#1a2a41"}`, color:`${RInterface || RI === "BInterface" ?"#c7b301":""}`}} onClick={RSection}><FontAwesomeIcon icon={faPlusCircle} />{' '}Recharge</div>
                                <div className='nkab-main-btn2 text-center mr-2 ml-2' style={{borderBottom:`${BInterface || BI === "BInterface" ?OpSelected:"none"}`, background:`${BInterface || BI === "BInterface" ?"none":"#1a2a41"}`, color:`${BInterface || BI === "BInterface" ?"#c7b301":""}`}} onClick={BSection}><FontAwesomeIcon icon={faMoneyBill} />{' '}Achat</div>
                                <div className='nkab-main-btn3 text-center mr-2 ml-2' style={{borderBottom:`${TInterface || TI === "TInterface" ?OpSelected:"none"}`, background:`${TInterface || TI === "BInterface" ?"none":"#1a2a41"}`, color:`${TInterface || TI === "BInterface" ?"#c7b301":""}`}} onClick={TSection}><FontAwesomeIcon icon={faExchangeAlt} />{' '}Transfert</div>
                            </div>:
                            <Button
                                fullWidth
                                variant="contained"
                                className='home-btn'
                                onClick={MoreOptions}
                                sx={{ mt: 3, mb: 2, bgcolor: '#c7b301', fontWeight:'800', fontSize:'15px', color:'#0c1886' }}
                            >
                                Commencer
                            </Button>
                            } */}
                            </Grid>
                        </Box>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div>
                        <div className='nkab-cryptocurrency-home-v d-flex justify-content-center'>
                            {
                                RInterface || RI === "RInterface" ? 
                                <RInterfaceComponent themeMode={themeMode} Wallets = {Wallets} PaymentSuccess={PaymentSuccess} nkab_rge_id = {nkab_rge_id} nkab_rge_reference = {nkab_rge_reference} />:
                                BInterface || BI === "BInterface" ? 
                                <BInterfaceComponent themeMode={themeMode} Wallets = {Wallets} MyCodePin = {MyCodePin} />:
                                TInterface || TI === "TInterface" ? 
                                <TInterfaceComponent themeMode={themeMode} Wallets = {Wallets} MyCodePin = {MyCodePin} nkab_trans_id = {nkab_trans_id} nkab_trans_reference = {nkab_trans_reference} />:
                                OpInterface || OpI === "OpInterface" ?
                                <OpInterfaceComponent themeMode={themeMode} />:
                                <RInterfaceComponent themeMode={themeMode} Wallets = {Wallets} PaymentSuccess={PaymentSuccess} nkab_rge_id = {nkab_rge_id} nkab_rge_reference = {nkab_rge_reference} />
                                // :
                                // <HomeVariations themeMode={themeMode}/>
                            }
                        </div>
                    </div>
                </div>
                <div className='col-md-1'></div>
            </div>
            <div className='d-flex justify-content-center mb-5'>
                <div className='row mt-5 d-flex justify-content-center'>
                    <div className='text-center mb-5' style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}}><h4 className='bolder'>Comment ça marche ?</h4></div>
                    <div className='row justify-content-center'>
                        <div className={`color-white col-md-3 ${themeMode === "true" ? "nkab-cards-t" : "nkab-cards"}`}>
                            <img src="../../images/nkabico/t_ico_recharge.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mb-2 icon-16" />
                            <h4 style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}} className='mt-2'>1. Comment recharger ?</h4>
                            <div style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}}>Cliquez sur bouton <strong>Recharge</strong>, sélectionnez le mode de rechargement disponible, saisissez le montant en fonction du minimum réquis et choisissez le compte de paiement ou associez la carte de paiement.</div>
                        </div>
                        <div className={`color-white col-md-3 ${themeMode === "true" ? "nkab-cards-t" : "nkab-cards"}`}>
                            <img src="../../images/nkabico/t_ico_achat.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mb-2 icon-16" />
                            <h4 style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}} className='mt-2'>2. Comment acheter ?</h4>
                            <div style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}}>Cliquez sur bouton <strong>Achat</strong>, sélectionnez la monnaie disponible, saisissez la quantité ou le montant selon votre bon vouloir et faites le choix du réseau proposé en fonction de la crypto si possible. Afin, renseignez votre adresse pour terminer.</div>
                        </div>
                        <div className={`color-white col-md-3 ${themeMode === "true" ? "nkab-cards-t" : "nkab-cards"}`}>
                            <img src="../../images/nkabico/t_ico_transfert.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mb-2 icon-16" />
                            <h4 style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}} className='mt-2'>3. Comment trensférer de l'argent ?</h4>
                            <div style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}}>Cliquez sur bouton <strong>Transfert</strong>, sélectionnez le de paiement disponible, saisissez le montant et le numéro du bénéficiaire. Prêtez attention au montant final qui sera envoyé!</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center mb-5'>
                <div className='row mt-5 d-flex justify-content-center'>
                    <div className='text-center mb-5' style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}}><h4 className='bolder'>Monnaies populaires</h4></div>
                    <div className='row justify-content-center'>
                        {
                            coinsBTC.length > 0 ?
                            <div className='nkab-cards2 col-md-3'>
                                <span className='ml-2 mr-2'><img src={coinsBTC[0].image} width="30" alt="nkab drapeau" className="dark-theme-icon-disable mb-2 icon-16" /></span>
                                <span className='bolder uppercase' style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}}>{coinsBTC[0].symbol}</span>
                                <span className='ml-2 mr-2' style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}}>${coinsBTC[0].current_price}</span>
                                <span className={coinsBTC[0].price_change_percentage_24h < 0 ? 'mr-2 text-danger' : 'mr-2 text-success'} >
                                    {coinsBTC[0].price_change_percentage_24h < 0 ? <i className="fa fa-sort-down mr-1 text-danger"></i> : <i className="fa fa-sort-up mr-1 text-success"></i>}
                                    {coinsBTC[0].price_change_percentage_24h.toFixed(2)}%
                                </span>
                            </div>:null
                        }
                        {
                            coinsUSDT.length > 0 ?
                            <div className='nkab-cards2 col-md-3'>
                                <span className='ml-2 mr-2'><img src={coinsUSDT[0].image} width="30" alt="nkab drapeau" className="dark-theme-icon-disable mb-2 icon-16" /></span>
                                <span className='bolder uppercase' style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}}>{coinsUSDT[0].symbol}</span>
                                <span className='ml-2 mr-2' style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}}>${coinsUSDT[0].current_price}</span>
                                <span className={coinsUSDT[0].price_change_percentage_24h < 0 ? 'mr-2 text-danger' : 'mr-2 text-success'} >
                                    {coinsUSDT[0].price_change_percentage_24h < 0 ? <i className="fa fa-sort-down mr-1 text-danger"></i> : <i className="fa fa-sort-up mr-1 text-success"></i>}
                                    {coinsUSDT[0].price_change_percentage_24h.toFixed(2)}%
                                </span>
                            </div>:null
                        }
                        {
                            coinsBNB.length > 0 ?
                            <div className='nkab-cards2 col-md-3'>
                                <span className='ml-2 mr-2'><img src={coinsBNB[0].image} width="30" alt="nkab drapeau" className="dark-theme-icon-disable mb-2 icon-16" /></span>
                                <span className='bolder uppercase' style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}}>{coinsBNB[0].symbol}</span>
                                <span className='ml-2 mr-2' style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}}>${coinsBNB[0].current_price}</span>
                                <span className={coinsBNB[0].price_change_percentage_24h < 0 ? 'mr-2 text-danger' : 'mr-2 text-success'} >
                                    {coinsBNB[0].price_change_percentage_24h < 0 ? <i className="fa fa-sort-down mr-1 text-danger"></i> : <i className="fa fa-sort-up mr-1 text-success"></i>}
                                    {coinsBNB[0].price_change_percentage_24h.toFixed(2)}%
                                </span>
                            </div>:null
                        }
                        {
                            coinsTRON.length > 0 ?
                            <div className='nkab-cards2 col-md-3'>
                                <span className='ml-2 mr-2'><img src={coinsTRON[0].image} width="30" alt="nkab drapeau" className="dark-theme-icon-disable mb-2 icon-16" /></span>
                                <span className='bolder uppercase' style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}}>{coinsTRON[0].symbol}</span>
                                <span className='ml-2 mr-2' style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}}>${coinsTRON[0].current_price}</span>
                                <span className={coinsTRON[0].price_change_percentage_24h < 0 ? 'mr-2 text-danger' : 'mr-2 text-success'} >
                                    {coinsTRON[0].price_change_percentage_24h < 0 ? <i className="fa fa-sort-down mr-1 text-danger"></i> : <i className="fa fa-sort-up mr-1 text-success"></i>}
                                    {coinsTRON[0].price_change_percentage_24h.toFixed(2)}%
                                </span>
                            </div>:null
                        }
                        {
                            coinsDOGE.length > 0 ?
                            <div className='nkab-cards2 col-md-3'>
                                <span className='ml-2 mr-2'><img src={coinsDOGE[0].image} width="30" alt="nkab drapeau" className="dark-theme-icon-disable mb-2 icon-16" /></span>
                                <span className='bolder uppercase' style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}}>{coinsDOGE[0].symbol}</span>
                                <span className='ml-2 mr-2' style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}}>${coinsDOGE[0].current_price}</span>
                                <span className={coinsDOGE[0].price_change_percentage_24h < 0 ? 'mr-2 text-danger' : 'mr-2 text-success'} >
                                    {coinsDOGE[0].price_change_percentage_24h < 0 ? <i className="fa fa-sort-down mr-1 text-danger"></i> : <i className="fa fa-sort-up mr-1 text-success"></i>}
                                    {coinsDOGE[0].price_change_percentage_24h.toFixed(2)}%
                                </span>
                            </div>:null
                        }
                        {
                            coinsLTC.length > 0 ?
                            <div className='nkab-cards2 col-md-3'>
                                <span className='ml-2 mr-2'><img src={coinsLTC[0].image} width="30" alt="nkab drapeau" className="dark-theme-icon-disable mb-2 icon-16" /></span>
                                <span className='bolder uppercase' style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}}>{coinsLTC[0].symbol}</span>
                                <span className='ml-2 mr-2' style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}}>${coinsLTC[0].current_price}</span>
                                <span className={coinsLTC[0].price_change_percentage_24h < 0 ? 'mr-2 text-danger' : 'mr-2 text-success'} >
                                    {coinsLTC[0].price_change_percentage_24h < 0 ? <i className="fa fa-sort-down mr-1 text-danger"></i> : <i className="fa fa-sort-up mr-1 text-success"></i>}
                                    {coinsLTC[0].price_change_percentage_24h.toFixed(2)}%
                                </span>
                            </div>:null
                        }
                    </div>
                </div>
            </div>

            <div className='text-center mt-4'>
                <h4 style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`}} className='bolder'>Questions fréquentes (FAQ)</h4>
            </div>
            <div className="row mb-3"> 
                <div className="col-md-3"> </div>
                <div className="mt-3 pointer col-md-6">
                    <div className={`d-flex justify-content-between ${themeMode === "true" ? "nkp_faq_title-t" : "nkp_faq_title"}`}>
                        <span><strong className="nkp_faq_strong" style={{fontSize:"19px"}} onClick={Circle1}>1. Qu’est-ce que NKAB Exchange ?</strong></span>
                        <span className="ml-5">{!QCircle1?<AddCircleIcon sx={{fontSize:"35px", color:"#c7b301"}} onClick={Circle1}/>:<DoNotDisturbOnIcon sx={{fontSize:"35px", color:"#000000"}} onClick={CircleNo}/>}</span>
                    </div>
                    {
                        QCircle1 ? 
                        <div className="mt-3 nkp_p_content animate__animated animate__fadeInRight" style={{fontSize:"16px", color:`${themeMode === "true" ? "#87ccf1" : "#52514f"}`}}><strong>NKAB EXCHANGE</strong> est une plateforme d'échange de crypto-actifs et de transfert d'argent national et international basée en Afrique de l'Ouest développée par NK Group Technology dont Elle est une filiale.<br/><br/>Elle donne ainsi la possibilité à toute personne d'échanger de la cryptomonnaie contre du fiat et du fiat contre de la cryptomonnaie, d'effectuer leurs transactions de façon automatique et en toute sécurité.<br/><br/>Elle permet également de faire des transferts d'argent inter-opérateurs nationaux et internationaux sans se déplacer en respectant toutes les normes sécuritaires.<br/><br/>Nous avons choisi de faciliter la vie à nos utilisateurs tant dans l'acquisition de monnaies crypto-actifs et dans les transferts d'argent.<br/><br/>Nous mettons ainsi au devant une sécurité très optimale sécurisant tout cadre afin de garantir les transactions de nos différents utilisateurs.</div>:null
                    }
                </div>
                <div className="col-md-3"></div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3"></div>
                <div className="mt-3 pointer col-md-6">
                    <div className={`d-flex justify-content-between ${themeMode === "true" ? "nkp_faq_title-t" : "nkp_faq_title"}`}>
                        <span><strong className="nkp_faq_strong" style={{fontSize:"19px"}} onClick={Circle2}>2. Qu’est-ce qu’une cryptomonnaie ?</strong></span><span className="ml-5">{!QCircle2?<AddCircleIcon sx={{fontSize:"35px", color:"#c7b301"}} onClick={Circle2}/>:<DoNotDisturbOnIcon sx={{fontSize:"35px", color:"#000000"}} onClick={CircleNo}/>}</span>
                    </div>
                    {
                        QCircle2 ? 
                        <div className="mt-3 nkp_p_content animate__animated animate__fadeInRight" style={{fontSize:"16px", color:`${themeMode === "true" ? "#87ccf1" : "#52514f"}`}}>La cryptomonnaie est une forme numérique de monnaie qui utilise la cryptographie comme moyen de sécurité et fonctionne indépendamment d’une banque centrale.<br/><br/>Les transactions en cryptomonnaies sont enregistrées sur un registre public distribué appelé blockchain, qui garantit que toutes les transactions sont sécurisées, transparentes et irréversibles.<br/><br/>Les cryptomonnaies sont décentralisées et offrent des avantages potentiels par rapport aux devises traditionnelles, tels que des transactions plus rapides et moins chères, une plus grande confidentialité et la possibilité de contourner les intermédiaires financiers.<br/><br/>Cependant, elles peuvent être très volatiles et sujettes à d’importantes fluctuations de prix, et l’environnement réglementaire entourant les cryptomonnaies est toujours en évolution, ce qui crée de l’incertitude et des risques pour les investisseurs.</div>:null
                    }
                </div>
                <div className="col-md-3"></div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3"></div>
                <div className="mt-3 pointer col-md-6">
                    <div className={`d-flex justify-content-between ${themeMode === "true" ? "nkp_faq_title-t" : "nkp_faq_title"}`}>
                        <span><strong className="nkp_faq_strong" style={{fontSize:"19px"}} onClick={Circle3}>3. Comment acheter des cryptomonnaies ?</strong></span>
                        <span className="ml-5">{!QCircle3?<AddCircleIcon sx={{fontSize:"35px", color:"#c7b301"}} onClick={Circle3}/>:<DoNotDisturbOnIcon sx={{fontSize:"35px", color:"#000000"}} onClick={CircleNo}/>}</span>
                    </div>
                    {
                        QCircle3 ? 
                        <div className="mt-3 nkp_p_content animate__animated animate__fadeInRight" style={{fontSize:"16px", color:`${themeMode === "true" ? "#87ccf1" : "#52514f"}`}}>Vous pouvez acheter des cryptos instantanément via l’application ou le site Web en ligne de Binance, en suivant les étapes ci-dessous :<br/><br/>1. Créez un compte sur le site de nkab-exchange.<br/><br/>2. Effectuez les étapes de vérification nécessaires, telles que la vérification d’identité.<br/><br/>3. Choisissez la cryptomonnaie que vous souhaitez acheter, sélectionnez votre mode de paiement préféré puis indiquez le montant que vous souhaitez dépenser.<br/><br/>4. Vérifiez les détails de la transaction et confirmez l’achat.<br/><br/>La cryptomonnaie que vous venez d’acheter sera créditée sur votre compte e-nkab.</div>:null
                    } 
                </div>
                <div className="col-md-3"></div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3"></div>
                <div className="mt-3 pointer col-md-6">
                    <div className={`d-flex justify-content-between ${themeMode === "true" ? "nkp_faq_title-t" : "nkp_faq_title"}`}>
                        <span><strong className="nkp_faq_strong" style={{fontSize:"19px"}} onClick={Circle4}>4. Comment acheter des cryptomonnaies ?</strong></span><span className="ml-5">{!QCircle4?<AddCircleIcon sx={{fontSize:"35px", color:"#c7b301"}} onClick={Circle4}/>:<DoNotDisturbOnIcon sx={{fontSize:"35px", color:"#000000"}} onClick={CircleNo}/>}</span>
                    </div>

                    {
                        QCircle4 ? 
                        <div className="mt-3 nkp_p_content animate__animated animate__fadeInRight" style={{fontSize:"16px", color:`${themeMode === "true" ? "#87ccf1" : "#52514f"}`}}>Il existe plusieurs raisons pour lesquelles une personne peut envisager d’acheter des cryptomonnaies dans le but d’investir :<br/><br/>1. Potentiel de rendement élevé : les cryptomonnaies peuvent être très volatiles et subir d’importantes fluctuations de prix, ce qui signifie qu’elles offrent un potentiel de rendement élevé si vous achetez au bon moment et vendez lorsque le prix a augmenté.<br/><br/>2. Diversification : investir dans les cryptomonnaies peut être un moyen de diversifier votre portefeuille d’investissement et de répartir vos risques entre différents types d’actifs.<br/><br/>3. Décentralisées et sûres : les cryptomonnaies sont décentralisées et fonctionnent indépendamment des systèmes financiers traditionnels, ce qui signifie qu’elles offrent un niveau élevé de sécurité et de confidentialité.<br/><br/>4. Une adoption en augmentation : les cryptomonnaies sont de plus en plus largement adoptées comme moyen de paiement et comme réserve de valeur, ce qui laisse penser que leur valeur pourrait continuer à augmenter au fil du temps.</div>:null
                    }
                </div>
                <div className="col-md-3"></div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3"></div>
                <div className="mt-3 pointer col-md-6">
                    <div className={`d-flex justify-content-between ${themeMode === "true" ? "nkp_faq_title-t" : "nkp_faq_title"}`}>
                        <span><strong className="nkp_faq_strong" style={{fontSize:"19px"}} onClick={Circle5}>5. Que faire avec les cryptomonnaies ?</strong></span>
                        <span className="ml-5">{!QCircle5?<AddCircleIcon sx={{fontSize:"35px", color:"#c7b301"}} onClick={Circle5}/>:<DoNotDisturbOnIcon sx={{fontSize:"35px", color:"#000000"}} onClick={CircleNo}/>}</span>
                    </div>
                    {
                        QCircle5 ? 
                        <div className="mt-3 nkp_p_content animate__animated animate__fadeInRight" style={{fontSize:"16px", color:`${themeMode === "true" ? "#87ccf1" : "#52514f"}`}}>Les cryptomonnaies peuvent être utilisées de différentes manières, notamment pour acheter des biens et des services, effectuer du trading sur des échanges, détenir des investissements, envoyer de l’argent à l’étranger et soutenir des organisations caritatives.<br/><br/>Il offre des avantages tels que la décentralisation, la sécurité et la protection de la vie privée, ainsi qu’un potentiel de rendement élevé et de diversification des portefeuilles d’investissement.<br/><br/>La disponibilité et l’acceptation des cryptomonnaies peuvent également varier d’un pays ou d’une région à l’autre, et leur utilisation et leur investissement nécessitent des recherches approfondies et une bonne compréhension des risques encourus.<br/><br/>Dans l’ensemble, les cryptomonnaies offrent de nouvelles possibilités et de nouveaux défis pour la finance et l’investissement à l’ère numérique.</div>:null
                    }
                </div>
                <div className="col-md-3"></div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3"></div>
                <div className="mt-3 pointer col-md-6">
                    <div className={`d-flex justify-content-between ${themeMode === "true" ? "nkp_faq_title-t" : "nkp_faq_title"}`}><span><strong className="nkp_faq_strong" style={{fontSize:"19px"}} onClick={Circle6}>6. Quelle cryptomonnaie devrais-je acheter ?</strong></span><span className="ml-5">{!QCircle6?<AddCircleIcon sx={{fontSize:"35px", color:"#c7b301"}} onClick={Circle6}/>:<DoNotDisturbOnIcon sx={{fontSize:"35px", color:"#000000"}} onClick={CircleNo}/>}</span>
                    </div>
                    {
                        QCircle6 ? 
                        <div className="mt-3 nkp_p_content animate__animated animate__fadeInRight" style={{fontSize:"16px", color:`${themeMode === "true" ? "#87ccf1" : "#52514f"}`}}>La cryptomonnaie que vous choisissez d’acheter dépend de ce que vous comptez en faire. Le Bitcoin, le Litecoin ou le Bitcoin Cash sont souvent acceptés pour les dépenses en cryptomonnaies.<br/><br/>Ethereum et les tokens ERC-20 sont idéaux pour créer des smart contracts, acheter des NFT ou pour d’autres cas d’utilisation innovants.<br/><br/>Le Dogecoin et le Shiba Inu sont des cryptomonnaies populaires en raison de leurs fortes communautés, et elles sont souvent considérées comme des cryptos amusantes à acheter, à détenir et à échanger.<br/><br/>Les stablecoins comme l’USD Coin et le PAX Dollar sont adossés à la valeur du dollar américain, ce qui leur confère moins de volatilité que les autres cryptomonnaies.</div>:null
                    }
                </div><div className="col-md-3"></div>
            </div>

            <div className='nkab-beginning container'>
                <div className='text-center'>
                    <h4 style={{color:`${themeMode === "true" ? "#fff" : "#0a146e"}`, fontSize:"25px"}} className='bolder'>Commencez dès maintenant</h4>
                </div>
                    <div className='d-flex justify-content-center animate__fadeInDown animate__animated'>
                        <Box noValidate sx={{ mt: 1 }}>
                            <Grid container>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    className='home-btn'
                                    sx={{ mt: 3, mb: 2, bgcolor: '#c7b301', fontWeight:'800', fontSize:'15px', color:'#0c1886' }}
                                >
                                    <Link onClick={MoreOptions} href='#beginning' sx={{textDecorationLine:"none", color:"#fff"}}>Opérez maintenant</Link>
                                </Button>
                            </Grid>
                        </Box>
                    </div>
            </div>
            
            <div className='nkp_body'>
                <div style = {{ backgroundSize: 'cover', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat'}}>
                    {
                        premium && Msg === "" ? 
                        <div className='container pb-5'>
                            <div className='text-center mt-5'><h4 className='color-b'> <strong>Plans Flexibles Pour Tous</strong> </h4></div>
                            <div className='text-center'><h5>Nos plans sont faits pour tous. Que vous débutiez sur notre plateforme ou que vous y soyez depuis longtemps, nous avons un plan qui vous convient.</h5></div>
                            <div className='text-center mt-5'>
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">Choix périodique</FormLabel>
                                    {
                                        (NkabPack1 || NkabPack2 || NkabPack3) && PremiumStatus === "" ? <Alert severity="error">Veuillez sélectionner une période avant de poursuivre!</Alert> : null 
                                    }
                                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group" >
                                        <div>
                                            <span><FormControlLabel className='color-black' value="mensuellement" control={<Radio />} label="Facturé mensuellement" onChange={radioHandler1} /></span>
                                            <span><FormControlLabel className='color-black' value="semestriellement" control={<Radio />} label="Facturé semestriellement" onChange={radioHandler2} /></span>
                                            <span><FormControlLabel className='color-black' value="annuellement" control={<Radio />} label="Facturé annuellement" onChange={radioHandler3} /></span>
                                        </div>
                                    </RadioGroup>
                                </FormControl>
                             </div>
                             {
                                NkabPack1 && PremiumStatus !== "" ? 
                                <div className='mt-4'>
                                    <div className='m-1'>
                                        <Card sx={{ minWidth: 275 }}>
                                            <CardContent>
                                                <div className='text-center'>
                                                    <h2 className='nkab-premium-period-title'>${NkabPackPrice1}<span className='nkab-premium-period-slash'>/</span><span className='nkab-premium-period'>mois</span></h2>
                                                </div>
                                                <div className='text-center'>
                                                    <span className='uppercase'>Facturé <strong className='text-success'>{PremiumStatus}</strong></span>
                                                </div>
                                                <div><hr/></div>
                                                <div>
                                                    <span className='mr-2 text-success'><CheckCircleIcon/></span>
                                                    <span className='color-grey'>Taux actuel <strong>675 XOF</strong></span>
                                                </div>
                                                <div>
                                                    <span className='mr-2 text-success'><CheckCircleIcon/></span>
                                                    <span className='color-grey'>Avantage : <strong>de 675 XOF à 670 XOF</strong></span>
                                                </div>
                                                <div>
                                                    <span className='mr-2 text-success'><CheckCircleIcon/></span>
                                                    <span className='color-grey'>Réduction de <strong>5 XOF</strong></span>
                                                </div>
                                                <div>
                                                    <span className='mr-2 text-success'><CheckCircleIcon/></span>
                                                    <span className='color-grey'>Achetez aux taux de <strong>670 XOF</strong></span>
                                                </div>
                                            </CardContent>
                                            <div className='text-center'><Alert severity="warning">Une fois votre souscription terminée vous ne pourez plus la modifier avant la date d'expiration!</Alert></div>
                                            <CardActions>
                                                <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#c21515' }} onClick={finalNkabPackBack}> Annuler </Button>
                                                <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#c7b301' }} onClick={finalNkabPack}> { !NkabSpinner?"Finaliser la souscription":<div className="nkab-spinner"></div> } </Button>
                                            </CardActions>
                                        </Card>
                                    </div>
                                </div>:null
                             }
                        </div>:
                        premium && Msg !== "" ? 
                        <div className='container pb-5'>
                            <div className='text-center mt-5'><h4 className='text-success uppercase'> <strong>Félicitation!</strong> </h4></div>
                            <div className='mt-3 text-center'><Alert severity="success">{Msg}<span className='ml-4 nkab-lite-start pointer' onClick={premiumPack2}>OK</span></Alert></div>
                        </div>:null
                    }
                </div>
            </div>
            <Dialog
                    open={NewCode === "ok" || UpdateCode === "ok" ? true : open}
                    PaperProps={{
                    component: 'form',
                    }}
                >
                    <DialogTitle><span style={{color:"#fff", fontSize:"21px"}}>Nouveau Code Pin</span></DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                    <div><span className='mb-2' style={{color:"#fff", fontSize:"16px"}}>Saisissez un nouveau <strong>code pin!</strong></span></div>
                    {
                        CodeEmail.length > 10 && CodeEmail === userEmail ? null : CodeEmail.length > 10 && CodeEmail !== userEmail ? <div className='text-center'><span className='mb-2 mt-2 text-danger'>Nous remarquons que votre email ne conrespond pas à celui de notre base de données!</span></div>:null
                    }
                    </DialogContentText>
                    <OutlinedInput
                        autoFocus
                        required
                        value={CodeEmail}
                        onChange={handleEmail}
                        margin="dense"
                        id="name"
                        name="email"
                        label="email"
                        type="email"
                        fullWidth
                        variant="standard"
                        placeholder="email"
                        sx={{color:"#fff", borderRadius:"10px", border:"0.5px solid #fff", width:"100%"}}
                        className='nkab-popup-tex-color mb-2'
                    />
                    <OutlinedInput
                        required
                        value={passwordInput}
                        onChange={handlePasswordChange}
                        margin="dense"
                        id="name"
                        name="cp"
                        label="Code pin"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff sx={{color:"#c7b301"}}/> : <Visibility sx={{color:"#c7b301"}} />}
                            </IconButton>
                            </InputAdornment>
                        }
                        fullWidth
                        variant="standard"
                        placeholder="code pin"
                        sx={{color:"#fff", mb:"7px", borderRadius:"10px", border:"0.5px solid #fff", width:"100%"}}
                    />
                    <OutlinedInput
                        required
                        value={CodePin}
                        onChange={handleChangeInput}
                        margin="dense"
                        id="name"
                        name="cp"
                        label="Code pin"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff sx={{color:"#c7b301"}} /> : <Visibility sx={{color:"#c7b301"}} />}
                            </IconButton>
                            </InputAdornment>
                        }
                        fullWidth
                        variant="standard"
                        placeholder="confirmez le code pin"
                        sx={{color:"#fff", borderRadius:"10px", border:"0.5px solid #fff", width:"100%"}}
                        className='nkab-popup-tex-color'
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} sx={{color:"#c7b301"}}>Revenir</Button>
                    {
                        CodeEmail.length > 10 && CodeEmail === userEmail ? 
                        <Button sx={{color:"#c7b301"}} onClick={UpdateCode === "ok" ? UpdateCodeHandler : NewCodeHandler}>Confirmer</Button>:
                        <Button sx={{color:"#554d04"}} >Confirmer</Button>
                    }
                    </DialogActions>
            </Dialog>
            <Footer/>
        </div>
        <div className="nkp_diplay_container">
            <div id="nkp_bottom_navbar" className="visible">
                <div className="nkab_ul">
                    <div className={`nkab_li ${NewCode === "ok" ? activated : null}`}>
                        <a href="#/" onClick={ClickNewCodeHandler}>
                            <span className="nkp_bottom_span">
                                <img src="../../images/nkabico/t_ico_code_pin.png" width="25" className="" alt="nkab ico"/>
                            </span>
                            <span className="nkp_bottom_navbar_title">Code pin</span>
                        </a>
                    </div>
                    <div className={`nkab_li ${RInterface ? activated : null}`}>
                        <a href="#/" onClick={RSection}>
                            <span className="nkp_bottom_span">
                                <img src="../../images/nkabico/t_ico_recharge.png" width="25" className="" alt="nkab ico"/>
                                </span>
                            <span className="nkp_bottom_navbar_title">Recharge</span>
                        </a>
                    </div>
                    <div className={`nkab_li ${BInterface ? activated : null}`}>
                        <a href="#/" onClick={BSection}>
                            <span className="nkp_bottom_span">
                                <img src="../../images/nkabico/t_ico_achat.png" width="25" className="" alt="nkab ico"/>
                            </span>
                            <span className="nkp_bottom_navbar_title">Achat</span>
                        </a>
                    </div>
                    <div className={`nkab_li ${TInterface ? activated : null}`}>
                        <a href="#/" onClick={TSection}>
                            <span className="nkp_bottom_span">
                                <img src="../../images/nkabico/t_ico_transfert.png" width="25" className="" alt="nkab ico"/>
                            </span>
                            <span className="nkp_bottom_navbar_title">Transfert</span>
                        </a>
                    </div>
                    <div className=" nkab_li">
                        <a href="#logout" onClick={handleLogout}>
                            <span className="nkp_bottom_span">
                                <img src="../../images/nkabico/t_ico_connexion.png" width="25" className="" alt="nkab ico"/>
                            </span>
                            <span className="nkp_bottom_navbar_title">Logout</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NkabPro