import React, { useEffect, useRef, useState } from 'react'

export default function ServiceDisponible() {

  const TRON            = "TRON"
  const BTC             = "Bitcoin"
  const LTC             = "Litecoin"
  const USDT            = "Tether(USDT)"
  const DOGE            = "Dogecoin"
  const BUSD            = "Binance USD"
  const SHIB            = "Shiba Inu"
  const MATIC           = "Polygon"
  const ETH             = "Ethereum"
  const LINK            = "Chainlink"
  const POLKADOT        = "Polkadot"
  const CARDANO         = "Cardano"
  const SOLANA          = "Solana"
  const PAYEER          = "Payeer"
  const PM              = "Perfect Money"
  const BNB             = "Binance Smart Chain"
  
  const tron            = "../images/tron.png"
  const btc             = "../images/btc.png"
  const ltc             = "../images/ltc.png"
  const usdt            = "../images/usdt.png"
  const doge            = "../images/doge.png"
  const busd            = "../images/busd.png"
  const shiba           = "../images/shiba.png"
  const polygonmatic    = "../images/polygonmatic.png"
  const ethereum        = "../images/eth.png"
  const chainlink       = "../images/chainlink.png"
  const polkadot        = "../images/pokadot.png"
  const cardano         = "../images/cardano.png"
  const solana          = "../images/solana.png"
  const payeer          = "../images/payeer.png"
  const pm              = "../images/pm.png"
  const bnb             = "../images/bnb.png"

  const STATUS = { STARTED: 'Traitement en cours...', }
  
    const INITIAL_COUNT1 = 300
  
    const [secondsRemaining1,            setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
    const [status1,                      setStatus1           ] = useState(STATUS.STOPPED)
  
    useEffect(()=>{
      setStatus1(STATUS.STARTED)
    },[STATUS.STARTED])

  useInterval(
    () => {
    if (secondsRemaining1 > 0) {
        setSecondsRemaining1(secondsRemaining1 - 1)
    } else {
        setStatus1(STATUS.STOPPED)
    }
    },

    status1 === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
)

function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
    savedCallback.current = callback
    }, [callback])

// Set up the interval.
useEffect(() => {
  function tick() {
    savedCallback.current()
  }
  if (delay !== null) {
    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }
}, [delay])
}

var [themeMode, setThemeMode] = useState()

let ThemeMode = localStorage.getItem("ThemeMode")

useEffect(()=>{
  
  if(secondsRemaining1 > 0){
    setThemeMode(ThemeMode)
  }

},[ThemeMode, secondsRemaining1])

  return (
    <div className={`${themeMode === "true" ? "change-container change-container-t" : "change-container"}`}>
      <div className='row text-center'>
        <div className="_our-header">
          <h1 className="header__title" style={{color:`${themeMode === "true" ? "#90d9ff" : "#333"}`}}>Nos monnaies</h1>
          <h2 className="header__subtitle" style={{color:`${themeMode === "true" ? "#90d9ff" : "#333"}`}}>Nos monnaies numériques disponibles & non dispomibles</h2>
        </div>
      </div>
      <div className="change-items animate__animated animate__backInRight">
          <div className={`${themeMode === "true" ? "change-card change-card-theme" : "change-card"}`}>
            <div className="change-card-content">
              <div className="change-image-container">
                <img className="change-image" src={btc} alt={BTC} />
              </div>
              <div className="change-titles">
                <div className={`${themeMode === "true" ? "change-name change-name-theme" : "change-name"}`}>{BTC}</div>
                  <div>
                    <div className="text-success"> Disponible </div>
                  </div>
              </div>
            </div>
          </div>
          <div className={`${themeMode === "true" ? "change-card change-card-theme" : "change-card"}`}>
            <div className="change-card-content">
              <div className="change-image-container">
                <img className="change-image" src={usdt} alt={USDT} />
              </div>
              <div className="change-titles">
                <div className={`${themeMode === "true" ? "change-name change-name-theme" : "change-name"}`}>{USDT}</div>
                  <div>
                    <div className="text-success"> Disponible </div>
                  </div>
              </div>
            </div>
          </div>
          <div className={`${themeMode === "true" ? "change-card change-card-theme" : "change-card"}`}>
            <div className="change-card-content">
              <div className="change-image-container">
                <img className="change-image" src={bnb} alt={BNB} />
              </div>
              <div className="change-titles">
                <div className={`${themeMode === "true" ? "change-name change-name-theme" : "change-name"}`}>{BNB}</div>
                  <div>
                    <div className="text-success"> Disponible </div>
                  </div>
              </div>
            </div>
          </div>
          <div className={`${themeMode === "true" ? "change-card change-card-theme" : "change-card"}`}>
            <div className="change-card-content">
              <div className="change-image-container">
                <img className="change-image" src={tron} alt={TRON} />
              </div>
              <div className="change-titles">
                <div className={`${themeMode === "true" ? "change-name change-name-theme" : "change-name"}`}>{TRON}</div>
                  <div>
                    <div className="text-success"> Disponible </div>
                  </div>
              </div>
            </div>
          </div>
          <div className={`${themeMode === "true" ? "change-card change-card-theme" : "change-card"}`}>
            <div className="change-card-content">
              <div className="change-image-container">
                <img className="change-image" src={ltc} alt={LTC} />
              </div>
              <div className="change-titles">
                <div className={`${themeMode === "true" ? "change-name change-name-theme" : "change-name"}`}>{LTC}</div>
                  <div>
                    <div className="text-success"> Disponible </div>
                  </div>
              </div>
            </div>
          </div>
          <div className={`${themeMode === "true" ? "change-card change-card-theme" : "change-card"}`}>
            <div className="change-card-content">
              <div className="change-image-container">
                <img className="change-image" src={shiba} alt={SHIB} />
              </div>
              <div className="change-titles">
                <div className={`${themeMode === "true" ? "change-name change-name-theme" : "change-name"}`}>{SHIB}</div>
                  <div>
                    <div className="text-success"> Disponible </div>
                  </div>
              </div>
            </div>
          </div>
          <div className={`${themeMode === "true" ? "change-card change-card-theme" : "change-card"}`}>
            <div className="change-card-content">
              <div className="change-image-container">
                <img className="change-image" src={busd} alt={BUSD} />
              </div>
              <div className="change-titles">
                <div className={`${themeMode === "true" ? "change-name change-name-theme" : "change-name"}`}>{BUSD}</div>
                  <div>
                    <div className="text-success"> Disponible </div>
                  </div>
              </div>
            </div>
          </div>
          <div className={`${themeMode === "true" ? "change-card change-card-theme" : "change-card"}`}>
            <div className="change-card-content">
              <div className="change-image-container">
                <img className="change-image" src={pm} alt={PM} />
              </div>
              <div className="change-titles">
                <div className={`${themeMode === "true" ? "change-name change-name-theme" : "change-name"}`}>{PM}</div>
                  <div>
                    <div className="text-success"> Disponible </div>
                  </div>
              </div>
            </div>
          </div>
          <div className={`${themeMode === "true" ? "change-card change-card-theme" : "change-card"}`}>
            <div className="change-card-content">
              <div className="change-image-container">
                <img className="change-image" src={doge} alt={DOGE} />
              </div>
              <div className="change-titles">
                <div className={`${themeMode === "true" ? "change-name change-name-theme" : "change-name"}`}>{DOGE}</div>
                  <div>
                    <div className="text-success"> Disponible </div>
                  </div>
              </div>
            </div>
          </div>
          <div className={`${themeMode === "true" ? "change-card change-card-theme" : "change-card"}`}>
            <div className="change-card-content">
              <div className="change-image-container">
                <img className="change-image" src={ethereum} alt={ETH} />
              </div>
              <div className="change-titles">
                <div className={`${themeMode === "true" ? "change-name change-name-theme" : "change-name"}`}>{ETH}</div>
                  <div>
                    <div className="text-success"> Disponible </div>
                  </div>
              </div>
            </div>
          </div>
          <div className={`${themeMode === "true" ? "change-card change-card-theme" : "change-card"}`}>
            <div className="change-card-content">
              <div className="change-image-container">
                <img className="change-image" src={polygonmatic} alt={MATIC} />
              </div>
              <div className="change-titles">
                <div className={`${themeMode === "true" ? "change-name change-name-theme" : "change-name"}`}>{MATIC}</div>
                  <div>
                    <div className="text-success"> Disponible </div>
                  </div>
              </div>
            </div>
          </div>
          <div className={`${themeMode === "true" ? "change-card change-card-theme" : "change-card"}`}>
            <div className="change-card-content">
              <div className="change-image-container">
                <img className="change-image" src={chainlink} alt={LINK} />
              </div>
              <div className="change-titles">
                <div className={`${themeMode === "true" ? "change-name change-name-theme" : "change-name"}`}>{LINK}</div>
                  <div>
                    <div className="text-success"> Disponible </div>
                  </div>
              </div>
            </div>
          </div>
          <div className={`${themeMode === "true" ? "change-card change-card-theme" : "change-card"}`}>
            <div className="change-card-content">
              <div className="change-image-container">
                <img className="change-image" src={polkadot} alt={POLKADOT} />
              </div>
              <div className="change-titles">
                <div className={`${themeMode === "true" ? "change-name change-name-theme" : "change-name"}`}>{POLKADOT}</div>
                  <div>
                    <div className="text-success"> Disponible </div>
                  </div>
              </div>
            </div>
          </div>
          <div className={`${themeMode === "true" ? "change-card change-card-theme" : "change-card"}`}>
            <div className="change-card-content">
              <div className="change-image-container">
                <img className="change-image" src={cardano} alt={CARDANO} />
              </div>
              <div className="change-titles">
                <div className={`${themeMode === "true" ? "change-name change-name-theme" : "change-name"}`}>{CARDANO}</div>
                  <div>
                    <div className="text-success"> Disponible </div>
                  </div>
              </div>
            </div>
          </div>
          <div className={`${themeMode === "true" ? "change-card change-card-theme" : "change-card"}`}>
            <div className="change-card-content">
              <div className="change-image-container">
                <img className="change-image" src={solana} alt={SOLANA} />
              </div>
              <div className="change-titles">
                <div className={`${themeMode === "true" ? "change-name change-name-theme" : "change-name"}`}>{SOLANA}</div>
                  <div>
                    <div className="text-success"> Disponible </div>
                  </div>
              </div>
            </div>
          </div>
          <div className={`${themeMode === "true" ? "change-card change-card-theme" : "change-card"}`}>
            <div className="change-card-content">
              <div className="change-image-container">
                <img className="change-image" src={payeer} alt={PAYEER} />
              </div>
              <div className="change-titles">
                <div className={`${themeMode === "true" ? "change-name change-name-theme" : "change-name"}`}>{PAYEER}</div>
                  <div>
                    <div className="text-danger"> Non disponible </div>
                  </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}
