import React from 'react'
import './Banner.css'

const Banner = () => {
  return (
    <div className='container mt-5 mb-5'>
        <img className='nkab-exchange-banner' src="../../images/nkab-bannier.jpg" alt="bannière nkab exchange" />
        {/* <div id="fly-in" className='banner-content banner-color'>
            <div><span>Very</span>Cinematic</div>
            <div>Fade Away<span>into the distance</span></div>
            <div>Still Loops <span> for eternity</span></div>
            <div><span>Just CSS</span> and HTML</div>
            <div><span>Very</span>Cinematic</div>
            <div>Fade Away<span>into the distance</span></div>
            <div>Still Loops <span> for eternity</span></div>
            <div><span>Just CSS</span> and HTML</div>
        </div> */}
    </div>
  )
}

export default Banner