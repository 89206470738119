import React from 'react'

export default function ChatCustomer() {


  return (
    <section>
      <label className="chat-btn">
          <a href="https://wa.me/message/BNJDHCQNNP52J1"> <i className="fa fa-whatsapp fa-2x"></i> </a>
        </label>
    </section>
  )
}

