import React, { useEffect, useRef, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { Alert } from '@mui/material';


const defaultTheme = createTheme();

export default function SignIn() {

  const [NkabSpinner,  setNkabSpinner] = useState(false)

  const STATUS = { STARTED: 'Traitement en cours...', }
  
  const INITIAL_COUNT1 = 300

  const [secondsRemaining1, setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
  const [status1, setStatus1 ] = useState(STATUS.STOPPED)
  const [ErrorMessage, setErrorMessage ] = useState("")

  const LineLink = true
  // const LineLink = false
  
  useEffect(()=>{
    setStatus1(STATUS.STARTED)
  },[STATUS.STARTED])

  useInterval(
    () => {
    if (secondsRemaining1 > 0) {
        setSecondsRemaining1(secondsRemaining1 - 1)
    } else {
        setStatus1(STATUS.STOPPED)
    }
    },

    status1 === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
)

function useInterval(callback, delay) {
  
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
    savedCallback.current = callback
    }, [callback])

// Set up the interval.
useEffect(() => {
  function tick() {
    savedCallback.current()
  }
  if (delay !== null) {
    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }
}, [delay])
}

var [themeMode, setThemeMode] = useState()

let ThemeMode = localStorage.getItem("ThemeMode")

useEffect(()=>{
  
  if(secondsRemaining1 > 0){
    setThemeMode(ThemeMode)
  }

},[ThemeMode, secondsRemaining1])
  
  var   _ProWallet = localStorage.getItem('_ProWallet')
  const Logged  = localStorage.getItem('userStatus')


  useEffect(() => {

    if(Logged === "Logged"){
      if (_ProWallet === 'true') {
        localStorage.setItem("isMarchandActive", true)

        if(LineLink){
          window.location.replace("https://nkab-exchange.net/nkab-pro");
        } else {
           window.location.replace("http://localhost:3000/nkab-pro");
        }
      }
      if (_ProWallet === 'false') {
        localStorage.setItem("isMarchandActive", true)

        if(LineLink){
          window.location.replace("https://nkab-exchange.net/sell-or-buy-crypto");
        } else {
           window.location.replace("http://localhost:3000/sell-or-buy-crypto");
        }
      }
    }

  }, [Logged, _ProWallet, LineLink])

  const HandleChange = (event) => {
    setNkabSpinner(false)
    setErrorMessage("")
    event.preventDefault()
  }

  const HandleChange2 = (event) => {
    setNkabSpinner(false)
    setErrorMessage("")
    event.preventDefault()
  }

  const handleLogin = async (event) => {
    
    setNkabSpinner(true)
    event.preventDefault()
    const data = new FormData(event.currentTarget);

    try {
      const res = await axios.post('/api/login', {
        email: (data.get('email')).toLowerCase(),
        password: data.get('password'),
      })
      if (res) {

        localStorage.setItem('userID',        res.data.user.ID)
        localStorage.setItem('userName',      res.data.user.name)
        localStorage.setItem('userLastName',  res.data.user.lastName)
        localStorage.setItem('userEmail',     res.data.user.email)
        localStorage.setItem('userPhone',     res.data.user.telNumber)
        localStorage.setItem('userStatus',    res.data.msg)
        localStorage.setItem('token',         res.data.token)
        localStorage.setItem('SenderCountry', res.data.user.countryName)
        localStorage.setItem('Country',       res.data.user.country)
        localStorage.setItem('role',          res.data.user.role)
        localStorage.setItem('firstLogin',    true)
        localStorage.setItem("isRActive",     false)
        localStorage.setItem("isSeen",        false)
        localStorage.setItem("isMarchandActive", true)

        if(LineLink){
          window.location.replace("https://nkab-exchange.net/sell-or-buy-crypto");
        } else {
           window.location.replace("http://localhost:3000/sell-or-buy-crypto");
        }

      }
    } catch (err) {
      setNkabSpinner(false)
      setErrorMessage("Identifiant ou mot de passe incorrect")
     }
  }

  return (
    <div className='pt-5 mb-5 pb-5'>
      <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-5 d-flex justify-content-around'>
          <div className='d-flex justify-content-center'><img className='login-currency-img' src="../../images/nkab-info.jpg" width="700" alt="login nkab" /></div>
        </div>
        <div className='col-md-3 final-seesion d-flex justify-content-around'>
          <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs" className='css-binzgt'>
              <CssBaseline />
              <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                <Avatar sx={{ m: 1, bgcolor: '#c7b301' }}> <LockOutlinedIcon /> </Avatar>
                <Typography sx={{fontSize: "20px", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}> Connexion </Typography>
                {ErrorMessage !==""?<Alert severity="error">{ErrorMessage}</Alert>:null}
                <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Votre e-mail"
                    name="email"
                    autoComplete="email"
                    onChange={HandleChange}
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Mot de passe"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={HandleChange2}
                  />
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Se rappeler de moi"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, bgcolor: '#c7b301' }}
                  >
                      { !NkabSpinner?"Connexion":<div className="nkab-spinner"></div> }
                    
                  </Button>
                  <Grid container>
                    <Grid item xs> <Link href="/forgot_password" variant="body2" sx={{color:"#c7b301"}}> Mot de passe oublié ? </Link> </Grid>
                    <Grid item> <Link href="/singup" variant="body2" sx={{color:"#c7b301"}}> {"Pas de compte ? S'inscrire"} </Link> </Grid>
                  </Grid>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </div>
        <div className='col-md-2'></div>
      </div>
    </div>
  );
}