import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  showErrMessage,
  showSuccessMessage,
} from '../components/utils/notification/Notification'
import { useHistory, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBackward } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import InfoCoin from '../components/InfoCoin'

export default function EditUser(props) {


  var _ProWallet                       = localStorage.getItem('_ProWallet')
  const token                          = localStorage.getItem('token')
      
  const { id }                         = useParams()
  const users                          = useSelector((state) => state.users)
  const history                        = useHistory()
  const [editUser,    setEditUser    ] = useState([])
  const [checkAdmin,  setCheckAdmin  ] = useState(false)
  const [err,         setErr         ] = useState(false)
  const [success,     setSuccess     ] = useState(false)
  const [num,         setNum         ] = useState(0)


  if (_ProWallet === 'true') {
    props.history.push('/nkab-pro')
  }

  useEffect(() => {
    if (users.length !== 0) {
      users.forEach((user) => {
        if (user._id === id) {
          setEditUser(user)
          setCheckAdmin(user.role === 1 ? true : false)
        }
      })
    } else {
      history.push('/profile')
    }
  }, [id, users, history])

  const handleUpdate = async () => {
    try {
      if (num % 2 !== 0) {
        const res = await axios.patch(
          `/api/user/api/v1/update_role//${editUser._id}`,
          {
            role: checkAdmin ? 1 : 0,
          },
          {
            headers: { Authorization: token },
          },
        )

        setSuccess(res.data.message)
        setNum(0)
      }
    } catch (err) {
      err.response.data.message && setErr(err.res.data.message)
    }
  }

  const handleCheck = () => {
    setSuccess('')
    setErr('')
    setCheckAdmin(!checkAdmin)
    setNum(num + 1)
  }

  return (
    <div className="color-w container" style={{backgroundColor:"#90d9ff"}}>
      <InfoCoin />
      <div className="about-session">
        <div className="container emp-profile">
          <div className="row">
            <div style={{ marginBottom: `${'10px'}` }}>
              <span
                onClick={() => history.goBack()}
                style={{ color: '#0a146e', cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faBackward} /> {'[Retour]'}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="name">Nom</label>
                <input
                  className="form-control input-signin input-add"
                  type="text"
                  name="name"
                  defaultValue={editUser.name}
                  disabled
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  className="form-control input-signin input-add"
                  type="text"
                  name="email"
                  defaultValue={editUser.email}
                  disabled
                />
              </div>

              <div className="form-group">
                <input
                  className="input-checkbox"
                  type="checkbox"
                  id="isAdmin"
                  checked={checkAdmin}
                  onChange={handleCheck}
                />
                <span>
                  {' '}
                  <label htmlFor="isAdmin">is Admin</label>
                </span>
              </div>
            </div>
            <br />

            <div className="form-group">
              <input
                style={{ backgroundColor: `${'#c7b301'}`, border: `${'none'}` }}
                className="btn btn-lg btn-primary btn-block"
                value="Mettre à jour"
                onClick={handleUpdate}
              />
            </div>
          </div>
          <div className="row">
            <div style={{ marginTop: `${'10px'}`, marginBottom: `${'10px'}` }}>
              {err && showErrMessage(err)}
              {success && showSuccessMessage(success)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
