import './Authentification.css'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {
  showErrMessage,
  showSuccessMessage,
} from '../components/utils/notification/Notification'
import { isEmail } from '../components/utils/validation/Validation'



const initialState = {
  email: '',
  err: '',
  success: '',
}

export default function ForgotPassword(props) {

  var    _ProWallet     = localStorage.getItem('_ProWallet')

  const [data,            setData         ] = useState(initialState)
  const [GetUser,         setGetUser      ] = useState([])
  const [MainEmail,       setMainEmail    ] = useState()
  const [IdValided,       setIdValided    ] = useState('')
  const [EmailValided,    setEmailValided ] = useState('')
  const [Success,         setSuccess      ] = useState('')
  const { email,           err            } = data

  
  if (_ProWallet === 'true') {
    props.history.push('/nkab-pro')
  }

  useEffect(()=>{

    const fetchDada12 = async () => {
      try {
        const { data } = await axios.get(
          '/api/v1/get-users',
        )
        setGetUser(data)
      } catch (error) {}
    }
    fetchDada12()

  },[])



  const handleChangeInput = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value, err: '', success: '' })
  }

  useEffect(()=>{

    if(email && GetUser){
      const SearchUnicEmail = GetUser.filter((obj)=>{
        return obj.email.includes(email)
      })
      setMainEmail(SearchUnicEmail)
    }

  },[email, GetUser])

  useEffect(()=>{

    if(MainEmail){
      if(MainEmail[0]){
        setIdValided(MainEmail[0].ID)
        setEmailValided(MainEmail[0].email)
      }
    }
    
  },[MainEmail])

  const handleForgotPassword = async () => {
    if (!isEmail(email))
      return setData({ ...data, err: 'Invalid emails', success: '' })

    try {
      const res = await axios.post(
        '/api/forgot_password',
        { email },
      )
      setSuccess(res.data.message)
      localStorage.setItem('email', email)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-5 forgot-password">
          <div className="card">
            <div className="card-body">
              <div className="text-center">
                <div style={{ marginBottom: `${'10px'}` }}>
                  {err && showErrMessage(err)}
                  {Success && showSuccessMessage(Success)}
                </div>
                <h4> <i className="fa fa-lock fa-4x" style={{ color: `${'#c7b301'}`}}></i> </h4>
                <h2 className="text-center">Mot de passe oublié ?</h2>
                <p>Renseignez votre adresse de vérification ici.</p>
                <div className="panel-body">
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        id="email"
                        value={email.toLowerCase()}
                        name="email"
                        placeholder="Adresse email"
                        className="form-control input-signin input-add"
                        type="email"
                        onChange={handleChangeInput}
                      />
                    </div>
                  </div>
                    {
                      !IdValided && !EmailValided && !email ?
                      <div className="form-group">
                        <input
                          style={{ backgroundColor: `${'#e2be99'}`, border: `${'none'}`, }}
                          name="recover-submit"
                          className="btn btn-lg btn-primary btn-block"
                          value="Envoyer la vérification"
                          type="submit"
                          disabled
                        />
                      </div>:
                      IdValided && (EmailValided !== email) ?
                      <div className="form-group">
                        <input
                          style={{ backgroundColor: `${'#e2be99'}`, border: `${'none'}`, }}
                          name="recover-submit"
                          className="btn btn-lg btn-primary btn-block"
                          value="Envoyer la vérification"
                          type="submit"
                          disabled
                        />
                      </div>:
                      IdValided && (EmailValided === email) ?
                      <div className="form-group">
                        <input
                          style={{ backgroundColor: `${'#c7b301'}`, border: `${'none'}`, }}
                          name="recover-submit"
                          className="btn btn-lg btn-primary btn-block"
                          value="Envoyer la vérification"
                          type="submit"
                          onClick={handleForgotPassword}
                        />
                      </div>:
                      <div className="form-group">
                        <input
                          style={{ backgroundColor: `${'#e2be99'}`, border: `${'none'}`, }}
                          name="recover-submit"
                          className="btn btn-lg btn-primary btn-block"
                          value="Envoyer la vérification"
                          type="submit"
                          disabled
                        />
                      </div>
                    }
                  <input type="hidden" className="hide" name="token" id="token" />
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3"></div>
      </div>
    </div>
  )
}
